
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { ensureHttps, shuffleArray, shuffleArray10, shuffleArray20, useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourPicture } from "../service/LifeService";
import { TOURISTMENU } from "../utility/life";
import { GrTransaction } from "react-icons/gr";
import { SlControlPlay } from "react-icons/sl";
import { LoadingCommunityStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import LazyImage from "../common/LasyImage";
import ButtonEx from "../common/ButtonEx";
import PCSlickSliderComponentEx from "../common/PCSwipeex";

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  height: 1500px;
  overflow-y : auto; 
  scrollbar-width: none;
  width:100%;
`
const style = {
  display: "flex"
};

const  SearchLayer = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display:flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  width:100%;
  background:#fff;
  position:sticky;
  top:0px;
`

const BoxItem = styled.div`
  padding: 20px;
  border: 1px solid #ededed;
  border-radius: 10px;
  margin-bottom: 20px;
  color: #333;
  line-height: 1.8;
  width:85%;
  font-family: "Pretendard-Light";


`
const Inputstyle={
  margin :"10px auto",
  border: '2px solid #ff7e10',
  background: '#fff',
  height: '20px',
  width:"77%",

}
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#f3f3f3",
  padding: "10px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '5px',
  color: '#333',
}

const Taglabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:16px;
  margin-right:10px;
`
const Item = styled.div`
  margin: 5px 0px;
`
const LocationText  = styled.div`
  color : #131313;
  font-size:13px;
`
const SearchText = styled.div`
color : #131313;
font-family:'Pretendard-Light';
font-size:10px;
`  

const ButtonLayer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
`

const FilterButton = styled.div`
  background-color: #fff;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  border: 1px solid #999;
  font-family: 'Pretendard-SemiBold';
`

const RecommendTitle = styled.div`
  font-family: 'Pretendard-Bold';
  margin: 20px 0px;

`

const RecommendButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  color : #999;
`

const BannerItems =[
  {type:TOURISTMENU.TOURREGION,image : imageDB.tour, main1:"전국사진", main2:"지도에 기반한 관광지  향토 문화시설및 편의시설정보 ", main3:"", color:"#ebf7fa"},
]
const PCLifeTourPicture =({containerStyle}) =>  {


/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);
  const [searching, setSearching] = useState(true);
  const [displayitems, setDisplayitems] = useState(data.tourpictureitem);

  const [popupstatus, setPopupstatus] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setDisplayitems(displayitems);

  }, [refresh])

  const _handleRecommend = () =>{
    let itemsTmp = shuffleArray20(data.tourpictureitem);
    const newArray = [...displayitems , ...itemsTmp];
    setDisplayitems(newArray);
    setRefresh((refresh) => refresh +1);
  }

  useEffect(()=>{
      async function FetchData(){

        if(data.tourpictureitem.length == 0){
          const tourpictureitem = await ReadTourPicture();
          const dataToSavpicture = JSON.parse(tourpictureitem);
          data.tourpictureitem = dataToSavpicture.response.body.items.item;

          datadispatch(data);
        }

        let itemsTmp = shuffleArray20(data.tourpictureitem);

        setDisplayitems(itemsTmp); // Add new item to the end of the array



        setSearching(false);
    
      
      }

      FetchData();
  }, [])

   /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);

  const _handleView =(index)=>{
    navigate("/Mobiletourdetailpicture", {state:{index: index, items:displayitems, name:TOURISTMENU.TOURPICTURE}});
  }

  const _handleautoplay = (index) =>{
    navigate("/Mobiletourautopicture", {state:{index: index, items:displayitems, name:TOURISTMENU.TOURPICTURE}});
  }
 
  return (

    <Container style={containerStyle}>
        <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'}/>

        {
          searching == true ? (<LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
            width={"100px"} height={'100px  '} />)
          :(
            <Column>



              <Row style={{flexWrap:"wrap", width:"100%", margin: "0 auto"}}>      
              {
                displayitems.map((data, index)=>(
                  <div style={{width:'22%', margin:"10px auto",}} onClick={()=>{_handleView(index)}}>
                  <LazyImage src={ensureHttps(data.galWebImageUrl)} containerStyle={{ height:"200px", backgroundColor: '#e0e0e0'}}/>
              
                  <LocationText>{data.galPhotographyLocation}</LocationText>
                    <SearchText>
                    {data.galSearchKeyword.slice(0, 16)}
                    {data.galSearchKeyword.length > 16 ? "..." : null}
                    </SearchText>
                  </div>
              ))
              }
              </Row>

     
              <ButtonEx text={'더보기'} width={'60'} containerStyle={{marginBottom:50, marginTop:20 }}
                    onPress={_handleRecommend} bgcolor={'#ff7e19'} color={'#fff'} />

            </Column>
     
            
            )
        }
  

         
    </Container>
  );

}

export default PCLifeTourPicture;

