import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Column } from "../../../common/Column";
import { BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { UserContext } from "../../../context/User";
import { imageDB } from "../../../utility/imageData";
import { EventItems } from "../../../utility/screen";
import { REQUESTINFO, WORKNAME } from "../../../utility/work";

import { PiLockKeyLight } from "react-icons/pi"
import { BADGE } from "../../../utility/badge";
import { setRef } from "@mui/material";

import { IoIosRefresh } from "react-icons/io";
import { CreateName } from "../../../utility/data";
import { CountryAddress, MainRegion } from "../../../utility/region";
import MobileSuccessPopup from "../../../modal/MobileSuccessPopup/MobileSuccessPopup";
import { getuserInfobyusers_id, updatealluserbydeviceid, Update_addrbyusersid, Update_addrItemsbyusersid, Update_userinfobyusersid } from "../../../service/UserService";
import { SlEnvelopeOpen } from "react-icons/sl";
import { sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";
import { LoadingChatAnimationStyle, LoadingMainAnimationStyle, LoadingMapConfigAnimationStyle, LoadingProfileAnimationStyle } from "../../../screen/css/common";

import Barcode from 'react-barcode';

const Container = styled.div`
  width:100%;
  margin-bottom:100px;
`
const style = {
  display: "flex"
};

const ConfigLayer = styled.div`
  margin: 20px auto;
  width:80%;
  bottom: 0px;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding:20px;

`
const PointLayer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position : relative;
    height:200px;
    background :#fff;

`
const PointLabel = styled.div`
  position: absolute;
  top: 10px;
  font-size: 18px;
  color: #fff;
  background: #ff7e19;
  padding: 5px 50px;
  border-radius: 50px;
`

const Point = styled.div`
  position: absolute;
  top: 70px;
  font-size: 22px;
  color: #131313;
  font-family :Pretendard-SemiBold;
`

const PointRegistDesc = styled.div`
    position: absolute;
    top: 120px;
    font-size: 14px;
    color: #999;
    left: 10px;
`

const PointRegistDate = styled.div`
    position: absolute;
    top: 150px;
    font-size: 13px;
    color: #999;
    left: 10px;
`
const PointExpireDate = styled.div`
    position: absolute;
    top: 170px;
    font-size: 13px;
    color: #999;
    left: 10px;
`

const BarcodeLayer = styled.div`
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    transform: rotate(90deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


`

const pointitems=[
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
  {point:"", pointtype:"", pointdate:"", pointexpiredate:""},
]

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const MobilePointConfig =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [currentloading, setCurrentloading] = useState(true);


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
  }, [refresh])

  useEffect(()=>{
    async function FetchData(){
      await sleep(1000);

      setCurrentloading(false);
      setRefresh((refresh) => refresh +1);
    }

    FetchData();

  }, [])


 
  return (
    <>
      {
      currentloading == true ? (<LottieAnimation
      containerStyle={LoadingMainAnimationStyle} animationData={imageDB.loading} width={"50px"} height={'50px'}/>):(
        <Container style={containerStyle}>
        {
          pointitems.map((data)=>(
            <PointLayer>
              <Row style={{width:"100%", height:"100%",border: "2px solid #ededed"}}>
                <Column style={{width:"80%", height:"100%", borderRight:"2px dashed #ededed"}}>
                    <PointLabel>포인트</PointLabel>
                    <Point>1000 POINT</Point>
                    <PointRegistDesc>룰렛 게임에 의해 획득된 포인트 입니다</PointRegistDesc>
                    <PointRegistDate>발행일자 2024년 11월 10일 09:23</PointRegistDate>
                    <PointExpireDate>포인트 유효일자 2024년 12월 10일 09:23</PointExpireDate>

                </Column>
                <BarcodeLayer>
                
                  <Barcode value="1234567890"
                  width={2}
                  height={20}
                  lineColor="#666" // 바코드 색상
                  background="#ffff" // 배경 색상
                  displayValue={true}
                  fontOptions="Light"
                  textAlign="center"
                  
                  /> {/* 바코드에 넣을 텍스트 */}

                </BarcodeLayer>

              </Row>
           

            </PointLayer>
          ))
        }
        </Container>)
      }
    </>
  );

}

export default MobilePointConfig;

