import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";

// import { Calendar, momentLocalizer,Views } from 'react-big-calendar';
import { Calendar, dateFnsLocalizer,Views } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";

import "./WorkCalendar.css";

import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { enUS } from "date-fns/locale";

const locales = { "en-US": enUS };
const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });


// const localizer = momentLocalizer(moment);



const Container = styled.div`

`






const WorkCalendar =({containerStyle}) =>  {


    const [view, setView] = useState(Views.MONTH); // 기본 뷰는 월간 뷰

    const events = [
      {
        title: 'Meeting',
        start: new Date(2024, 11, 3, 10, 0),
        end: new Date(2024, 11, 4, 15, 0),
      },
      {
        title: '우체국가기',
        start: new Date(2024, 11, 11, 10, 0),
        end: new Date(2024, 11, 11, 12, 0),
      },
      {
        title: '공부방청소',
        start: new Date(2024, 11, 11, 10, 0),
        end: new Date(2024, 11, 16, 12, 0),
      },
      {
        title: '재활용하기',
        start: new Date(2024, 11, 26, 13, 0),
        end: new Date(2024, 11, 28, 14, 0),
      },
    ];

    const handleViewChange = (newView) => {
        setView(newView);
      };
    
      return (
        <div>
      
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}

        />
      </div>
      );
}

export default WorkCalendar;

