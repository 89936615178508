import React, { Component, memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";

import Button from "../../../common/Button";
import { DataContext } from "../../../context/Data";

import { readuser, readuserbydeviceid, Update_attendancebyusersid } from "../../../service/UserService";
import { getDateEx, getDateEx3, getDateFullTime } from "../../../utility/date";
import { useDispatch } from "react-redux";
import { ALLREFRESH } from "../../../store/menu/MenuSlice";
import { shuffleArray, sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";

import ButtonEx from "../../../common/ButtonEx";
import {motion} from 'framer-motion';
import RotateCardBasic from "../../../common/RotateCardBasic";
import RotateCard from "../../../common/RotateCard";
import MobileGameResult from "../../../modal/MobileGameResult";
import { ReadRACE } from "../../../service/RaceService";
import { LoadingRankingAnimationStyle, LoadingSearchAnimationStyle } from "../../../screen/css/common";

const Container = styled.div`
  padding-top:10px;

`
const InfoLayer = styled.div`
  background: #fcc81c57;
  width: 90%;
  margin: 0 auto;


`
const InfoLayerContent = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height:2;
`
const listTag ={
  listStyleType: 'disc',
  listStylePosition: 'inside',
}

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-family:Pretendard-SemiBold;
  padding: 10px;
  font-size:14px;
`

const TableContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  font-size :12px;
  padding: 10px;
`



const AudioPlayer = ({ condition }) => {
  const [audio] = useState(new Audio("/sounds/game.mp3"));

  const handleBeforeUnload = () => {
    audio.pause();
    audio.currentTime = 0;
    // 추가 작업
  };


  useEffect(() => {
    if (condition) {
      audio.play().catch((err) => {
        console.error("Audio playback failed", err);
      });
    } else {
      audio.pause();
      audio.currentTime = 0; // 재생 위치 초기화
    }

    return () => {
      // 컴포넌트가 언마운트될 때 오디오 종료
      audio.pause();
      audio.currentTime = 0;
    };
  }, [condition, audio]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      audio.pause();
      audio.currentTime = 0;
    };

    // 페이지 이동 이벤트 감지
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [audio]);

  return null;
};

const MobileGameRank =memo(({containerStyle}) =>  {

  const [seconds, setSeconds] = useState(0); // 10초 카운트다운

  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [items, setItems] = useState([]);
  const [currentloading, setCurrentloading] = useState(true);
  const [highlightIndex, setHighlightIndex] = useState(0);



  useEffect(() => {
    const timer = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 1000); // 1초마다 강조 항목 변경

    return () => clearInterval(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, [items.length]);


  useLayoutEffect(() => {

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  const sortBycount = (items) => {
    const sortedItems = [...items].sort((a, b) => a.COUNT - b.COUNT);
  
    return sortedItems;
  };

  async function FetchData(){

    const USERS = await readuser({});

    const rankingitems = await ReadRACE({});


    rankingitems.map((data)=>{
  
        const FindIndex = USERS.findIndex(x=>x.USERS_ID == data.USERS_ID);

        data["NAME"] = USERS[FindIndex].USERINFO.nickname;
    
    })




    console.log("MATRIX LOG : FetchData : rankingitems:", rankingitems);

    setItems(sortBycount(rankingitems));

    setCurrentloading(false);
  }

  useEffect(()=>{
    FetchData();
  },[])

  useEffect(()=>{
    setItems(items);

  },[refresh])


  return (
    <Container style={containerStyle}>
        <Row margin={'10px auto;'} width={'95%'} style={{background:"#fff", flexWrap:"wrap"}} >
          <InfoLayer>
            <InfoLayerContent>
              
              <div>도전 홍여사 주간 순위는 매주 일요일에 확정됩니다</div>
              <ul>
                <li style={listTag}>도전 홍여사에 매주 1위부터 3위까지는 다음의 상품이 지급 되겠습니다</li>
                <li style={listTag}>1위 : 5만원 신세계 상품권</li>
                <li style={listTag}>2위 : 홍여사 포인트 30000 포인트 지급 </li>
                <li style={listTag}>3위 : 홍여사 포인트 20000 포인트 지급</li>
              </ul>    
            </InfoLayerContent>
          </InfoLayer>     
        </Row>


        {
        currentloading == true ? ( <LottieAnimation containerStyle={LoadingRankingAnimationStyle} animationData={imageDB.loading}
          width={"50px"} height={'50px'}
          />) :(
        <Column>


        <TableHeader>
          <div> 구해줘 홍여사 주간 순위</div>
        </TableHeader>

        {
          items.map((data, index) =>(
            <TableContent
    
            className={`tablerow ${index === highlightIndex ? 'highlighted' : ''}`}>
                <FlexstartRow style={{width:'8%'}}>{index+1}위</FlexstartRow>
                <FlexstartRow style={{width:'25%'}}>{getDateEx3(data.CREATEDT)}</FlexstartRow>
                <FlexstartRow style={{width:'32%'}}>{data.NAME}</FlexstartRow>
                <FlexstartRow style={{width:'25%'}}>{data.COUNT}횟수/{data.MINUTE}초</FlexstartRow>

            </TableContent>
          ))
        }
        </Column>
          )
        }
   
    </Container>
  );

});

export default MobileGameRank;

