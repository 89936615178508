import React, { Fragment, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB, Seekimage } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { GrPrevious } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { CURRENTPAGE } from "../../../utility/router";


const Container = styled.div``;




const PCContactheader = ({callback, image,name}) => {
  const navigation = useNavigate();
  const { user, dispatch } = useContext(UserContext);

  const location = useLocation();
  const _handleprev = () =>{

    if(location.pathname == CURRENTPAGE.MOBILESEARCH){
    
      navigation('/mobilemain');
    }else{
      navigation(-1);
    }
   
  }

  const _handlehistory = () =>{
    navigation("/Mobilesearchhistory");
  }



  return (
    <Container
      id="header"
      style={{
        zIndex: 999,
        position: "fixed",
        background: "#fff",
        width: "30%",
        margin:"0px auto",
        height: "50px",
        left:"35%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ededed",
        fontFamily:"Pretendard-SemiBold",
        flexShrink: 0,
        top:'0px',
      }}
    >


      <div style={{paddingLeft:15, display:"flex", fontWeight:700, fontSize:'16px', color:"#131313", alignItems:"center"}}>
        <div>{name}</div>
      </div>

      <div  style={{paddingRight:15, display:"flex"}}>
        <img onClick={_handleprev} src={imageDB.close2} style={{width:24}}/>
      </div>


    
    
    </Container>
  );
};

export default PCContactheader;
