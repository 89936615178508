
import { setRef, Table } from "@mui/material";
import React, {memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { LoadingCommunityStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import "../screen/css/common.css"
import { FaArrowDownLong } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./mobile.css";
import { ReadTOURCOURSE, ReadTOURCOURSECODE } from "../service/TourCourseService";
import { IoFlagSharp } from "react-icons/io5";
import { TOURISTMENU } from "../utility/life";




const Container = styled.div`

  margin : 0px auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;
  background-color :#f9f9f9;

`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  padding: 0px 0px 30px;
  border-bottom: 1px solid #131313;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.8;
  width:100%;
  font-family: "Pretendard-Light";
  m


`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}

const Taglabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:14px;
  margin-right:10px;
  color :#131313;
  min-width:50px;
  display : flex;
  align-items: center;
  justify-content: flex-start;
`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;

  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
// const CourseItem = [
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)세병관(통제영지)", LATITUDE: "128.423238", LONGITUDE :"34.847749",ORDER:"3",ELAPSE:"2",CONTENT:"종교/역사/전통", INNER:"실내"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)충렬사", LATITUDE: "128.417847", LONGITUDE :"34.846626",ORDER:"4",ELAPSE:"3",CONTENT:"종교/역사/전통", INNER:"실내"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)해저터널", LATITUDE: "128.409908", LONGITUDE :"34.834504",ORDER:"5",ELAPSE:"4",CONTENT:"체험/학습/산업", INNER:"실내"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)착량묘", LATITUDE: "128.410558", LONGITUDE :"34.835804",ORDER:"6",ELAPSE:"5",CONTENT:"종교/역사/전통", INNER:"실외"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)도남관광지", LATITUDE: "128.432766", LONGITUDE :"34.828362",ORDER:"7",ELAPSE:"6",CONTENT:"자연/힐링", INNER:"실외"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)전혁림미술관", LATITUDE: "128.415157", LONGITUDE :"34.826556",ORDER:"8",ELAPSE:"6",CONTENT:"문화/예술", INNER:"실외"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)중앙활어시장", LATITUDE: "128.424279", LONGITUDE :"34.845701",ORDER:"9",ELAPSE:"7",CONTENT:"쇼핑/놀이", INNER:"실외"},
//   {COURSEID:"177", COURSENME:"통영가족여행", REGION:"(통영)남망산국제조각공원", LATITUDE: "128.429715", LONGITUDE :"34.841168",ORDER:"10",ELAPSE:"7",CONTENT:"문화/예술", INNER:"실외"},

// ]
const CourseItemLayer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size:16px;
  line-height: 2;
  margin: 10px 0px;

`
const Box = styled.div`
  border :1px solid #999;
  width: 75%;
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  background:#fff;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
`
const InnerLabel = styled.div`

  background: #fff;
  color: #131313;
  border : 1px solid #ededed;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
  margin-top: 5px;
`
const ContentLabel = styled.div`
  margin-left:3px;
  background: #fff;
  color: #131313;
  border : 1px solid #ededed;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
  margin-top: 5px;

`
const CourseName = styled.div`
    font-family: 'Pretendard-Bold';
    margin-bottom: 10px;
    font-size:20px;
    color :#19a7ff;

`
const ViewBtn = styled.div`
    margin: 20px auto 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: rgb(51, 51, 51);
    height: 38px;
    font-size: 16px;
    font-family: Pretendard-SemiBold;
    width: 33%;
    background:#f9f9f9;
    border : 1px solid #000;


`
const OrderLabel = styled.div`

  color: #131313;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  font-size: 14px;
  width: 80px;


`
const Move = styled.span`
    font-size: 14px;
    color: #999;
    margin-left: 10px;
    padding: 5px 5px;
    display: flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;

`
const CheckPoint = styled.div`
    width: 30px;
    border-radius: 30px;
    height: 30px;
    background: rgb(218 217 217);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FilterEx = styled.div`
    position: fixed;
    width: 100%;
    height: 100px;
    z-index: 10;
    bottom: 40px;


`

const mapstyle = {
  position: "absolute",
  overflow: "hidden",
  top: '10%',
  width:'100%',
};


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const BasicLevel =12;


const MobileLifeTourMapCourse =memo(({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [courseitems, setCourseitems] = useState([]);
  const [codeitems, setCodeitems] = useState([]);
  const [overlays, setOverlays] = useState([]);
  const [curMap, setCurMap] = useState({});




  const _handleControlTrace = (name, item) =>{

    navigate("/Mobiletourcourseanalyze" ,{state :{name :name, COURSEITEM : item}});
  }

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setLoading(loading);
    setCourseitems(courseitems);
    setCodeitems(codeitems);
    console.log("MATRIX LOG : courseitems:",courseitems)

  }, [refresh])

  


  async function FetchData(){

    const codeitemsTmp = await ReadTOURCOURSECODE({});


    setCodeitems(codeitemsTmp[0].TOURCOURSECODEITEM);

    const itemsTmp = await ReadTOURCOURSE({});

    const groupedItemsTmp = itemsTmp[0].TOURCOURSEITEM.reduce((result, item) => {
   	
      // item.category 값이 이미 존재하는지 확인
      const key = item["코스 아이디"];
      if (!result[key]) {
        result[key] = []; // category 값이 없으면 배열 초기화
      }

      const FindIndex = codeitemsTmp[0].TOURCOURSECODEITEM.findIndex(x=>x["코스ID"] == item["코스 아이디"]);
      item["코스명"] = codeitemsTmp[0].TOURCOURSECODEITEM[FindIndex]["코스명"];
      result[key].push(item); // 해당 category에 item 추가
      return result;
    }, {});
    


    const keysWithSizes = Object.keys(groupedItemsTmp).map((key) => ({
      key,
      size: groupedItemsTmp[key].length,
      items: groupedItemsTmp[key].sort((a, b) => a["코스순서"] - b["코스순서"])
    }));

    let CompleteItems = [];

    keysWithSizes.map((data)=>{
      CompleteItems.push(data.items);
    })
      
    console.log("MATRIX LOG : keysWithSizes.map : CompleteItems:", CompleteItems);
    setLoading(false);
    setCourseitems(CompleteItems);
    setRefresh((refresh) => refresh +1);
    ListmapDraw(CompleteItems);
  }

  useEffect(()=>{

    FetchData();
  }, [])
  function ListmapDraw(items){
    var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
    mapOption = { 
            center: new kakao.maps.LatLng(user.latitude, user.longitude), // 지도의 중심좌표
            level: BasicLevel // 지도의 확대 레벨
    };


    var map = new kakao.maps.Map(mapContainer, mapOption);
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT); //

    var overlaysTmp = [];
    var overlays = [];


    items.map((data, index)=>{
   
      let latitude = "";
      let longitude = "";

      let overlay = {
      POSITION : {},
      NAME : "",
      ITEMS :[],
      DESC :"",
      TIME : "",
      id :"",
      }


      latitude = data[0]["위도(도)"];
      longitude =  data[0]["경도(도)"];


      overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
      overlay.id = data[0]["코스 아이디"];
      overlay.NAME = data[0]["코스명"];
      overlay.ITEMS = data;

      data.map((sub)=>{
        overlay.TIME = sub["이동시간"]+'시간소요';
      })
   
      overlaysTmp.push(overlay);
  })
  console.log("MATRIX LOG  : overlaysTmp:", overlaysTmp)


  overlaysTmp.map((overlayData, index) => {


    var content = document.createElement('div');
    var customOverlay ={};

    kakao.maps.event.addListener(map, 'zoom_changed', function() {
      // 현재 지도 레벨 가져오기
      var level = map.getLevel();

      console.log('현재 지도 레벨: ' + level);

      
        // Custom Overlay 내용 생성
        if(level < 12){
          content.className = 'mapcourseoverlay';
          content.innerHTML =
          '  <div>' +
          '    <div class="titleex">'+overlayData.NAME +'</div>' +
          '    <div class="price"> '+overlayData.TIME+'</div>' +
          '  </div>' +
          '</div>';
        }else{
          content.className = 'tourcourse';
          content.innerHTML =
          '  <a>' +
          '    <div>' +
          '    <img src="'+ imageDB.gps+'"style="width:32px;"/>' +
          '    </div>' +
          '  </a>' +
          '</div>';
        }


        

        // Custom Overlay 생성
        customOverlay = new kakao.maps.CustomOverlay({
            position: overlayData.POSITION,
            content: content,
            clickable: true // 클릭 가능하도록 설정
        });


        var customData = {
            id: overlayData.id,
            item : overlayData.ITEMS,
            name : overlayData.NAME
        };
        customOverlay.customData = customData;
        // Custom Overlay 지도에 추가

        customOverlay.setMap(map);
        overlays.push(customOverlay);


        content.addEventListener('click', function(event) {
            
      
          _handleControlTrace(customOverlay.customData.name, customOverlay.customData.item);

        });


    });

  

    setLoading(false);
    setRefresh((refresh) => refresh +1);



  })


  map.setLevel(map.getLevel() +1);
  setCurMap(map);


  //오버레이를 변수에 담아둔다
  setOverlays(overlays);


  // 마커 클러스터러 생성
  var clusterer = new kakao.maps.MarkerClusterer({
    map: map, // 클러스터러가 표시될 지도 객체
    averageCenter: true, // 클러스터의 평균 중심 좌표 사용
    gridSize: 60, // 클러스터 간격 설정
    averageCenter: true,
    minLevel: 8,
    styles: [{ // 클러스터 아이콘 커스텀 스타일
      width: '40px',
      height: '40px',
      background: '#19a4ffc7',
      borderRadius: '50%',
      textAlign: 'center',
      color: '#fff',
      fontSize: '14px',
      lineHeight: '40px'
    }]
  });

  // 마커 클러스터러에 마커 추가
  clusterer.addMarkers(overlays);
        
}


  const _handleViewPopup = (eventname,data ) => {
    console.log("MATRIX LOG : MobileLifeTourCourse : data:", data)
   

    navigate("/Mobiletourcourse" ,{state :{eventname :eventname, COURSEITEM : data}});
  }

  const _handleList =()=>{
    navigate("/Mobilecommunitycontent" ,{state :{name :TOURISTMENU.TOURCOURSE}});
  }



  return (

    <Container style={containerStyle}>    

    {loading == true && (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'} />)}

    <div style={{position:"absolute", width:"100%"}}>
      <div id="map" className="Map" style={mapstyle}></div>
    </div>

    <FilterEx>
      <ViewBtn onClick={_handleList}>리스트로 보기</ViewBtn> 
    </FilterEx>
      
    </Container>
  );

});

export default MobileLifeTourMapCourse;

