import React, {memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";
import { Column, FlexstartColumn } from "../common/Column";
import { imageDB } from "../utility/imageData";
import ButtonEx from "../common/ButtonEx";
import "../screen/css/common.css"
import LottieAnimation from "../common/LottieAnimation";



const Container = styled.div`
  width:100%;

`


const PCLifeAI = memo(({containerStyle, data}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [number, setNumber] = useState(1);
  const [currentloading, setCurrentloading] = useState(true);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);

  }, [refresh])

  async function FetchData(){
       
   
  }

  useEffect(()=>{
  
      FetchData();
  }, [])
  

  const _handleAI = ()=>{
    navigate("/PClogin");
  }
  const handleCanPlay =()=>{

    setRefresh((refresh) => refresh +1);
  }
 
  return (

    <Container style={containerStyle}>

        <Column style={{alignItems:"flex-start",width:"100%"}}>

        <FlexstartColumn style={{justifyContent:"flex-start",alignItems:"flex-start", marginTop:35, width:"100%"}}>
{/* 
  
          {
            currentloading == true  && <LottieAnimation containerStyle={{zIndex:11, marginTop:'30%'}} animationData={imageDB.loadinglarge}/>
          } */}

          <video class="background-video2"  autoPlay controls  loop
          preload="metadata"
          poster={imageDB.introducethumnail3} // 미리보기 이미지 경로
          onLoadedData={() => {setCurrentloading(false); setRefresh((refresh) => refresh +1)}} >
          <source src={imageDB.introduce3}  type="video/mp4" />
          </video> 


          <ButtonEx text={"홍여사 AI 들어가기"} onPress={_handleAI}
          containerStyle={{backgroundColor: "#ff7e19",color :"#fff",borderRadius: "4px", border:"none",boxShadow:"none",
          fontSize: 24, width: "40%", margin:"40px auto", height:"54px"}}/> 


        </FlexstartColumn>


        </Column>
    </Container>
  );

})

export default PCLifeAI;

