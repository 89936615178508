import { Table } from "@mui/material";
import { setLogLevel } from "firebase/firestore";
import React, {memo, Suspense, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import { Column, FlexstartColumn } from "../common/Column";
import LottieAnimation from "../common/LottieAnimation";
import { BetweenRow, FlexstartRow, Row } from "../common/Row";
import { DataContext } from "../context/Data";
import { UserContext } from "../context/User";
import { LoadingCommunityStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { ReadTourRegion } from "../service/LifeService";
import { sleep, useSleep } from "../utility/common";
import { imageDB } from "../utility/imageData";
import "./mobile.css";
import "./table.css";
import { IoMdClose } from "react-icons/io";
import { GrTip } from "react-icons/gr";
import { COLORS } from "../utility/colors";
import CurrentWeather from "./CurrentWeather";
import { TOURISTMENU } from "../utility/life";
import PCSlickSliderComponentEx from "../common/PCSwipeex";


const Container = styled.div`
    width:100%;


`
const style = {
  display: "flex"
};

const DetailLevel = 4;
const DetailMeter =300;
const BasicLevel =7;



const mapstyle = {
    position: "absolute",
    overflow: "hidden",
    top: '10%',
    width:'100%',
};


const PopupWorkEx = styled.div`
  position: absolute;
  top:100px;
  left :30%;
  width: 40%;
  background: #fff;
  border : 1px solid #000;
  z-index: 5;
  overflow-y: auto;
  padding-bottom: 80px;
  height: 700px;
`



const TableLayout = styled.div`
  overflow-y: scroll;
  scroll-behavior: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  margin-bottom: 100px;
`
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}

const MapExbtn = styled.div`
  position: relative;
  top: 10px;
  left: 88%;
  z-index: 3;
  background: #f9f9f9;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderPopupline = styled.div`

  width:30%;
  background:#E3E3E3;
  height:5px;
`
const LableIconLayer = styled.div`
  border-radius: 5px;
  height: 30px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
const LabelText = styled.div`
  margin-left:5px;
  font-family : Pretendard-SemiBold;
  font-size :16px;
`

const MainContent = styled.div`
  margin: unset;
  width: 100%;
  background: #131313;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 20px;
  margin-bottom:10px;

`
const ContentText = styled.div`
  font-size: 16px;
  margin: 10px 0px;
  color : ${COLORS.BLACKCOLOR};
  word-wrap: break-word; /* 단어가 길어도 줄바꿈 */
  overflow-wrap: break-word; /* 최신 표준 속성 */
`

const ButtonLayer = styled.div`
  position: fixed;
  bottom: 80px;
  width: 60px;
  height: 60px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: #195eff;
  right: 15%;

  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
  animation: pulse 1s infinite; /* 애니메이션 설정 */
`

const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Inputstyle={
  background: '#FFF',
  borderRadius:'25px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",

}


const TourLayer = styled.div`
  width: 85%;
  margin: 10px auto;
  border: 1px solid #b7b7b7;
  padding: 10px;
  border-radius: 10px;
  line-height:1.5;

`
const TourRegion = styled.div`
  font-size:12px;
  color : #999;

`

const maplayerstyle ={
  position: "absolute",
  width: "70%",
  left: "16%",
  top: "35%",
}

  
/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const BannerItems =[
  {type:TOURISTMENU.TOURREGION,image : imageDB.tour, main1:"관광정보", main2:"지도에 기반한 관광지  향토 문화시설및 편의시설정보 ", main3:"", color:"#ebf7fa"},
]


const PCLifeTourRegion =memo(({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [overlays, setOverlays] = useState([]);
  const [item, setItem] = useState(null);
  const [currentloading, setCurrentloading] = useState(true);

  const [show, setShow] = useState(true);

  const [expand, setExpand] = useState(false);

  const [curmap, setCurMap] = useState({});

  const [markerex, setMarkerex] = useState(false);

  const [level, setLevel] = useState(0);

  const [search, setSearch] = useState("");
  const [searchpopup, setSearchpopup] = useState(false);
  const [searchitems, setSearchitems] = useState([]);


    // useLayoutEffect(() => {
    // }, []);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     return () => {};
    // }, []);

    useEffect(()=>{
        setOverlays(overlays);
        setCurrentloading(currentloading);
        setItem(item);
        setExpand(expand);
        setCurMap(curmap);
        setMarkerex(markerex);
        setLevel(level);
        setSearch(search);
        setSearchpopup(searchpopup);
        setSearchitems(searchitems);
    }, [refresh])

    useLayoutEffect(()=>{
        async function FetchData(){

            if(data.tourregionitem.length == 0){
              const tourregionitem = await ReadTourRegion();
              const dataToSaveregion = JSON.parse(tourregionitem);
              data.tourregionitem = dataToSaveregion.response.body.items;
  
              datadispatch(data);
      
            }
            await sleep(1000);
            ListmapDraw(data.tourregionitem)
        }
        FetchData();
    }, [])

 
    function findMapIndex(id, items){
        console.log("TCL: findWorkIndex -> workitems", items)
    
       const FindIndex =  items.findIndex(x=>x.rdnmadr  === id)
    
       return FindIndex;
      }

    const _handleControlFromMap = (id, items) =>{
        let FindIndex = findMapIndex(id, items);

        setItem(items[FindIndex]);
        console.log("MATRIX LOG : MobileLifeTourRegion : items:", items[FindIndex])

        setRefresh((refresh) => refresh+1);
    }





    const _handleExmap = () =>{
      navigate("/Mobilemap" ,{state :{WORK_ID :"", TYPE : ""}});
    }

    const _handlePopupClose = () =>{
      setItem(null);
      setRefresh((refresh) => refresh +1);
    }

    const _handleSearchPopup = () =>{
      setSearchpopup(true);
      setRefresh((refresh) => refresh +1);
    }



    function ListmapDraw(datas){
    var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
    mapOption = { 
            center: new kakao.maps.LatLng(user.latitude, user.longitude), // 지도의 중심좌표
            level: BasicLevel // 지도의 확대 레벨
    };



    var map = new kakao.maps.Map(mapContainer, mapOption);
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT); //

    var overlaysTmp = [];
    var overlays = [];


    for (var i = 0; i < datas.length; i ++){
        let overlay = {
        POSITION : {},
        NAME : "",
        ITEMS :datas,
        id :"",
        }

   
        let latitude = "";
        let longitude = "";


  

        latitude = datas[i].latitude;
        longitude =  datas[i].longitude;


        overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
        overlay.id = datas[i].rdnmadr;
        overlay.NAME = datas[i].trrsrtNm;
        overlay.ITEMS = datas
        overlaysTmp.push(overlay);

    }


    overlaysTmp.forEach(function(overlayData, index) {

      var content = document.createElement('div');
      var customOverlay ={};

      kakao.maps.event.addListener(map, 'zoom_changed', function() {
        // 현재 지도 레벨 가져오기
        var level = map.getLevel();

        console.log('현재 지도 레벨: ' + level);

        
          // Custom Overlay 내용 생성

          content.className = 'maptourregionlay';

          if(level > 9){
    
          }else{
            content.innerHTML =
            '  <a>' +
            '    <div>' +
            '    <img src="'+ imageDB.tour+'"style="width:32px;"/>' +
            '    </div>' +
            '    <div class="title">'+overlayData.NAME +'</div>' +
            '  </a>' +
            '</div>';
          }
 
          

          // Custom Overlay 생성
          customOverlay = new kakao.maps.CustomOverlay({
              position: overlayData.POSITION,
              content: content,
              clickable: true // 클릭 가능하도록 설정
          });

          var customData = {
              id: overlayData.id,
              items : overlayData.ITEMS
          };
          customOverlay.customData = customData;
          // Custom Overlay 지도에 추가

          customOverlay.setMap(map);
          overlays.push(customOverlay);
    
      });

  
      // 클릭 이벤트 등록 
      // 지도에서 클릭 햇을때는 리스트에서 클릭 했을때와 달리 별도로 circle을 표시할 필요는 없다
      content.addEventListener('click', function(event) {
        _handleControlFromMap(customOverlay.customData.id, customOverlay.customData.items);

      });

      // 확대/축소 레벨 제한 설정
      const minLevel = 1;
      const maxLevel = 10;

      window.kakao.maps.event.addListener(map, 'zoom_changed', () => {
  
          const level = map.getLevel();

          if (level < minLevel) {
              map.setLevel(minLevel);
          } else if (level > maxLevel) {
              map.setLevel(maxLevel);
          }
      });


    });

  
    map.setLevel(map.getLevel() +1);
    setCurMap(map);


    //오버레이를 변수에 담아둔다
    setOverlays(overlays);


      // 마커 클러스터러 생성
    var clusterer = new kakao.maps.MarkerClusterer({
      map: map, // 클러스터러가 표시될 지도 객체
      averageCenter: true, // 클러스터의 평균 중심 좌표 사용
      minLevel: 8, // 최소 줌 레벨 (확대 시 클러스터가 해제될 레벨)
      gridSize: 60, // 클러스터 간격 설정
      averageCenter: true,
      styles: [{ // 클러스터 아이콘 커스텀 스타일
        width: '40px',
        height: '40px',
        background: '#19a4ffc7',
        borderRadius: '50%',
        textAlign: 'center',
        color: '#fff',
        fontSize: '14px',
        lineHeight: '40px'
      }]
    });

    // 마커 클러스터러에 마커 추가
    clusterer.addMarkers(overlays);
  

    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);



    }




   const _handleSearchPopupClose = () =>{
      setSearchpopup(false);
      setRefresh((refresh) => refresh +1);
   }

   const handleKeyDown = (event)=>{
    if (event.key === 'Enter') {
      Searchfunc();
    }

   }
   
   const Searchfunc = () =>{
    _handleSubmit();
   }
   const _handleSubmit = () =>{

    console.log("MATRIX LOG : TOURREGION : search:", search)
    if(search == ''){
  
      setSearchitems([]);
      setRefresh((refresh) => refresh +1);
      return;
    }

  
    
    let itemsTmp = [];
    data.tourregionitem.map((data)=>{

      const containsWord = data.trrsrtNm.includes(search); // 'React'가 포함되어 있는지 확인

      if(containsWord != ''){
        data["open"] = false;
        itemsTmp.push(data);
      }else{

        const containsWord2 = data.rdnmadr.includes(search); // 'React'가 포함되어 있는지 확인

        if(containsWord2 != ''){
          data["open"] = false;
          itemsTmp.push(data);
        }

        console.log("MATRIX LOG : MobileLifeCampingRegion :불일치:", containsWord)
      }
      
    })
    console.log("MATRIX LOG : MobileLifeCampingRegion : _handleSubmit:", itemsTmp);

    setSearchitems(itemsTmp);
    setRefresh((refresh) => refresh +1);

   }

   const _handleView= (data)=>{
    if(data.open == false){
      data.open = true;
    }
    setSearchitems(searchitems);
    setRefresh((refresh) => refresh +1);
   }

   const _handleViewClose= (data)=>{
    if(data.open == true){
      data.open = false;
    }
    setSearchitems(searchitems);
    setRefresh((refresh) => refresh +1);
   }


    const RegionItem =({item, width})=>{

      return (
        <>
          <FlexstartColumn style={{width:`${width}`,margin:"10px auto 30px"}}>
          <MainContent>{item.trrsrtNm}</MainContent>
          <div>
          <FlexstartRow>
          <LableIconLayer>
            <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>구분</LabelText>

          </FlexstartRow>
          <ContentText>{item.trrsrtSe}</ContentText>
          </div>
          <FlexstartRow>
          <LableIconLayer>
            <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>주소</LabelText>

          </FlexstartRow>
          <ContentText>{item.rdnmadr}</ContentText>
          {
          item.cnvnncFclty != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
            <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>공공편익</LabelText>

          </FlexstartRow>
          <ContentText>{item.cnvnncFclty}</ContentText>
          </>
          }
          {
          item.stayngInfo != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
              <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>숙박시설</LabelText>
        
            </FlexstartRow>
            <ContentText>{item.stayngInfo}</ContentText>
          </>
          }
          {
          item.mvmAmsmtFclty != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
              <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>오락시설</LabelText>
        
            </FlexstartRow>
            <ContentText>{item.mvmAmsmtFclty}</ContentText>
          </>
          }
          {
          item.recrtClturFclty != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>문화시설</LabelText>

            </FlexstartRow>
            <ContentText>{item.recrtClturFclty}</ContentText>
          </>
          }
          {
          item.hospitalityFclty != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>접객시설</LabelText>

            </FlexstartRow>
            <ContentText>{item.hospitalityFclty}</ContentText>
          </>
          }
          {
          item.recrtClturFclty != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>문화시설</LabelText>

            </FlexstartRow>
            <ContentText>{item.recrtClturFclty}</ContentText>
          </>
          }
          {
          item.sportFclty != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>스포츠시설</LabelText>

          </FlexstartRow>
          <ContentText>{item.sportFclty}</ContentText>
          </>
          }
          {
          item.aceptncCo != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>문화시설</LabelText>

            </FlexstartRow>
            <ContentText>{item.aceptncCo}평</ContentText>
          </>
          }
          {
          item.prkplceCo != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>주차가능</LabelText>

          </FlexstartRow>
          <ContentText>{item.prkplceCo}대</ContentText>
          </>
          }

          {
          item.trrsrtIntrcn != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>소개</LabelText>

            </FlexstartRow>
            <ContentText>{item.trrsrtIntrcn}</ContentText>
          </>
          }
          {
          item.phoneNumber != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>전화번호</LabelText>

            </FlexstartRow>
            <ContentText>{item.phoneNumber}</ContentText>
          </>
          }
          {
          item.institutionNm != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>관리기관</LabelText>

          </FlexstartRow>
          <ContentText>{item.institutionNm}</ContentText>
          </>
          }

          <FlexstartRow style={{marginTop:10}}>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>관광지 주변 날씨정보</LabelText>

          </FlexstartRow>

          </FlexstartColumn>

          <Suspense fallback={<div>Loading...</div>}>
          <CurrentWeather containerStyle={{marginBottom:30, marginTop:-30}} addr={item.rdnmadr} type={'tourregion'} />
          </Suspense>
        </>

      )
    }

    return (

    <Container style={containerStyle}>

    <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'}/>

      {
        item != null && <PopupWorkEx>
          
          <div style={{display:"flex", flexDirection:"column",width:"100%" }}>
            <Row style={{height:"30px"}}>
                <div style={{position:"absolute", right:10, top:5}} onClick={_handlePopupClose} >
                  <IoMdClose  size={25}/>
                </div>
            </Row>

            <Suspense fallback={<div>Loading...</div>}>
            <RegionItem item={item} width={'90%'}/>
            </Suspense>
 

          </div>
        </PopupWorkEx>
      }

         
      {
        searchpopup == true && <PopupWorkEx>
          <div style={{display:"flex", flexDirection:"column",width:"100%" }}>
            <Row style={{height:"30px"}}>
            <div style={{position:"absolute", right:10, top:5}} onClick={_handleSearchPopupClose} >
            <IoMdClose  size={25}/>
            </div>
            </Row>

            <SearchLayer>
            <input  type="text" style={Inputstyle}
            onKeyDown={handleKeyDown} 
            class="communityinput"
            value={search} onChange={(e)=>{
            setSearch(e.target.value);
            setRefresh((refresh) => refresh +1);

            }}
            placeholder="관광지 이름, 지역 입력" />
            <img className ="searchicon" src={imageDB.redsearch} style={{width:20, height:20, position:"absolute", left:'88%'}} onClick={_handleSubmit}/>
            </SearchLayer>

            {
              searchitems.map((data)=>(
                <TourLayer>
                  <div>{data.trrsrtNm}</div>
                  <BetweenRow>
                  <TourRegion>{data.rdnmadr}</TourRegion>
                  {
                    data.open == false ? ( <div style={{color:"#ff7e19", fontSize:12}} onClick={()=>{_handleView(data)}}>상세보기</div>):(
                      <div style={{color:"#ff7e19", fontSize:12}} onClick={()=>{_handleViewClose(data)}}>접기</div>
                    )
                  }
                 
                
                  </BetweenRow>   
                  {
                    data.open == true && <RegionItem item={data} width={'100%'}/>
  
                  }
                </TourLayer>
              ))
            }





          </div>
        </PopupWorkEx>
      }


      {
        currentloading == true  && <LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'} />
      }

      
      <div style={maplayerstyle}>
        <div id="map" className="Map" style={mapstyle}></div>
      </div>
      

      {
        currentloading == false  &&
        <ButtonLayer onClick={_handleSearchPopup}>
        <div style={{fontSize:18}}>검색</div>
        </ButtonLayer>
      }


    </Container>
    );

});

export default PCLifeTourRegion;

