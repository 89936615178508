import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Label from "../../common/Label";
import { GoNoEntry } from "react-icons/go";

import "./MobileMaincontainer.css";
import "./MobileCommunitycontainer.css";
import MobileStoreInfo from "../../components/MobileStoreInfo";
import { ROOMSIZE } from "../../utility/room";
import { ReadRoom } from "../../service/RoomService";
import PCRoomItem from "../../components/PCRoomItem";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { ensureHttps, removeSymbols, shuffleArray, useSleep } from "../../utility/common";
import { BOARDMENU, CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../../utility/life";
import MobileTourLife from "../../components/MobileLifeTour";
import MobilePerformanceLife from "../../components/MobileLifePerformance";
import MobileMedicalLife from "../../components/MobileLifeMedical";
import MobileLifePerformance from "../../components/MobileLifePerformance";
import MobileLifeMedical from "../../components/MobileLifeMedical";
import MobileLifeTour from "../../components/MobileLifeTour";
import MobileLifeFamily from "../../components/MobileLifeFamily";
import MobileLifeConvenience from "../../components/MobileLifeConvenience";
import MobileLifeTourFestival from "../../components/MobileLifeTourFestival";
import MoblileLifePerformanceEvent from "../../components/MobileLifePerformanceEvent";
import MoblileLifePerformanceCinema from "../../components/MobileLifePerformanceCinema";
import MobileLifeTourRegion from "../../components/MobileLifeTourRegion";
import MobileLifeTourCountry from "../../components/MobileLifeTourCountry";
import MobileLifeTourPicture from "../../components/MobileLifeTourPicture";
import MobileLifeMedicalDrug from "../../components/MobileLifeMedicalDrug";
import MobileLifeMedicalHospital from "../../components/MobileLifeMedicalHospital";
import MobileLifeFoodDrug from "../../components/MobileLifeFoodDrug";
import MobileLifeCampingRegion from "../../components/MobileLifeCampingRegion";
import MobileLifeBoard from "../../components/MobileLifeBoard";
import MobileRecipeBoard from "../../components/MobileRecipeBoard";
import LazyImage from "../../common/LasyImage";

import { GrTip } from "react-icons/gr";
import { TbComponents } from "react-icons/tb";
import Recipe from "../../components/Recipe";
import { ReadRECIPE } from "../../service/RecipeService";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Empty from "../../components/config/Empty";
import { LoadingSearchAnimationStyle } from "../../screen/css/common";
import { model } from "../../api/config";

const Container = styled.div`
  padding:50px 0px;
  width: 90%;
  margin: 0 auto;
`


const SubContent = styled.div`
  width: 90%;
  margin: 0 auto;

`
const Content = styled.div``

const ContentText = styled.div`
  font-size: 16px;
  font-family : Pretendard-SemiBold;
  margin : 20px 0px;
`
const Tag1 = styled.div`
    font-size: 16px;
    width: 30%;
    color: #070606;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 25px;
    margin: 20px 0px;
    border: 1px solid #ededed;

`

const Tag2 = styled.div`
  font-size: 16px;
  width: 30%;
  color: #070606;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 25px;
  margin: 20px 0px;
  border: 1px solid #ededed;
  margin-left:20px;

`
const LableIconLayer = styled.div`
  background: #ff7e19;
  width: 30px;
  border-radius: 5px;
  height: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
`
const LabelText = styled.div`
  margin-left:5px;
  font-family : Pretendard-SemiBold;
  font-size :16px;
`
const Line = styled.div`
  border: 1px dotted;
  background: #f1f1f1;
  width: 50%;

`
const Property = styled.div`
width: 10%;
display: flex;
justify-content: flex-end;
font-family:Pretendard-Light;

`
const RecommendTitle = styled.div`
  font-family: 'Pretendard-Bold';
  margin: 20px 0px;

`
const Recipename = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
`
const RecommendButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
  color : #999;
`

const MenuButton = styled.div`
  background: ${({enable})=> enable == true ? ('#ff7e19'):('#fff')};
  color: ${({enable})=> enable == true ? ('#fff'):('#131313')};
  padding: 5px 15px;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom:5px;
  font-size:16px;
  display: flex;
  justify-content: center;

`
const CheckLayer = styled.div`
  display : flex;
  width :20px;
  height :15px;
`

const BoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom:30px;
`

const BoxItem = styled.div`
  width : 49%;
  height : 100%; 
  margin-bottom:40px;

`
const FreezeItem = styled.div`
  width : 100%;
  height : 100%; 
  margin-bottom:40px;
  display : flex;
  flex-direction: row;

`

const FreezeBoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FreezeBoxItem = styled.div`
  width : 49%;
  background: ${({enable})=> enable== true ? ('bisque') :('#f9f9f9')};
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction:column;
  border-radius: 5px;
  margin-bottom:5px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const Recipetip = styled.div`
font-size: 14px;
font-family: 'Pretendard-Light';
`
const Recipereview = styled.div`
font-size: 14px;
font-family: 'Pretendard-Light';
margin-top:10px;
`
const AILabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Pretendard-SemiBold;
  width: 100%;
  align-items: center;
  padding-left: 5px;
  font-size: 18px;
  margin-top: 20px;
  color: #efebea;
  background: #ff7e19;
  padding: 15px;
  width: 90%;
`
const Return = styled.div`
  margin-top:20px;
  white-space:pre-line;
  font-size: 16px;
  padding: 0px 5px;
  word-break: break-word;
  line-height:2;

`

/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const MobileFreezeRecipecontainer =({containerStyle, filterary}) =>  {



  const [loading, setLoading] = useState(true);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [totalitems, setTotalitems] = useState([]);
  const [displayitems, setDisplayitems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [freezerecommenditems, setFreezerecommenditems] = useState([]);
  const [freezerecommenddisplayitems, setFreezerecommenddisplayitems] = useState([]);

  const [categoryMenu, setCategoryMenu] = useState('전체');
  const [visibleImages, setVisibleImages] = useState(10);
  const [airecipe, setAirecipe] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setFreezerecommenditems(freezerecommenditems);
    setFreezerecommenddisplayitems(freezerecommenddisplayitems);
    setCategoryItems(categoryItems);
    setTotalitems(totalitems);
    setDisplayitems(displayitems);
    setLoading(loading);
    setAirecipe(airecipe);

  },[refresh])


  /**

   */
  useEffect(()=>{
    const now = moment();
 
    async function FetchData(){


      let items =[];
      let totalitems = await ReadRECIPE();

      console.log("MATRIX LOG : MobileFreezeRecipecontainer : filterary:", filterary)


     // setTotalitems(items);
    
      let itemsTmp = shuffleArray(items);
      setDisplayitems(itemsTmp);

      let aisearch = "";
      if(filterary.length == 1){

        aisearch += filterary[0];
        aisearch +="을 이용하여 만들수 있는 한식요리 와 양식요리에 대해 알려주세요";
        totalitems.map((data)=>{
          let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
          if(startposition != -1){
            // console.log("handleRecipeSearch", filterary[0],data);
            items.push(data);
          }   
        });
        console.log("MATRIX LOG : totalitems.map : items:", items)
        
      }else if(filterary.length ==2){
        aisearch += filterary[0];
        aisearch += "와"
        aisearch += filterary[1];
        aisearch +="을 이용하여 만들수 있는 한식요리 와 양식요리에 대해 알려주세요";

        totalitems.map((data)=>{
  
          let startposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[0]);
          if(startposition != -1){
            let twoposition = data.ITEM.RCP_PARTS_DTLS.indexOf(filterary[1]);
            if(twoposition != -1){
              items.push(data);
            } 
          }
        });
      }else{
        items = [];
      }

      const result = await model.generateContent(aisearch);
      const response = result.response;
      const text = response.text();
      setAirecipe(removeSymbols(text));

      const groupedItemsTmp = items.reduce((result, item) => {
  
        // item.category 값이 이미 존재하는지 확인
        const key = item.ITEM.RCP_PAT2;
        
        if (!result[key]) {
          result[key] = []; // category 값이 없으면 배열 초기화
        }
        
        result[key].push(item); // 해당 category에 item 추가
        return result;
      }, {});
      
      console.log("TCL: _handleRecipeSearch -> groupedItemsTmp", groupedItemsTmp)
  
  
      const keysWithSizes = Object.keys(groupedItemsTmp).map((key) => ({
        key,
        size: groupedItemsTmp[key].length,
      }));
  
      setCategoryItems( keysWithSizes);
   
      console.log("TCL: _handleRecipeSearch -> groupedItemsTmp", keysWithSizes)
  
      setLoading(false);
      setFreezerecommenditems(items);
      setFreezerecommenddisplayitems(items);
      setRefresh((refresh) => refresh +1);

    } 
    FetchData();

  }, [])


  const _handleCategory = (category)=>{

    setCategoryMenu(category);

    if(category == '전체'){
      setFreezerecommenddisplayitems(freezerecommenditems);
    }else{

      let items = [];
      freezerecommenditems.map((data)=>{
        if(data.ITEM.RCP_PAT2 == category){
          items.push(data);
        }
      })

      setFreezerecommenddisplayitems(items);
    }
    setRefresh((refresh) => refresh +1);
  }

  const _handleRecipe = (item)=>{
    navigate("/Mobilerecipe", {state : {item: item.ITEM}})
  }

  const _handleFreezeRecipe = (item)=>{
    navigate("/Mobilerecipe", {state : {item: item}})
  }

  return (
    <>
    
    {loading == true ? (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
            width={"100px"} height={'100px'}/>) :(    <Container style={containerStyle}>

              <AILabel>홍여사가 요리재료를 바탕으로 분석한 레시피는 다음과 같습니다</AILabel>
              <Return>
                  {airecipe}
              </Return>

              <AILabel>맞춤 레시피는 다음과 같습니다</AILabel>

              {
                freezerecommenddisplayitems.length == 0 && 
                <Column style={{marginTop:50, marginBottom:40}}>
                  <Empty content={'냉장고 재료에 맞는 맞춤 레시피가 없습니다'} fontsize={'14px'}/>
                </Column>
              }
              {
                freezerecommenddisplayitems.length > 0 && <Column style={{marginBottom:50}}>
                
                <FlexstartRow style={{width:"100%", flexWrap:"wrap", marginBottom:20, marginTop:10}}>
                  <MenuButton enable={categoryMenu == '전체'} onClick={()=>{_handleCategory('전체')}}>전체</MenuButton>
                  {
                    categoryItems.map((data)=>(
                      <MenuButton enable ={categoryMenu ==data.key}  onClick={()=>{_handleCategory(data.key)}}>{data.key}{data.size}</MenuButton>
                    ))
                  }
                </FlexstartRow>
          
                <BoxLayer>
          
          
          
                  {
                    freezerecommenddisplayitems.slice(0, visibleImages).map((data, index)=>(
                      <FreezeItem key= {index} onClick={()=>{_handleRecipe(data)}} >
                        <div style={{width:"40%"}}>
                        <LazyLoadImage
                          style={{borderRadius:10, background:"#ededed"}}
                          src={ensureHttps(data.ITEM.ATT_FILE_NO_MAIN)} 
                          alt="Lazy Loaded Example"
                          effect="blur"
                          offset={100} // 이미지가 보이기 100px 전 미리 로드
                          width={'90%'}
                        />
                      </div>
          
                      <div style={{width:"60%"}}>
                      <Recipename>{data.ITEM.RCP_NM}</Recipename>
                      <Recipetip>{data.ITEM.RCP_NA_TIP}</Recipetip>
                      <Recipereview>조회수 {data.VIEW}</Recipereview>
                      </div>
          
                    
                    </FreezeItem>
                    ))
                  }
                </BoxLayer>
                </Column>
              } 
              </Container>)
      }
    </>


  );

}

export default MobileFreezeRecipecontainer;

