
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { FiPhoneIncoming } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import LottieAnimation from "../common/LottieAnimation";
import { UserContext } from "../context/User";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import MobileWorkMapPopup from "../modal/MobileMapPopup/MobileWorkMapPopup";
import MobileSuccessPopup from "../modal/MobileSuccessPopup/MobileSuccessPopup";
import { CreateChat, CreateMessage, ReadChat } from "../service/ChatService";

import { Readuserbyusersid } from "../service/UserService";
import { ReadWorkByIndividually } from "../service/WorkService";
import { sleep, useSleep } from "../utility/common";
import { getDateFullTime } from "../utility/date";
import { imageDB, Seekimage } from "../utility/imageData";
import { CHATCONTENTTYPE, PCMAINMENU } from "../utility/screen";
import { REQUESTINFO } from "../utility/work";




const Container = styled.div`
  max-height: 550px;
  overflow-y: auto;
  width: 90%;
  margin: 60px auto 0px;
  background :#fff;

`
const style = {
  display: "flex"
};

const ResultContent = {
  width: '160px',
  fontSize: '14px',
  fontFamily: 'Pretendard-Light',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",
 
}

const LoadingAnimationStyle={
  zIndex: 11,
  position: "absolute",
  top: "40%",
  left: "35%"
}

const mapstyle = {
  position: "absolute",
  overflow: "hidden",
  width:'100%',
  height:'200px'
};

const { kakao } = window;

const MobileWorkReport =({containerStyle, messages, WORK_ID, WORKTYPE, WORK_STATUS, NICKNAME}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');
  const [worktype, setWorktype] = useState(WORKTYPE);


  const [supporterwork, setSupporterwork] = useState(false);
  const [ownerwork, setOwnerwork] = useState(false);
  const [closework, setClosework] = useState(false);
  const [currentloading, setCurrentloading] = useState(false);

  const [supportWorkSuccess, setSupportWorkSuccess] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setPopupstatus(popupstatus);  
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);
    setClosework(closework);
    setSupportWorkSuccess(supportWorkSuccess);
    setCurrentloading(currentloading);
  }, [refresh])


  useEffect(()=>{

  },[])
  useEffect(()=>{
      async function FetchData(){
       
      // 마감되었는지 확인 필요
      if(WORK_STATUS == 1){
          setClosework(true);
       }

       // 자신의 소유인지 확인 필요
       const WORK_INFO = await ReadWorkByIndividually({WORK_ID});
       if(WORK_INFO.USERS_ID == user.users_id){
          setOwnerwork(true);
       }

       // 지원 햇는지 확인 필요
       const USERS_ID = user.users_id;
       const chatitems = await ReadChat({USERS_ID});


       if(chatitems.length > 0){
        const FindIndex = chatitems.findIndex(x=>x.INFO.WORK_ID == WORK_ID);
        if(FindIndex != -1){
         if(chatitems[FindIndex].SUPPORTER_ID == USERS_ID){
           setSupporterwork(true);
         }
        }
       }

      const FindIndex = WORK_INFO.WORK_INFO.findIndex(x=>x.requesttype == '지역');

  
      if(FindIndex != -1 ){
   

        var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
        mapOption = { 
          center: new kakao.maps.LatLng(WORK_INFO.WORK_INFO[FindIndex].latitude, WORK_INFO.WORK_INFO[FindIndex].longitude), // 지도의 중심좌표
          level: 5 // 지도의 확대 레벨
        };
        var map = new kakao.maps.Map(mapContainer, mapOption);
    
        const zoomControl = new kakao.maps.ZoomControl();
        map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT); //
        
        var imageSrc = Seekimage(WORK_INFO.WORKTYPE); // 마커 이미지의 URL
        var imageSize = new kakao.maps.Size(36, 36); // 마커 이미지의 크기
        var imageOption = {offset: new kakao.maps.Point(27, 69)}; // 마커의 좌표에 일치시킬 이미지 안의 좌표
    
        // 마커 이미지를 생성합니다
        var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
    
    
        const markerPosition = new window.kakao.maps.LatLng(WORK_INFO.WORK_INFO[FindIndex].latitude, WORK_INFO.WORK_INFO[FindIndex].longitude);
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
          image: markerImage // 마커 이미지 설정
        });
    
        // 마커를 지도 위에 표시
        marker.setMap(map);
   
        setCurrentloading(false);
      }

  
      }
      FetchData();
  }, [])
  const _handleReset = () =>{

  }

  /**
   * WORK_ID를가지고 
   * 1) WORK 정보를 가져돈다
   * 2) 지원자 의 정보를 가져온다
   */
  const _handleReqComplete = async(WORK_ID) =>{
  
     const WORK_INFO = await ReadWorkByIndividually({WORK_ID});

    

     let USERS_ID = WORK_INFO.USERS_ID;
     let OWNER = await Readuserbyusersid({USERS_ID});

     console.log("TCL: _handleReqComplete -> WORK_INFO", USERS_ID, OWNER)
     if(OWNER == -1){
       return;
     }
     const OWNER_ID = OWNER.USERS_ID;

     USERS_ID = user.users_id;;
     const SUPPORTER = await Readuserbyusersid({USERS_ID});
     const SUPPORTER_ID = SUPPORTER.USERS_ID;

     const TYPE = PCMAINMENU.HOMEMENU;
     const INFO = WORK_INFO;

     const CHAT_ID = await CreateChat({OWNER, OWNER_ID,  SUPPORTER, SUPPORTER_ID, INFO, TYPE});

     const CHAT_CONTENT_TYPE = CHATCONTENTTYPE.ENTER;
     const read = [];
     read.push(user.users_id);
     read.push(SUPPORTER_ID);
     const users_id = user.users_id;

     const msg = SUPPORTER.NAMEITEMS[SUPPORTER.NAMEITEMS.length -1].name+ "님이 일감에 지원 하였습니다.";

     await CreateMessage({
       CHAT_ID,
       msg,
       users_id,
       read,
       CHAT_CONTENT_TYPE,
     
     });

     setSupportWorkSuccess(true);
     setRefresh((refresh) => refresh +1);


  }

  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
    setRefresh((refresh) => refresh +1);
  };

  const supportsuccesscallback = async () =>{
    setSupportWorkSuccess(false);
    navigate("/Mobilechat");
    setRefresh((refresh) => refresh +1);
  }

  const _handleMapview= (lat, long, worktype)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setWorktype(worktype);
    setClosework(closework);
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);

    setRefresh((refresh) => refresh +1);

  }

 
  return (

    <Container style={containerStyle}>

      {
        popupstatus == true && <MobileWorkMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
        top={'30%'}  left={'10%'} height={'280px'} width={'280px'} name={worktype} markerimg={Seekimage(worktype)}
        />
      }

      {
        supportWorkSuccess == true && <MobileSuccessPopup callback={supportsuccesscallback} content ={'일감에 정상적으로 지원되었습니다'} />
      }

      {
        currentloading == true ? (<LottieAnimation containerStyle={LoadingAnimationStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}/>) :(<>

          <div style={{padding:"10px"}}>

            <div style={{marginTop: 10, color :'#131313', fontSize:18, width:'90%', marginLeft:10, fontFamily:"Pretendard-SemiBold", textAlign:"center"}}>요구 사항</div>
            <table class="workreport-table" style={{  margin: '10px auto 0px', borderTop: "2px double #434343",   minHeight:"300px"}}>      
            <tbody>

              <tr>
                <td>{'요청자'}</td>
                <td>{NICKNAME}</td>
              </tr>   
              {
                messages.map((data)=>(
                  <>
                  {
                  data.type =='response' &&
                  <tr>
                  <td>{data.requesttype}</td>
                  <td>
                  <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                  {
                    (data.requesttype == REQUESTINFO.COMMENT && data.result =='') ? (
                      <div> {'별도 요구사항 없음'}</div>):(
                    <div>{
                      data.requesttype == REQUESTINFO.REQDATE ? (<div>{getDateFullTime(data.result)}</div>) :
                      (<div>{data.result}</div>)
                      
                    }</div>
                  )
                  }  

                  {/* {
                    data.requesttype == REQUESTINFO.CUSTOMERREGION &&
                    <div  onClick={()=>{_handleMapview(data.latitude,data.longitude, messages.WORKTYPE)}}><img src={imageDB.movegps} style={{width:36}}/> </div>
                  } */}
                  </div>
                  </td>
                  </tr>
                  }
                  </>                  
                ))
              }
            </tbody>
            </table>

            <div class="map-data">
              <div id="map" className="Map" style={mapstyle}></div>
            </div>
          </div>



          <div style={{display:"flex", flexDirection:"row", margin:'10px auto', width:'85%',justifyContent: "center", bottom:10, position:"absolute"}}>
            {
              closework == true ? (<Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 마감된 일감'}/>)
              :(
                <>
                  {
                    supporterwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 지원한 일감'}/>
                  }

                  {
                    ownerwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'본인이 등록한 일감'}/>
                  }
                  {
                    (supporterwork ==false && ownerwork == false) && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} onPress={()=>{_handleReqComplete(WORK_ID)}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#FF7125'} color={'#fff'} text={'지원하기'}/>

                  }
                </>
              )
            }
          </div>
          </>)
      }


    </Container>
  );

}

export default MobileWorkReport;

