import React, { useState, createContext } from "react";

const DataContext = createContext({
  data: {
    communityitems :[],
    workitems :[],
    roomitems :[],
    workmenu : "",
    tourpictureitem :[],
    tourregionitem:[],
    tourfestivalitem :[],
    tourcountryitem :[],
    performanceeventitem : [],
    performancecinemaitem:[],
    hospitalregionitem : [],
    campingregionitem :[],
    gameitems:[],
    freezeitems:[],
    freezeitem:{},
    regioncodeitems :[]
  

  },
  datadispatch: () => {},
});

const DataProvider = ({ children }) => {
  const [data, setData] = useState({});

  const datadispatch = ({
    communityitems,
    workitems,
    roomitems,
    workmenu,
    tourpictureitem,
    tourregionitem,
    tourfestivalitem,
    tourcountryitem,
    performanceeventitem,
    performancecinemaitem,
    hospitalregionitem,
    campingregionitem,
    gameitems,
    freezeitems,
    freezeitem,
    regioncodeitems,

 
  }) => {
    setData({
      communityitems,
      workitems,
      roomitems,
      workmenu,
      tourpictureitem,
      tourregionitem,
      tourfestivalitem,
      tourcountryitem,
      performanceeventitem,
      performancecinemaitem,
      hospitalregionitem,
      campingregionitem,
      gameitems,
      freezeitems,
      freezeitem,
      regioncodeitems
    });
  };

  const value = { data, datadispatch };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
export { DataContext, DataProvider };
