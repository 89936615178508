
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import LazyImage from "../common/LasyImage";
import LottieAnimation from "../common/LottieAnimation";
import { UserContext } from "../context/User";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import MobileWorkMapPopup from "../modal/MobileMapPopup/MobileWorkMapPopup";
import { ReadChat } from "../service/ChatService";
import { ReadRoomByIndividually } from "../service/WorkService";
import { getDateFullTime } from "../utility/date";
import { imageDB, Seekimage } from "../utility/imageData";
import { REQUESTINFO } from "../utility/work";




const Container = styled.div`
  max-height: 550px;
  overflow-y: auto;
  width: 90%;
  margin: 60px auto 0px;
  background: #fff;
`
const style = {
  display: "flex"
};

const ResultContent = {
  width: '180px',
  fontSize: '14px',
  fontFamily: 'Pretendard-Light',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"none",
 
}

const LoadingAnimationStyle={
  zIndex: 11,
  position: "absolute",
  top: "40%",
  left: "35%"
}

const mapstyle = {
  position: "absolute",
  overflow: "hidden",
  width:'100%',
  height:'200px'
};

const { kakao } = window;


const MobileRoomWorkReport =({containerStyle, messages, ROOM_ID, ROOMTYPE, ROOM_STATUS,NICKNAME}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [popupstatus, setPopupstatus] = useState(false);

  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');
  const [roomtype, setRoomtype] = useState(ROOMTYPE);

  const [supporterwork, setSupporterwork] = useState(false);
  const [ownerwork, setOwnerwork] = useState(false);
  const [closework, setClosework] = useState(ROOM_STATUS);
  const [currentloading, setCurrentloading] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setPopupstatus(popupstatus);  
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);
    setClosework(closework);
    setCurrentloading(currentloading);
  }, [refresh])
  useEffect(()=>{
    async function FetchData(){
     
    // 마감되었는지 확인 필요
    if(ROOM_STATUS == 1){
        setClosework(true);
     }

     // 자신의 소유인지 확인 필요
     const ROOM_INFO = await ReadRoomByIndividually({ROOM_ID});
     if(ROOM_INFO.USERS_ID == user.users_id){
        setOwnerwork(true);
     }

     // 지원 햇는지 확인 필요
     const USERS_ID = user.users_id;
     const chatitems = await ReadChat({USERS_ID});


     if(chatitems.length > 0){
      const FindIndex = chatitems.findIndex(x=>x.INFO.ROOM_ID == ROOM_ID);
      if(FindIndex != -1){
       if(chatitems[FindIndex].SUPPORTER_ID == USERS_ID){
         setSupporterwork(true);
       }
      }
     }

     const FindIndex = ROOM_INFO.ROOM_INFO.findIndex(x=>x.requesttype == '지역');

  
     if(FindIndex != -1 ){
  

       var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
       mapOption = { 
         center: new kakao.maps.LatLng(ROOM_INFO.ROOM_INFO[FindIndex].latitude, ROOM_INFO.ROOM_INFO[FindIndex].longitude), // 지도의 중심좌표
         level: 5 // 지도의 확대 레벨
       };
       var map = new kakao.maps.Map(mapContainer, mapOption);
   
       const zoomControl = new kakao.maps.ZoomControl();
       map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT); //
       
       var imageSrc = imageDB.movegps; // 마커 이미지의 URL
       var imageSize = new kakao.maps.Size(36, 36); // 마커 이미지의 크기
       var imageOption = {offset: new kakao.maps.Point(27, 69)}; // 마커의 좌표에 일치시킬 이미지 안의 좌표
   
       // 마커 이미지를 생성합니다
       var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);
   
   
       const markerPosition = new window.kakao.maps.LatLng(ROOM_INFO.ROOM_INFO[FindIndex].latitude, ROOM_INFO.ROOM_INFO[FindIndex].longitude);
       const marker = new window.kakao.maps.Marker({
         position: markerPosition,
         image: markerImage // 마커 이미지 설정
       });
   
       // 마커를 지도 위에 표시
       marker.setMap(map);
  
       setCurrentloading(false);
     }


    }
    FetchData();
}, [])

  const _handleReset = () =>{

  }
  const _handleReqComplete =(index) =>{

  }

  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
    setRefresh((refresh) => refresh +1);
  };

  const _handleMapview= (lat, long, worktype)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setRoomtype(roomtype);
    setRefresh((refresh) => refresh +1);

  }

 
  return (

    <Container style={containerStyle}>

      {
        popupstatus == true && <MobileWorkMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
        top={'30%'}  left={'10%'} height={'280px'} width={'280px'} name={roomtype} markerimg={Seekimage(roomtype)}
        />
      }

      {
        currentloading == true ? (<LottieAnimation containerStyle={LoadingAnimationStyle} animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'}/>) :(<>
          <div style={{marginTop: 10, color :'#131313', fontSize:18, width:'90%', marginLeft:10, fontFamily:"Pretendard-SemiBold", textAlign:"center"}}>요구 사항</div>
          <table class="roomreport-table" style={{  margin: '10px auto 0px', borderTop: "2px double #434343",   minHeight:"500px"}}> 
            
            <tbody>
              <tr>
                <td>{'요청자'}</td>
                <td>{NICKNAME}</td>
              </tr>   
              {
                messages.map((data)=>(
                  <>
                  {
                  ( data.type =='response'  && data.requesttype != REQUESTINFO.ROOM) &&
                  <tr>
                  <td>{data.requesttype}</td>
                  <td>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                    {
                      data.requesttype == REQUESTINFO.COMMENT ? (
                      <textarea style={ResultContent} value={data.result}/>):(
                      <div> {
                    
                          data.requesttype == REQUESTINFO.REQDATE ? (<div>{getDateFullTime(data.result)}</div>) :
                          (<div>{data.result}</div>)
                          
                        
                      }</div>
                    )
                    }  

        
                    </div>
                  </td>
                  </tr>
                  }

                  {
                  ( data.type =='response'  && data.requesttype ==REQUESTINFO.ROOM) &&
                  <tr>
                  <td>{data.requesttype}</td>
                  <td>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                      {/* <img src={data.result} style={{width:200, objectFit:'contain'}} /> */}
                      <LazyImage src={data.result} containerStyle={{width:"200px",objectFit:"contain" }}/>
                    </div>
                  </td>
                  </tr>
                  }
                  </>                  
                ))
              }
            </tbody>

      
        </table>
        <div class="map-data">
              <div id="map" className="Map" style={mapstyle}></div>
            </div>
        <div style={{display:"flex", flexDirection:"row", margin:'10px auto', width:'85%',justifyContent: "center",position:"absolute", bottom:10 }}>
            {
              closework == 1 ? (<Button containerStyle={{border: 'none', fontSize:16, marginTop:10,fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 마감된 일감'}/>)
              :(
                <>
                  {
                    supporterwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10, fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'이미 지원한 일감'}/>
                  }

                  {
                    ownerwork == true && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#EDEDED'} color={'#999'} text={'본인이 등록한 일감'}/>
                  }
                  {
                    (supporterwork ==false && ownerwork == false) && <Button containerStyle={{border: 'none', fontSize:16, marginTop:10,  fontFamily:"Pretendard-SemiBold"}} onPress={()=>{_handleReqComplete(ROOM_ID)}} height={'44px'} width={'100%'} radius={'4px'} bgcolor={'#FF7125'} color={'#fff'} text={'지원하기'}/>

                  }
                </>
              )
            }
        </div>
        </>)
      }


    </Container>
  );

}

export default MobileRoomWorkReport;

