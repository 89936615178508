import React, { Component, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexEndRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";

import Button from "../../../common/Button";
import { DataContext } from "../../../context/Data";

import { readuserbydeviceid, Update_attendancebyusersid } from "../../../service/UserService";
import { getDateEx, getDateFullTime } from "../../../utility/date";
import { useDispatch } from "react-redux";
import { ALLREFRESH } from "../../../store/menu/MenuSlice";
import { shuffleArray, sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";

import ButtonEx from "../../../common/ButtonEx";
import {motion} from 'framer-motion';
import RotateCardBasic from "../../../common/RotateCardBasic";
import RotateCard from "../../../common/RotateCard";
import MobileGameResult from "../../../modal/MobileGameResult";
import Marquee from 'react-marquee-line';
import './MobileGame.css';

const Container = styled.div`
  padding-top:10px;

`
const style = {
  display: "flex"
};

const SubText = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 20px;
  color :#131313;
  margin-top:10px;
`

const GameItems = [
  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  // {image:imageDB.game11,open:false,index:11},
  // {image:imageDB.game12,open:false,index:12},

  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  // {image:imageDB.game11,open:false,index:11},
  // {image:imageDB.game12,open:false,index:12},



]

const ButtonSt = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 80px;
    color :#fff;
    font-family:Pretendard-SemiBold;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;

    

`
const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  font-size: 16px;
  font-family: 'Pretendard-Bold';

`
const GameCount = '30';

const PointNotice = () => (
  <div className="marquee">
    <div style={{display:"flex", flexDirection:"row"}}>
    <p>이 글자는 연속적으로 흐르는 효과를 가지고 있습니다!</p>
    </div>

  </div>
);


const AudioPlayer = ({ condition }) => {
  const [audio] = useState(new Audio("/sounds/game.mp3"));
  const [volume, setVolume] = useState(0.5); // 초기 볼륨: 50%
  const audioRef = useRef(null);

  const handleBeforeUnload = () => {
    audio.pause();
    audio.currentTime = 0;
    // 추가 작업
  };


  useEffect(() => {
    audio.volume = 0.3;

    if (condition) {

      audio.play().catch((err) => {
        console.error("Audio playback failed", err);
      });
    } else {
      audio.pause();
      audio.currentTime = 0; // 재생 위치 초기화
    }

    return () => {
      // 컴포넌트가 언마운트될 때 오디오 종료
      audio.pause();
      audio.currentTime = 0;
    };
  }, [condition, audio]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      audio.pause();
      audio.currentTime = 0;
    };

    // 페이지 이동 이벤트 감지
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [audio]);

  return null;
};

const MobileGame =({containerStyle}) =>  {

  const [seconds, setSeconds] = useState(0); // 10초 카운트다운

  

  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [items, setItems] = useState(shuffleArray(GameItems));

  const [initialize, setInitialize] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [enableclickcount, setEnableclickcount] = useState(GameCount);
  const [alert, setAlert] = useState(false);
  const [gameresultPopup, setGameresultPopup] = useState(false);
  const [resulttype, setResulttype] = useState('');
  const [playAudio, setPlayAudio] = useState(false);
  const [point, setPoint] = useState(0);
  const [grid, setGrid] = useState([]);





  useLayoutEffect(() => {
    // const bellSound = new Audio("/sounds/game.mp3");
    // bellSound.play();

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    // 정렬 과정 표시

    const shuffledArray = shuffleArray(items);


    let step = 0;
    const interval = setInterval(() => {
      const newArray = shuffleArray([...shuffledArray]);

      setGrid(newArray);
      setRefresh((refresh) => refresh +1);
      console.log("MATRIX LOG : interval : newArray", newArray);
      
      step++;
      if (step > 20) clearInterval(interval); // 정렬 반복 횟수 제한
    }, 1000); // 1000ms마다 배열 업데이트


    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  },[])


  const playSuccessBellWithVibration = () => {
    const bellSound = new Audio("/sounds/good.mp3");
    bellSound.play();
  
    if (navigator.vibrate) {
      navigator.vibrate(200); // 200ms 진동
    }
  };

  useEffect(() => {
    let timer = null;
    if (isRunning) {
      data.gameitems = [];
      datadispatch(data);

      // 타이머 실행
      timer = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    } else {
      // 타이머 정지
      clearInterval(timer);
    }
    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearInterval(timer);
  }, [isRunning]);

  useEffect(()=>{
    setItems(items);

    setInitialize(initialize);
    setSeconds(seconds);
    setEnableclickcount(enableclickcount);
    setGameresultPopup(gameresultPopup);
    setAlert(alert);
    setResulttype(resulttype);
    setPlayAudio(playAudio);
    setPoint(point);


  },[refresh])

  const onPress = (success, gamesucess) =>{

    if(enableclickcount == 0){
    
      setResulttype("fail");
      setGameresultPopup(true);
      setIsRunning(false);
      setInitialize(true);
      setPlayAudio(false);

      setSeconds(0);
      setEnableclickcount(GameCount);
      setRefresh((refresh) => refresh +1);  
      data.gameitems = [];
      datadispatch(data);

      return;
    }

    if(gamesucess == true){

      playSuccessBellWithVibration();
      setResulttype("success");
      setGameresultPopup(true);

      setIsRunning(false);
      setInitialize(true);
      setPlayAudio(false);
  
      setRefresh((refresh) => refresh +1);  
      data.gameitems = [];
      datadispatch(data); 
    }


    let count = enableclickcount -1;
    setEnableclickcount(count);

    if(success){

      setPoint(point +10);
      setAlert(true);
    }else{
      setAlert(false)
    }
    setRefresh((refresh) => refresh+1);
  }

  const _handleStart =() =>{
    setInitialize(false);
    setIsRunning(true);
    setPlayAudio(true);
    setPoint(0);
    setRefresh((refresh) => refresh +1);
  }
  const _handleStop =() =>{

    setResulttype("stop");
    setGameresultPopup(true);
    setIsRunning(false);
    setInitialize(true);
    setPlayAudio(false);
    setPoint(0);
    setSeconds(0);
    setEnableclickcount(GameCount);
    data.gameitems = [];
    datadispatch(data);
    setRefresh((refresh) => refresh +1);  

  }
  const MobileResultCallback = () =>{
    setGameresultPopup(false);
    setPlayAudio(false);
    setSeconds(0);
    setPoint(0);
    setEnableclickcount(GameCount);
    setRefresh((refresh) => refresh +1);
  }



  // 분과 초로 변환
  const minutes = Math.floor(seconds / 60); // 총 초를 60으로 나눈 몫이 분
  const remainingSeconds = seconds % 60; // 총 초를 60으로 나눈 나머지가 초


  return (
    <Container style={containerStyle}>

        {
         initialize === false && <FlexEndRow style={{width:"85%", margin:"0 auto", height:30}}>
          <Row>
          포인트 
          <div style={{margin:"0px 5px", color:"#ff7e19", fontFamily:"Pretendard-SemiBold"}}>
          <motion.div
           key={point} // 키가 변경될 때마다 애니메이션 재생
          initial={{ y: 30, opacity: 0 }}  // 시작 상태 (아래에서 시작)
          animate={{ y: 0, opacity: 1 }}   // 목표 상태 (위로 올라오면서 나타남)
          transition={{ type: 'spring', stiffness: 100, duration: 1 }} 
          
          >
          <div >{point}</div>
          </motion.div>
          </div>point</Row> 
   
  
          </FlexEndRow>
        }

        {
         initialize === true &&
         <div style={{width:'90%', margin:"5px auto 15px",  overflow: "hidden"}}>
         <div className="marquee" style={{width:"750px",marginLeft:30}}>
         <span>게임속에서 그림을 맞출때 마다 10point를 드립니다. 게임이 완료 되면 100point를 드립니다. 게임하고 포인트를 모아보세요</span>
         </div>
       </div>
        }

        

        <Row margin={'10px auto;'} width={'95%'} style={{background:"#fff", flexWrap:"wrap"}} >
        {
            gameresultPopup == true && <MobileGameResult callback={MobileResultCallback} resulttype={resulttype} trynumber={enableclickcount} 
            minutes = {minutes} remainingSeconds ={remainingSeconds} GameCount={GameCount} length={items.length}/>
        }
  
        {
          initialize == true ? (  <>
            {
              <RotateCardBasic />
            }
            </>) :(  <>
            {
              items.map((data, index)=>(
                <RotateCard image={data.image}  item = {data} index={index} callback={onPress} length={items.length}/>
              ))
          
            }

            <div style={{position:"absolute"}}>
              {
                alert == true && <motion.div animate={{ scale: [1, 3, 0] }} transition={{ duration: 1 }}>
                <div style={{fontFamily:"Pretendard-Bold", color:"#ff7e19", fontSize:22}}>

                  <div>{'일치'}</div>
                </div>
              </motion.div>
              }

            </div>


            </>)
        }
        
        
        {
          initialize == true && <ButtonSt onClick={_handleStart}>
            <img src={imageDB.gamestart} style={{width:70, borderRadisu:"70px"}}/>
          </ButtonSt>
        }

        {
          initialize == false &&  <BetweenRow style={{width:"90%", margin:"0 auto"}}>
          <div style={{width:"30%"}}> 
            {
              initialize == false && <ButtonEx text={'게임중지'} width={'100'}  
              onPress={_handleStop} bgcolor={'#FF7E19'} color={'#FFF'} 
              containerStyle={{fontFamily:"Pretendard-SemiBold", boxShadow:"none", height:34, fontSize:16,marginTop:10}} />
            }
            
          </div>
          <BetweenRow style={{width:"70%", fontSize:16, padding:"10px 0px 0px 50px", color : '#F75100', fontFamily:"Pretendard-SemiBold"}}> 
            {
              enableclickcount > 0 && <motion.div animate={{ scale: [1, 3, 0] }} transition={{ duration: 1, repeat: Infinity, repeatType: 'loop' }}>
              <div >{enableclickcount}</div>
            </motion.div>

            }  
              
            <Time>경과시간</Time>
            <div>{minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</div>
          </BetweenRow>
          </BetweenRow>
        }


        <AudioPlayer  condition={playAudio} />    
     
     
        </Row>
    </Container>
  );

}

export default MobileGame;

