import React, {memo, useContext, useEffect, useLayoutEffect, useState,Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";
import { Column, FlexstartColumn } from "../common/Column";
import { imageDB } from "../utility/imageData";
import ButtonEx from "../common/ButtonEx";
import "../screen/css/common.css"
import CurrentWeather from "./CurrentWeather";
import CurrentWeatherPC from "./CurrentWeatherPC";
import { LIFEMENU } from "../utility/life";
import PCSlickSliderComponentEx from "../common/PCSwipeex";


const Container = styled.div`

  width:100%;

`

const { kakao } = window;

const BannerItems =[
  {type:LIFEMENU.CLIMATE,image : imageDB.littlecloud, main1:"날씨정보", main2:"현재 기온, 습도, 하늘상태등 실시간 정보를 제공 ", main3:
"기상청에서 발표된 실시간 단기 예측정보를 조회", color:"#ffe2d2"},
]


const PCLifeClimate = memo(({containerStyle, data}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);

  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [number, setNumber] = useState(1);
  const [address_name, setAddress_name] = useState(user.address_name);


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{


  }, [refresh])


  

 
  return (

    <Container style={containerStyle}>

        <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'} />

        <Column style={{alignItems:"flex-start",width:"100%", paddingLeft:"15px"}}>
        <Suspense fallback={<div>Loading...</div>}>
        <CurrentWeatherPC  addr={address_name} summaryexist ={true} type={'climate'} />
        </Suspense>

        </Column>
    </Container>
  );

})

export default PCLifeClimate;

