import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import MobileGatecontainer from "../../container/main/MobileGatecontainer";
import MobilePolicycontainer from "../../container/main/MobilePolicycontainer";

import MobileSplashcontainer from "../../container/main/MobileSplashcontainer";
import PCSumPolicycontainer from "../../container/PCmain/PCSumPolicycontainer";
import { UserContext } from "../../context/User";
import MobilePrevheader from "../../screen/Layout/Header/MobilePrevheader";
import { Helmet } from "react-helmet";
import PCGateLayout from "../../screen/LayoutPC/Layout/PCGateLayout";




const Container = styled.div`
  background:#f9f9f9;
`
const style = {
  display: "flex"
};

const PCSumPolicypage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);



 
  return (
    <>
    <Helmet>
    <title>이용약관</title>
    <meta name="description" content="구해줘 홍여사에 이용약관을 확인 해보세요" />
    <link rel="canonical" href="https://honglady.com/" />
    </Helmet>
    <PCGateLayout main={true} name={""} height={80}>
      <PCSumPolicycontainer/>
    </PCGateLayout>
    </>
  
  );

}

export default PCSumPolicypage;

