
import moment from 'moment';
import axios from "axios";
import { parseDocument } from 'htmlparser2';
import { selectOne } from 'css-select';

const isWeb = typeof window !== 'undefined'; // 웹 환경 확인





export  const isReactNativeWebView = ()=>{
	return typeof window.ReactNativeWebView !== 'undefined';
}


export  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true; // 파싱 성공
    } catch (error) {
      return false; // 파싱 실패
    }
 }

export const serverUrl = () =>{
	return "http://13.125.229.243:3000/"

}

/**
 * 전체문자열 data에서 특정 문자 위치 findstr를 발견 했을때 위치를 구한다
 */
export const searchpos = (data, findstr) =>{
	var position = data.indexOf(findstr);
	return position;
}

/**
 * 전체문자열 data의  시작위치를 pos 에서 특정 문자 위치 findstr를 발견 했을때 위치를 구한다
 */
export const searchposfrom = (data, pos, findstr) =>{

	var position = data.indexOf(findstr,pos);
	return position;
}

/**
 * 전체문자열 data에서 시작 위치 firstpos 와 끝위치 lastpost 사이에 문자열을 구한다
 */
export const searchposfromto = (data, firstpos, lastpos) =>{

	var findstsr = data.slice(firstpos, lastpos);
	return findstsr;
}

 /**
  * !html Tag를 없애 주고 실제 데이타만 뽑아줌
  */
 export const  extractTextFromHTML = (html) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	return doc.body.textContent || '';
  }

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const useSleep = delay => new Promise(resolve => setTimeout(resolve, delay));

export const validateEmail = email =>{
    const regex = /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(email);
}	

export const removeWhitespace = text =>{
    const regex = /\s/g;
    return text.replace(regex, '');
}

export const removeemail = text =>{
    const regex = /\s/g;
    return text.replace('@gmail.com', '');
}

export const ArrayIncludeData = (arraydata, data)=>{

	if(data ==''){
		return false;
	}
    if(arraydata == undefined){
        return false;
    }
    const FindIndex = arraydata.findIndex(x=>x == data);

    return FindIndex == -1 ? false : true;
}

export const fn_smsShare =(phone) =>
{
		var sBody = "[RELATION.CO.KR~~!! ]n"
		+ "안녕하세요. n"
		+ "마원 앱보고 문자드립니다.~n"
		+ "SMS 테스트 페이지 입니다.n"
		+ "n"
		+ "감사합니다.";
	
		sBody = sBody.replace(/(n|rn)/g,"%0a");
		return  "sms:"+phone+"?body=" + sBody;
}
export const fn_telShare =(phone) =>
{

	return  "tel:"+phone;
}


export const shuffleArray = (array) => {
	let shuffledArray = [...array]; // 기존 배열을 복사하여 새로운 배열을 만듦
	for (let i = shuffledArray.length - 1; i > 0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray;
}

export const shuffleArray10 = (array) => {
	let shuffledArray = [...array]; // 기존 배열을 복사하여 새로운 배열을 만듦
	for (let i = shuffledArray.length - 1; i > 0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray.splice(0, 10);;
}

export const shuffleArray18 = (array) => {
	let shuffledArray = [...array]; // 기존 배열을 복사하여 새로운 배열을 만듦
	for (let i = shuffledArray.length - 1; i > 0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray.splice(0, 18);;
}

export const shuffleArray20 = (array) => {
	let shuffledArray = [...array]; // 기존 배열을 복사하여 새로운 배열을 만듦
	for (let i = shuffledArray.length - 1; i > 0; i--) {
	  const j = Math.floor(Math.random() * (i + 1));
	  [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
	}
	return shuffledArray.splice(0, 20);;
}


export const ensureHttps = (url) => {
    if (url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }
    return url; // 이미 https이거나 다른 프로토콜인 경우 원본 URL 반환
  }

export const removeSymbols = (text) => {
	return text.replace(/[^A-Za-z0-9\uAC00-\uD7A3\s:/]/g, '');
}

export const getMainImageURL = async (url) => {
	try {
	  // 웹 페이지 HTML 가져오기
	  const response = await axios.get(url);

	  console.log("getMainImageURL", response);
	  
	  const html = response.data;
  
	  // HTML 파싱
	  const document = parseDocument(html);
  
	  // 메인 이미지 추출 (예: og:image 메타 태그)
	  const metaTag = selectOne('meta[property="og:image"]', document);
	  if (metaTag && metaTag.attribs.content) {
		return metaTag.attribs.content; // 메인 이미지 URL
	  }
  
	  // 다른 방법으로도 이미지 추출 가능 (예: 첫 번째 <img> 태그)
	  const firstImage = selectOne('img', document);
	  if (firstImage && firstImage.attribs.src) {
		return firstImage.attribs.src;
	  }
  
	  return '메인 이미지를 찾을 수 없습니다.';
	} catch (error) {
	  console.error('Error fetching image:', error);
	  return '에러 발생';
	}
  };
