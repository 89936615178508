import React, { Fragment, useContext, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { GrPrevious } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { CURRENTPAGE } from "../../../utility/router";
import { IoEllipseSharp } from "react-icons/io5";
import { FlexEndRow } from "../../../common/Row";
import IconButton from "../../../common/IconButton";
import { BsHouseAdd } from "react-icons/bs";

const Container = styled.div``;





const MobileSearchheader = ({callback, registbtn, name, iconname, storebutton}) => {
  const navigation = useNavigate();
  const { user, dispatch } = useContext(UserContext);

  const location = useLocation();

  const _handleprev = () =>{

    if(location.pathname == CURRENTPAGE.MOBILESEARCH){
      navigation('/mobilemain');
    }else{
      navigation(-1);
    }
   
  }

  const _handlehistory = () =>{
    navigation("/Mobilesearchhistory");
  }
  const _handleCategory = () =>{
    navigation("/Mobileaicategorycreate");
  }


  return (
    <Container
      id="header"
      style={{
        zIndex: 999,
        position: "fixed",
        background: "#fff",
        width: "100%",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "1px solid #ededed",
        fontFamily:"Pretendard-SemiBold",
  
      }}
    >

      <div style={{paddingLeft:15, width:'10%', display:"flex"}}>
        <GrPrevious onClick={_handleprev} size={20} />
      </div>



      {
        storebutton == true ? (
          <>
          <div style={{display:"flex", width:"40%", fontFamily:"Pretendard-SemiBold", fontSize:18}}>
          {name.slice(0, 40)}
          {name.length > 40 ? "..." : null} 
          </div>
          <FlexEndRow>
        <IconButton

          text={'지식창고 가기'} width={'100'} containerStyle={{
          fontSize: '14px',
          fontFamily:"Pretendard-SemiBold",
          color :"#fff",
          background: '#ff7e19',
          padding: '5px',
          borderRadius:'5px',
          boxShadow:"none"
        }}
        onPress={_handleCategory} bgcolor={'#00000057'} color={'#fff'} />
          </FlexEndRow>
          </>
        ):(
          <div style={{display:"flex",fontFamily:"Pretendard-SemiBold", fontSize:18}}>
          {name.slice(0, 40)}
          {name.length > 40 ? "..." : null} 
          </div>

        )  
    
      }
    
    
    </Container>
  );
};

export default MobileSearchheader;
