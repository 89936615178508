import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column } from "../../common/Column";
import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../../utility/life";

import PCLifeBoard from "../../components/PCLifeBoard";
import { Helmet } from "react-helmet";

const Container = styled.div`

  display:flex;
  flex-direction:column;
  width : 70%;
  margin : 30px auto;

`


const style = {
  display: "flex"
};


const LocationText  = styled.div`
  color : #131313;
  font-size:13px;
`
const SearchText = styled.div`
color : #131313;
font-family:'Pretendard-Light';
font-size:10px;
`



/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const PCLifecontainer =({containerStyle, TYPE}) =>  {
console.log("MATRIX LOG : PCLifecontainer : TYPE:", TYPE)

  const {value} = useSelector((state)=> state.menu);
  const [currentloading, setCurrentloading] = useState(true);

  const [menu, setMenu] = useState(value);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {

    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
  
  },[refresh])

  useEffect(()=>{
    setCurrentloading(currentloading);
    setMenu(value);
  },[value])

  /**
   * 팝업 노출여부를 확인 한다(hongpopup1, hongpopup2, hongpopup3 를 localstorage에서 가져온다
   * ! 홍여사 요청 업무를 초기 로딩시에 구해온 데이타로 세팅 한다
   * ! 현재 페이지에서 리플레시 햇을때 서버에서 데이타를 구해 올수 있어야 한다 서비스 사용 : ReadWork()
   * 
   */
  useEffect(()=>{
    const now = moment();
 
    async function FetchData(){
      setMenu(LIFEMENU.BOARD);

    } 
    FetchData();

  }, [])




  /**
   * useSelector menu 가 변경됨을 감지 함에 따라 호출되는  Hook 함수
   * 데이타를 서버로 부터 불러와서 FilterwokrItems 함수로 걸러진값을 workitems 에 설정 해준다
   */

  useEffect(()=>{
    async function FetchData(){

     setCurrentloading(false);
    }
    FetchData();

  },[])


  


  return (
    <>

      <Helmet>
        <title>일상생활</title>
        <meta name="description" content="구해줘 홍여사 AI,  요리레시피, 가사분담서비스 집안생활의 모든것" />
        <link rel="canonical" href="https://honglady.com/PCLife" />
      </Helmet>

      <Container style={containerStyle}>


        <PCLifeBoard  TYPE={TYPE}/>


      </Container>
    


    </>


  );

}

export default PCLifecontainer;

