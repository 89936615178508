import React, { Fragment, useContext, useEffect, useRef, useState,Suspense, useMemo, memo } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosCloseCircleOutline } from "react-icons/io";

import "../screen/css/common.css";
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { Column, FlexstartColumn } from "../common/Column";
import { IoCloseSharp } from "react-icons/io5";
import Button from "../common/Button";
import { FILTERITEMMONEY, FILTERITEMPERIOD, FILTERITMETYPE, LoadingType } from "../utility/screen";

import { WORKNAME } from "../utility/work";
import { AICATEGORY, ROOMSIZE, ROOMSIZEDISPALY } from "../utility/room";

import { MdLockReset } from "react-icons/md";
import { imageDB } from "../utility/imageData";

import { se } from "date-fns/locale";

import { model } from "../api/config";
import Loading from "../components/Loading";
import { useSleep } from "../utility/common";
import { CreateSearch, DeleteSearchByid, ReadSearch, ReadSearchByid } from "../service/SearchService";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { getFullTime } from "../utility/date";

import TimeAgo from 'react-timeago';

import { FaListCheck } from "react-icons/fa6";


import { GrUploadOption } from "react-icons/gr";
import LottieAnimation from "../common/LottieAnimation";
import TypingText from "../common/TypingText";
import { LoadingAnimationStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { CiSearch } from "react-icons/ci";
import ButtonEx from "../common/ButtonEx";
import { UserContext } from "../context/User";
import { CreateCategory, DeleteCATEGORYByid, ReadCATEGORY, ReadCATEGORYCONTENT } from "../service/CategoryService";
import { useNavigate } from "react-router-dom";
import { setRef } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GoPencil } from "react-icons/go";

import { OrbitControls, TransformControls } from "@react-three/drei";



import { useGLTF } from "@react-three/drei";
import { Environment } from '@react-three/drei';

import { Canvas, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import * as THREE from "three";
import { MdDeleteForever } from "react-icons/md";


const formatter = buildFormatter(koreanStrings); 


const LabelView = styled.div`
  width: 100%;
  padding: 25px 15px;


`
const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85%;
  height: 50px;
  font-family: 'Pretendard-Regular';
  font-size: 16px;
  margin: 0 auto;
`
const InputContent = {
  width:'95%',
  margin:'0px auto 0px',
  borderRadius: '5px',
  fontFamily: 'Pretendard-Regular',
  flex: '0 0 auto',
  height: '30px',
  border: 'none',
  borderRadius: '10px',
  paddingLeft : "10px"
}

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  width: 48%;
  border-radius: 15px;
  padding : 5px 0px;


  margin-bottom:10px;


`
const BoxContent = styled.div`
  font-size: 18px;
  color: ${({clickstatus}) => clickstatus == true ? ('#fff') :('#131313') };
  font-weight: 500;
  background: ${({clickstatus}) => clickstatus == true ? ('#ff7e19') :('#ededed') };
  padding: 15px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-family: 'Pretendard';
  height: 70px;
  margin-bottom: 5px;
  flex-direction: column;
  border-radius:10px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`
const CountItem= styled.div`
color: #7b7575;
padding-top: 10px;
font-size: 12px;
`

const ControlButton = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
`

const MobileAiCategoryCreate = memo(({}) =>{
  const { dispatch, user } = useContext(UserContext);
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState(-1);
  const [categoryname, setCategoryname] = useState('');
  const [categoryitems, setCategoryitems] = useState([]);
  const [selectcategory, setSelectcategory] = useState('');
  const [loading, setLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {

        if(loading == false){
          setLoading(true);
          setRefresh((refresh) => refresh +1);
          Category_Create();
        }

    }
  };

  const Shelf = memo(() => {
 
    const { nodes, scene } = useGLTF("/models/shelf.glb");
  
    return (
      <group>
        {/* 선반 외형 */}
        <primitive object={scene} position={[0, 1, 0]} />

      </group>
    );
  });

  const CategoryScene = memo(() => {

    const [isOpen, setIsOpen] = useState(false);

    return (

      <Canvas 
      dpr={[1, 2]} // 디스플레이 해상도 최적화
      frameloop="demand" // 필요할 때만 렌더링
      gl={{ antialias: true }} // 부드러운 렌더링
      orthographic // 정사각형 카메라 사용
      camera={{
        zoom: 100, // 줌 레벨 고정
        position: [-1, 2, 8], // 카메라 위치 (냉장고 앞)
        near: 1,
        far: 300,
      }}
     
      style={{background: "#fff",height:'80vh'}}>
      {/* <Suspense fallback={null}> */}
      {/* 환경 조명: 부드러운 배경광 */}
      <ambientLight intensity={0.1} />
  

      <Environment files="/models/venice_sunset_1k.hdr" />

      {/* 외부에서 비추는 방향광 */}
      <directionalLight position={[-2, 2, 2]} // 냉장고를 대각선 위에서 비추기
        target-position={[0, 0, 0]} // 냉장고 중심을 타겟
        intensity={5} // 밝기
        color="#ffffff" // 빛 색깔
      />
    
      <Shelf />

  
      <OrbitControls  />
      {/* </Suspense> */}
      </Canvas>


    );
  });

  const Category_Create = async() =>{

    const USERS_ID = user.users_id;
    const CATEGORY = categoryname;

    const category = await CreateCategory({USERS_ID,CATEGORY});

    const ReadItems = await ReadCATEGORY({USERS_ID});

    const SearchItems = await ReadCATEGORYCONTENT({USERS_ID});

    let items = [];


    if(ReadItems != -1){
 
      ReadItems.map((data)=>{
        data["COUNT"] = 0;
        if(SearchItems != -1){
          SearchItems.map((subdata)=>{
            if(subdata.CATEGORY == data.CATEGORY){
              data["COUNT"] += 1;
            }
          })
        }
  
        items.push(data);
      })
    }


    setCategoryitems(items);

    setCategoryname("");

    setLoading(false);
    console.log("TCL: CategoryCreate -> items", items)
    setRefresh((refresh) => refresh +1);

  }
  useEffect(() => {
    setCategoryname(categoryname);
    setCategoryitems(categoryitems);
    setSelectcategory(selectcategory);
    setLoading(loading);

  }, [refresh]);

  async function FetchData(){

    setLoading(true);
    setRefresh((refresh) => refresh +1);
    const USERS_ID = user.users_id;
    const ReadItems = await ReadCATEGORY({USERS_ID});

    const SearchItems = await ReadCATEGORYCONTENT({USERS_ID});

    let items = [];


    if(ReadItems != -1){
      ReadItems.map((data)=>{
        data["COUNT"] = 0;

        if(SearchItems != -1){
          SearchItems.map((subdata)=>{
            if(subdata.CATEGORY == data.CATEGORY){
              data["COUNT"] += 1;
            }
          })
        }
        items.push(data);
      })
    }


    setCategoryitems(items);
    setLoading(false);
    console.log("TCL: CategoryCreate -> items", items)
    setRefresh((refresh) => refresh +1);
  
  }
  /**
   * 데이타를 가져온다
   * 1) 무조건 데이타를 가져 와서 저장 해둔다
   * 2) 검색어가 있다면 zemini에 요청한다
   * 3) 검색 결과를 searchresult 에 저장 해두고 데이타 베이스에 입력한다
   * 4) 검색어가 없다면 처음에 가져온 데이타에서 첫번째 인덱스 값을 보여준다 
   */
  useEffect(() =>{
    FetchData();

  }, [])

  const _handlecategorydelete = async(id)=>{
    setLoading(true);
    const CATEGORY_ID = id;
    const Delete = await DeleteCATEGORYByid({CATEGORY_ID});
    FetchData();
  }

  const _handlecategoryclick = (data)=>{
    console.log("TCL: _handlecategoryclick -> data", data)
    navigate("/Mobileaicategorylist" ,{state :{category :data}});
  }

  
  return (
    <Column style={{width:'100%', margin: '0 auto'}}>
      <LabelView>
          <Label>
          {'카테고리를 생성하고 홍여사 AI로 검색 한 결과를 정리해보세요'}
          </Label>
      </LabelView>


      <Row style={{width:"80%"}}>
        <img  src={imageDB.roomplus} style={{width:30, height:30, position:"relative"}} />

        <input type={'text'} style={InputContent} value={categoryname} 
          placeholder={'생성할 카테고리를 8자이내로 입력해주세요'}
          class="Createinput"
          maxLength = {8}
          onKeyDown={handleKeyDown} 
          onChange={(e) => {
              setCategoryname(e.target.value);
              setRefresh((refresh) => refresh +1);
            }}/>
      </Row>

  

      {/* {loading == true && (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loading}
            width={"50px"} height={'50px'}/>)
      } */}

      <Column style={{width:"100%", margin: "10px auto 30px"}}> 
          <BetweenRow style={{flexWrap:"wrap", width:"90%", margin:"0 auto"}}>
            {
              categoryitems.map((data, index)=>(
                <Box clickstatus={AICATEGORY.SCHEDULE == data.CATEGORY} >  

                  <BoxContent clickstatus={AICATEGORY.SCHEDULE == data.CATEGORY}  >

                    <Column onClick={()=>{_handlecategoryclick(data.CATEGORY)}} style={{height:"100%", width:"100%"}}>
                      <div style={{fontFamily:"Pretendard"}}>{data.CATEGORY}</div>
                      <CountItem>{data.COUNT}개 등록</CountItem>
                    </Column>
           


                    <FlexEndRow style={{width:"100%", position:"relative", top:5}} onClick={()=>{_handlecategorydelete(data.CATEGORY_ID)}}>
                    <ControlButton> 
                      <RiDeleteBin5Line/>
                    </ControlButton>
     
                  </FlexEndRow>
                  </BoxContent>
         
              
                </Box>
              ))
            }
          
          </BetweenRow>


          {/* <CategoryScene/> */}

      </Column> 
    </Column>
  );
});

export default MobileAiCategoryCreate;