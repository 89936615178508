import React,{useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, Link, useNavigate} from "react-router-dom";
import styled from 'styled-components';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { imageDB } from '../utility/imageData';
import '../screen/css/common.css' 
import { PiX } from 'react-icons/pi';
import LazyImage from './LasyImage';
import ButtonEx from './ButtonEx';
import { LIFEMENU } from '../utility/life';
import { CONFIGMOVE } from '../utility/screen';


const Container = styled.div`
  width: 100%;
  overflow-x: hidden; /* X축 스크롤을 숨깁니다. */
  // min-height:210px;

`
// background :#ec8260;

const Box = styled.div`
position: relative;
width: 100%;
height:210px;
background :${({bgcolor}) => bgcolor};
display: flex;
justify-content: center;
align-items: center;

&::before {

  content : attr(data-label);
  position: absolute;
  top: 20px;
  left: 35px;
  background-color: #141413a1;
  color: white;
  padding: 2px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}
` 
const MainItem = styled.div`

 
  font-family :'Pretendard-SemiBold';
  color :${({color}) => color};
  font-size:18px;
  position: absolute;
  left: 30px;
  line-height:1.5;
  padding-top:30px;
  

`

const ImageLayer = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;


`




const SlickSliderComponent = ({width ,items, bgcolor}) => {

    const navigate = useNavigate();

    const settings = {
      dots: false, // 슬라이더 하단에 점을 표시
      infinite: true, // 무한 루프 설정
      speed: 500, // 슬라이더 전환 속도
      slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
      slidesToScroll: 1, // 한 번에 스크롤할 슬라이드 수
      autoplay: true, // 자동 슬라이드
      autoplaySpeed: 5000, // 자동 슬라이드 속도 (밀리초)
      swipeToSlide: true, // 슬라이드 간 스와이프
      adaptiveHeight: true, // Adjust slider height
    };
  
    const _handleBtn = () =>{

    }

    const _handleSearch = ()=>{
      navigate("/Mobilecommunitycontent" ,{state :{name :LIFEMENU.AI}});
    }
    const _handleCommunity = ()=>{
      navigate("/Mobilecommunitycontent" ,{state :{name :LIFEMENU.BOARD}});
    }

    const _handleAttendance = ()=>{
 
      navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.ATTENDANCE, TYPE : ""}});

    }

    const _handleRullet = ()=>{
      navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.RULLET, TYPE : ""}});
    }

    const _handleGame = ()=>{

    navigate("/Mobilecommunitycontent" ,{state :{name :LIFEMENU.GAME}});
    
    }
    const _handleContact = ()=>{
      navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.CONTACTKNOW, TYPE : ""}});
    }
    const _handleTransaction = ()=>{
      navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.TRANSACTIONKNOW, TYPE : ""}});
    }
    return (
     <Container width={width} bgcolor={bgcolor}>
        <Slider {...settings}>
          {
            items.map((data, index)=>(
              <>
              <Box  data-label = {index+ 1 + "/" + items.length} bgcolor={data.bgcolor}>
                <MainItem color={data.color}>
                  {data.maintext}
                
                  <div>{data.subtext1}</div>


                  {data.buttontype == 1 && <ButtonEx text={'거래절차 보기'} width={'100'}  onPress={(_handleTransaction)} color={data.color}
                  containerStyle={{fontSize:14,padding:"0px 10px", height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'}} />  }
                  {data.buttontype == 2 && <ButtonEx text={'계약서는 이렇게 작성 되요'} width={'100'}  onPress={(_handleContact)} color={data.color}
                  containerStyle={{fontSize:14,padding:"0px 10px", height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'}} />  }

                  {data.buttontype == 3 && <ButtonEx text={'무엇이든 물어보세요'} width={'100'}  onPress={(_handleSearch)}  color={data.color}
                  containerStyle={{fontSize:14,padding:"0px 10px",height:"30px", marginTop:20, padding:"0px 5px",boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'}} />  }
                  {data.buttontype == 4 && <ButtonEx text={'게시판 가기'} width={'100'}  onPress={(_handleCommunity)}  color={data.color}
                  containerStyle={{fontSize:14,padding:"0px 10px",height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'}} />  }
                  {data.buttontype == 5 && <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                    <ButtonEx text={'출석이벤트'} width={'33'}  onPress={(_handleAttendance)}  color={data.color}
                    containerStyle={{fontSize:14,padding:"0px 10px",  height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'}} /> 
               
                    <ButtonEx text={'룰렛이벤트'} width={'33'}  onPress={(_handleRullet)}  color={data.color}
                    containerStyle={{fontSize:14, padding:"0px 10px", height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', marginLeft:3}} /> 


                    <ButtonEx text={'게임이벤트'} width={'33'}  onPress={(_handleGame)}  color={data.color}
                    containerStyle={{fontSize:14, padding:"0px 10px", height:"30px", marginTop:20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', marginLeft:3}} /> 

                    </div>
                  }

        

                </MainItem>
           
                {/* <SubItem1>
                  
       

                </SubItem1> */}
            
                
                <ImageLayer>
                  <img src={data.image} style={{width:'70px' }}/>
                </ImageLayer>
               
              </Box>
              </>
       
            ))
          }
      
        </Slider>
      </Container>
    );
  };
  
  export default SlickSliderComponent;
  