import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Label from "../../common/Label";
import { GoNoEntry } from "react-icons/go";

import "./MobileMaincontainer.css";
import "./MobileCommunitycontainer.css";
import MobileStoreInfo from "../../components/MobileStoreInfo";
import { ROOMSIZE } from "../../utility/room";
import { ReadRoom } from "../../service/RoomService";
import PCRoomItem from "../../components/PCRoomItem";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { ensureHttps, useSleep } from "../../utility/common";
import { BOARDMENU, CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../../utility/life";
import MobileTourLife from "../../components/MobileLifeTour";
import MobilePerformanceLife from "../../components/MobileLifePerformance";
import MobileMedicalLife from "../../components/MobileLifeMedical";
import MobileLifePerformance from "../../components/MobileLifePerformance";
import MobileLifeMedical from "../../components/MobileLifeMedical";
import MobileLifeTour from "../../components/MobileLifeTour";
import MobileLifeFamily from "../../components/MobileLifeFamily";
import MobileLifeConvenience from "../../components/MobileLifeConvenience";
import MobileLifeTourFestival from "../../components/MobileLifeTourFestival";
import MoblileLifePerformanceEvent from "../../components/MobileLifePerformanceEvent";
import MoblileLifePerformanceCinema from "../../components/MobileLifePerformanceCinema";
import MobileLifeTourRegion from "../../components/MobileLifeTourRegion";
import MobileLifeTourCountry from "../../components/MobileLifeTourCountry";
import MobileLifeTourPicture from "../../components/MobileLifeTourPicture";
import MobileLifeMedicalDrug from "../../components/MobileLifeMedicalDrug";
import MobileLifeMedicalHospital from "../../components/MobileLifeMedicalHospital";
import MobileLifeFoodDrug from "../../components/MobileLifeFoodDrug";
import MobileLifeCampingRegion from "../../components/MobileLifeCampingRegion";
import MobileLifeBoard from "../../components/MobileLifeBoard";
import MobileRecipeBoard from "../../components/MobileRecipeBoard";
import LazyImage from "../../common/LasyImage";

import { GrTip } from "react-icons/gr";
import { TbComponents } from "react-icons/tb";
import Recipe from "../../components/Recipe";

const Container = styled.div`
  padding:50px 0px;
  height: 100%;
`

const Empty = styled.div`
  height: 2px;
  background: #ededed;
  margin: 20px 0px;

`
const SubContent = styled.div`
  width: 90%;
  margin: 0 auto;

`
const Content = styled.div``

const ContentText = styled.div`
  font-size: 16px;
  font-family : Pretendard-SemiBold;
  margin : 20px 0px;
`
const Tag1 = styled.div`
    font-size: 16px;
    width: 30%;
    color: #fffbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 25px;
    margin: 20px 0px;
    background: #ff7e19;

`

const Tag2 = styled.div`
  font-size: 16px;
  width: 30%;
  color: #fffbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 25px;
  margin: 20px 10px;
  background: #ff7e19;

`
const LableIconLayer = styled.div`
  border-radius: 5px;
  height: 30px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
const LabelText = styled.div`
  margin-left:5px;
  font-family : Pretendard-SemiBold;
  font-size :16px;
`
const Line = styled.div`
  border: 1px dotted;
  background: #f1f1f1;
  width: 50%;

`
const Property = styled.div`
width: 10%;
display: flex;
justify-content: flex-end;
font-family:Pretendard-Light;

`

/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const MobileRecipeContentcontainer =({containerStyle, item}) =>  {
console.log("TCL: MobileRecipeContentcontainer -> item", item)



  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [currentloading, setCurrentloading] = useState(true);
  const [ingredient, setIngredient] = useState([]);


  useLayoutEffect(() => {
   let ingredientTmp =  item.RCP_PARTS_DTLS.split(',');
   setIngredient(ingredientTmp);
   console.log("TCL: MobileRecipeContentcontainer -> ingredientTmp", ingredientTmp)
   setRefresh((refresh) => refresh +1);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setIngredient(ingredient);


  },[refresh])

  function IngredientName(ingredient){

    let startposition = ingredient.indexOf('(');
    let lastposition = ingredient.indexOf(')');

    if(startposition > 0){
      return ingredient.substring(0,startposition);

    }else{
      return ingredient;
    }
  
  

  }
  function IngredientQty(ingredient){

    let startposition = ingredient.indexOf('(');
    let lastposition = ingredient.indexOf(')');
  
    if(startposition > 0 && lastposition > 0){
      return ingredient.substring(startposition+ 1,lastposition);

    }else{
      return "";
    }


  }

  /**

   */
  useEffect(()=>{
    const now = moment();
 
    async function FetchData(){

      await useSleep(1000);
      setCurrentloading(false);
    } 
    FetchData();

  }, [])



  return (
    <>

    {

      <Container style={containerStyle}>
        <LazyImage src={ensureHttps(item.ATT_FILE_NO_MAIN)} containerStyle={{width:'100%', backgroundColor: '#ededed' }}/>

        <Content>

        <SubContent>

          <FlexstartRow>
            <Tag1>{item.RCP_PAT2}</Tag1>
            <Tag2>{item.RCP_WAY2}</Tag2>
          </FlexstartRow>

          <FlexstartRow>
            <LableIconLayer>
              <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>요리팁</LabelText>
          </FlexstartRow>
    

          <ContentText>{item.RCP_NA_TIP}</ContentText>
        </SubContent>

        <Empty/>

        <SubContent>
          <FlexstartRow>
            <LableIconLayer>
              <TbComponents color={'#131313'}/>
            </LableIconLayer>
            <LabelText>필요한 재료 {ingredient.length}개</LabelText>
          </FlexstartRow>

          <FlexstartColumn style={{marginTop:10}}>
          {
            ingredient.map((data)=>(
              <BetweenRow style={{width:"100%", margin:"5px auto"}}>
                <div style={{width:"100%"}}>{data}</div>
                {/* <Line></Line>
                <Property>{IngredientQty(data)}</Property> */}
              </BetweenRow>
            ))
          }
          </FlexstartColumn>

        </SubContent>
        <Empty/>


        <SubContent>
          <FlexstartRow>
            <LableIconLayer>
              <TbComponents color={'#131313'}/>
            </LableIconLayer>
            <LabelText>영양소</LabelText>
          </FlexstartRow>


          <BetweenRow style={{width:"100%", margin:"10px auto"}}>
                <div style={{width:"20%"}}>{'열량'}</div>
                <Line></Line>
                <Property>{item.INFO_ENG}cal</Property>
          </BetweenRow>

          <BetweenRow style={{width:"100%", margin:"10px auto"}}>
                <div style={{width:"20%"}}>{'탄수화물'}</div>
                <Line></Line>
                <Property>{item.INFO_CAR}g</Property>
          </BetweenRow>


          <BetweenRow style={{width:"100%", margin:"10px auto"}}>
                <div style={{width:"20%"}}>{'단백질'}</div>
                <Line></Line>
                <Property>{item.INFO_PRO}g</Property>
          </BetweenRow>


          <BetweenRow style={{width:"100%", margin:"10px auto"}}>
                <div style={{width:"20%"}}>{'지방'}</div>
                <Line></Line>
                <Property>{item.INFO_FAT}g</Property>
          </BetweenRow>

          <BetweenRow style={{width:"100%", margin:"10px auto"}}>
                <div style={{width:"20%"}}>{'나트륨'}</div>
                <Line></Line>
                <Property>{item.INFO_NA}g</Property>
          </BetweenRow>

    
        </SubContent>

        <Empty/>

        <SubContent>
          <FlexstartRow>
            <LableIconLayer>
              <TbComponents color={'#131313'}/>
            </LableIconLayer>
            <LabelText>조리방법</LabelText>
          </FlexstartRow>
        </SubContent>

 
        <Recipe item = {item}/>

        </Content>



   

  
      </Container>
    }


    </>


  );

}

export default MobileRecipeContentcontainer;

