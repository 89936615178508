
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { getMainImageURL, useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadPerformanceEvent } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { LoadingLifeStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { PERFORMANCEMENU } from "../utility/life";
import PCSlickSliderComponentEx from "../common/PCSwipeex";
import axios from "axios";

const Container = styled.div`

  margin : 20px auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  height: 1500px;
  overflow-y : auto; 
  scrollbar-width: none;
  width:100%;
`
const style = {
  display: "flex"
};

const  SearchLayer = styled.div`
width: 90%;
margin : 0 auto;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background: #fff;
position: sticky;
top: 0px;
padding-top: 10px;
padding-bottom: 10px;
`

const BoxItem = styled.div`
  margin: 15px 15px;
  position: relative;
  height:450px;
  color: #333;
  width: 20%;
  font-family: "Pretendard-Light";
  border: 1px solid #ededed;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`
const Inputstyle={

  background: '#FFF',
  borderRadius:'25px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",

}
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "400px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
  position: "absolute",
  top: "400px",
  left : "25%"
}

const Taglabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:14px;
  margin-right:10px;
  color :#131313;
  min-width:55px;
  display : flex;
  align-items: center;
  justify-content: flex-start;
`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;
  word-break: break-word;
  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`



const BannerItems =[
  {type:PERFORMANCEMENU.PERFORMANCEEVENT,image : imageDB.performance, main1:"공연행사 할인티켓", main2:"공연 문화예술 할인 예매 정보보고 공연을 쉽게 즐겨보세요 ", main3:"",color:"#ebf7fa"},
]

const PCLifePerformanceEvent =({containerStyle}) =>  {


/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);
  const [searching, setSearching] = useState(true);
  const [displayitems, setDisplayitems] = useState([]);

  const [popupstatus, setPopupstatus] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');
  const [performanceeventname, setPerformanceeventname] = useState('');



  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setDisplayitems(displayitems);
    setPopupstatus(popupstatus);
    setLatitude(latitude);
    setLongitude(longitudie);
    setPerformanceeventname(performanceeventname);
  }, [refresh])

  const isPastDate = (dateString) => {
    const targetDate = new Date(dateString);
    const currentDate = new Date();

    
    // `getTime()`을 사용하여 날짜를 밀리초로 변환 후 비교
    return currentDate.getTime() > targetDate.getTime();
  };

  useEffect(()=>{
      async function FetchData(){


        let items = [];

        if(data.performanceeventitem.length == 0)
        {
          const performanceeventitem = await ReadPerformanceEvent();

          let promises;
          performanceeventitem.map(async(data, index)=>{
            const dataToSave = JSON.parse(data.dateitem);
  
            if(dataToSave.response.body != undefined){
               promises =  dataToSave.response.body.items.map(async (subdata)=>{
                if(isPastDate(subdata.eventEndDate) == false){

                 const jsonPayload = {
                    url: subdata.homepageUrl,
                  };

                  
                  await axios.post("https://asia-northeast1-help-bbcb5.cloudfunctions.net/api/proxy-image",  jsonPayload, {
                    headers: {
                      "Content-Type": "application/json"
                    }
                  })
                  .then((response) =>{
                    console.log("subdata.homepageUrl", response);
                    subdata["image"] = response.data.imageUrl;
                  })
         
                  .catch((error) => console.error('Error:', error));

                  items.push(subdata);
       
                }
                
              })
  
            }
          })
          await Promise.all(promises); 
          data.performanceeventitem = items;
          datadispatch(data);
        }


        setDisplayitems(data.performanceeventitem);
        console.log("MATRIX LOG : data.performanceeventitem:", data.performanceeventitem);

        await useSleep(1000);
        setSearching(false);
        setRefresh((refresh) => refresh +1);
    
      
      }

      FetchData();
  }, [])

   /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);
 
  const _handleSubmit = async(event) => {
    event.preventDefault();

    Searchfunc();

  };
  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
    setRefresh((refresh) => refresh +1);
  };

  const _handleMapview= (lat, long, performanceeventname)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setPerformanceeventname(performanceeventname);
    setRefresh((refresh) => refresh +1);

  }

  const handleKeyDown = (event)=>{
    if (event.key === 'Enter') {
      Searchfunc();
    }

  }

  async function Searchfunc(){
    setSearching(true);

    await useSleep(2000);
    const keyworditems = searchkeyword(search, data.performanceeventitem);

    const regionitems = searchRegion(search, data.performanceeventitem);

    let resultitems = [];
    keyworditems.map((data)=>{resultitems.push(data)});
    regionitems.map((data)=>{resultitems.push(data)});

    setDisplayitems(resultitems);

    setSearching(false);
    setRefresh((refresh) => refresh +1);
  }
  function searchkeyword(data, items){

    const foundItems = items.filter(item => item.eventNm.toLowerCase().includes(data.toLowerCase()));

    return foundItems;

  }
  function searchRegion(data, items){

    const foundItems = items.filter(item => item.rdnmadr.toLowerCase().includes(data.toLowerCase()));

    return foundItems;

  }
  return (

    <Container style={containerStyle}>
        
        <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'}/>

        {
          popupstatus == true && <MobileMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
          name={performanceeventname} markerimg={imageDB.performance}
          top={'30%'}  left={'10%'} height={'280px'} width={'280px'}
          />
        }

        {
          searching == true ? (<LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
            width={"100px"} height={'100px'} />)
          :(
            <Column style={{marginTop:10,width:"100%",margin:"0 auto", marginTop:"20px"}}>

              <Column style ={{width:"60%", backgroundColor:"#fff"}}>
                <SearchLayer>
                  <input className="custom-input" type="text" style={Inputstyle}
                  onKeyDown={handleKeyDown} 
                  value={search} onChange={(e)=>{
                    setSearch(e.target.value);
                    setRefresh((refresh) => refresh +1);
                  
                  }}
                  placeholder="공연 이름, 지역 입력" />
                  <img className ="searchicon" src={imageDB.redsearch} style={{width:20, height:20, position:"absolute", left:'88%'}} onClick={_handleSubmit}/>

                </SearchLayer>

                <FlexstartRow style={{width:"90%", margin : '10 auto', marginBottom:10}}>
                    <img src={imageDB.infocircle} width={16} height={16} o/>
                    <span style={{fontSize:"12px", color :"#636363", marginLeft:5}}>알아보고자 하는 공연 이름이나 지역이름을 넣어주세요</span>                  
              </FlexstartRow>
              </Column>

         

              <FlexstartRow style={{width:"100%", marginTop:50, marginBottom:10}}>
                <ResultLabel label={'총'} result = {displayitems.length} unit={'건'}/>
              </FlexstartRow>

              <div style={{overflowY:"hidden",width:"100%",margin:"0 auto", display:"flex", flexWrap:"wrap"}}>
              {
                displayitems.map((data, index)=>(
                    <BoxItem>
               <img src ={data.image} style={{width:150}}/>
                      <Item style={{color:"#131313", fontWeight:500,fontFamily: "Pretendard-SemiBold", marginBottom:20}}>{data.eventNm}</Item>
                      <Item><TagData>{data.eventStartDate} ~ {data.eventEndDate}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>공연홀</Taglabel><TagData>{data.opar}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>공연종류</Taglabel><TagData>{data.eventCo}</TagData></Item>

                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>공연시간</Taglabel><TagData>{data.eventStartTime} ~ {data.eventEndTime}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>공연주관</Taglabel><TagData>{data.mnnstNm}{data.phoneNumber}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>홈페이지</Taglabel><TagData>{data.homepageUrl}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>요금</Taglabel><TagData>{data.chrgeInfo}</TagData>
                      {
                        data.seatNumber != '' && <div>{data.seatNumber}석</div>
                      }
                      </Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>무료여부</Taglabel><TagData>{data.admfee}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>연령제한</Taglabel><TagData>{data.entncAge}</TagData></Item>
                      <Item><Taglabel><img src={imageDB.dot} style={{width:'3px',marginRight:3}}/>가시는길</Taglabel><TagData>{data.rdnmadr}</TagData></Item>
                      <Item><div style={MapbtnStyle} onClick={()=>{_handleMapview(data.latitude,data.longitude,data.eventNm)}}>지도로보기</div></Item>
                    </BoxItem>
                ))
              }
              </div>



            </Column>)
        }
          
    </Container>
  );

}

export default PCLifePerformanceEvent;

