import React, {Fragment, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexEndRow, Row } from "../common/Row";
import { UserContext } from "../context/User";
import { imageDB } from "../utility/imageData";
import { FILTERITMETYPE, PCMAINMENU } from "../utility/screen";
import { GiPositionMarker } from "react-icons/gi";
import "../screen/css/common.css"
import { googlelocationapiKey } from "../api/config";
import axios from "axios";
import LottieAnimation from "../common/LottieAnimation";
import { LoadingListStyle } from "../screen/css/common";


const Container = styled.div`

`
const style = {
  display: "flex"
};

const Point = styled.div`
  border-radius: 100px;
  background-color: rgb(0, 184, 155);
  width: 44px;
  height: 44px;
  font-size: 12px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  z-index:2;
  @keyframes blink-effect {
    50% {
      opacity: 0;
    }
  
  }
  
  .blink {
    animation: blink-effect 0.5s step-start infinite;
  }

`

const PointDesc = styled.div`
background: rgb(255, 255, 255);
border: 1px solid rgb(0, 184, 155);
border-radius: 15px;
width: 55%;
margin-left: -5px;
padding-left: 10px;
padding-right: 10px;
line-height: 1.2;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

/** 제목 정리
 ** 설명
! type 에 따라 point 처리를 바꿔준자
 */

const Position =({containerStyle, type, callback,}) =>  {

  console.log("TCL: Position -> type", type);

  const { dispatch, user } = useContext(UserContext);

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [loading, setLoading] = useState(false);
  const [addr, setAddr] = useState(user.address_name);
  const [refresh, setRefresh] = useState(1);


  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{

    setAddr(addr);
    setLoading(loading);
  }, [refresh])



  const _handleCurrentpos = () =>{

    const fetchLocation = async () => {
      try {

        setLoading(true);

        const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googlelocationapiKey}`);
     
        console.log("MATRIX LOG : fetchLocation : googlelocationapiKey:", googlelocationapiKey, response);

        setLatitude(response.data.location.lat);
        setLongitude(response.data.location.lng);

        // Geocoder를 사용하여 좌표를 주소로 변환
        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.coord2Address(response.data.location.lng, response.data.location.lat, (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address;

            console.log("TCL: FetchLocation -> ", address);
          
            user.address_name = address.address_name;
            user.region_1depth_name = address.region_1depth_name;
            user.region_2depth_name = address.region_2depth_name;
            user.region_3depth_name = address.region_3depth_name;
            user.main_address_no = address.main_address_no;

            geocoder.addressSearch(address.address_name, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                  const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                  user.latitude = result[0].y;
                  user.longitude = result[0].x;
                  dispatch(user);
              }
            });

      
            setAddr(user.address_name);

            setLoading(false);

            setRefresh((refresh) => refresh +1);

            callback(user);

   
          }else{
        
          }
        });

      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();




 
  }


  return (

    <Container style={containerStyle}>

    {(loading == true ) && <LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
            width={"100px"} height={'100px'} />}

            <FlexEndRow>
              <div style={{lineHeight: '32px', marginRight:20}}>
               

            </div>
            <Row>
    
              <GiPositionMarker size={20}/>
              <div>{addr}</div>
            </Row>



            <Row 
              onClick={_handleCurrentpos}
              style={{backgroundColor:"#fff",
              height:"44px",
              padding: "0px 16px 0px 16px",
              marginLeft: "10px",
              borderRadius: "100px"}}>
              <img src={imageDB.sample10} width={20} height={20} style={{borderRadius:50}} />
              <div>현재 위치 재검색</div>
              
            </Row>
            </FlexEndRow>

    </Container>
  );

}

export default Position;

