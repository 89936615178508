import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import { imageDB } from "../../utility/imageData";



const Container = styled.div`
  height :${({height}) => height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom:50px;

`
const style = {
  display: "flex"
};

const Contentitem = styled.div`
  font-size: ${({fontsize}) => fontsize};
  margin-top: 20px;
  font-family: 'Pretendard-Light';
`

const Empty =({containerStyle, content, height, fontsize='18px'}) =>  {
 
  return (

    <Container style={containerStyle} height={height} >
       <img src={imageDB.Empty} style={{height:60, width:60}}/>
       <Contentitem fontsize={fontsize}>{content}</Contentitem>
    </Container>
  );

}

export default Empty;

