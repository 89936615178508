import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { sleep, useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { readuserbyphone, Update_usertoken } from "../../service/UserService";
import localforage from 'localforage';
import { Column } from "../../common/Column";
import ButtonEx from "../../common/ButtonEx";
import StoreInfo from "../../components/StoreInfo";
import { Helmet } from "react-helmet";
import { googlelocationapiKey } from "../../api/config";
import axios from "axios";
import { ReadREGIONCODE } from "../../service/RegionCodeService";
import { useAtom, useAtomValue } from "jotai";
import { fetchDataTotalFcst } from "../../store/jotai";



const Container = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const style = {
  display: "flex"
};

const ButtonLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : center;

`
const SkipButton = styled.div`
  z-index: 10;
  width: 150px;

  height: 50px;
  background: #131313;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const LoginButton = styled.div`
  z-index: 10;
  width: 200px;

  height: 50px;
  background: #ff7e19;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 20px;

`
const MainItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

`

const HighLight = styled.div`
  font-size: 20px;
  color: rgb(255, 126, 25);
  font-family: Pretendard-SemiBold;
  margin-bottom: 15px;

`

const  MatchingItem = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction : row;

`
const MatchingItemData = styled.div`
  font-size: 28px;
  line-height:48px;
  color : #c2c1c1db;
`
const MatchingItemSubData = styled.div`
  font-size: 24px;
  line-height:48px;
  color : #c2c1c1db;
`
const Tag = styled.div`

  background: #ff7e19;
  color: #fff;
  padding: 10px 20px;
  font-size: 30px;
  border-radius: 5px;
  margin-left:5px;

`
const MainLabel = styled.div`
  font-size: 43px;
  font-family: Pretendard-Bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

`
const SubLabel = styled.div`
  font-size: 20px;
  font-family: Pretendard-Regular;
  color: rgb(124, 124, 124);
  line-height: 1.8;
  margin-top: 20px;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
`
const AppBtn = styled.div`

    height: 44px;
    border-radius: 10px;
    font-size: 20px;
    margin: 0px auto;
    width: 100%;
    border-radius: 4px;
    background: #131313;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 18px;
    font-family: "Pretendard-SemiBold";
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);

`

/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCGatecontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);

  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [loading, setLoading] = useState(true);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
    
  const handleCanPlay = () => {
    setIsVideoReady(true);

  };

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{
    const fetchLocation = async () => {
      try {
        const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googlelocationapiKey}`);
     
        console.log("MATRIX LOG : fetchLocation : googlelocationapiKey:", googlelocationapiKey, response);

        setLatitude(response.data.location.lat);
        setLongitude(response.data.location.lng);

        // Geocoder를 사용하여 좌표를 주소로 변환
        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.coord2Address(response.data.location.lng, response.data.location.lat, (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address;

            console.log("TCL: FetchLocation -> ", address);
          
            user.address_name = address.address_name;
            user.region_1depth_name = address.region_1depth_name;
            user.region_2depth_name = address.region_2depth_name;
            user.region_3depth_name = address.region_3depth_name;
            user.main_address_no = address.main_address_no;

            geocoder.addressSearch(address.address_name, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                  const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                  user.latitude = result[0].y;
                  user.longitude = result[0].x;
                  dispatch(user);
              }
            });

            const USERINFO = user;

            localforage.setItem('userconfig', USERINFO).then(async function () {
            
              
            }).catch(function (err) {
              console.error('Error saving userconfig:', err);
            });

   
          }else{
        
          }
        });

      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();
  },[])


  // FFFAE0
  return (

    <>
        <Container style={containerStyle}>
          <div style={{height:"700px", backgroundColor:"#e0f2ff", width:"100%"}}>
   
            <Row style={{width:"70%", margin:"150px auto"}}>
              <Column style={{width:"60%",height:"100%", paddingTop:20}}>
            
                  <MainLabel>
                    <div>당신 근처의</div>
                    <div>가장 일잘하는 동네 일꾼</div>
                  </MainLabel>
                  <SubLabel>
                    <div>이제 집안일은 동네에서 맡기세요</div>
                    <div>구해줘 홍여사가 가장 믿음직스러운 일꾼을 찾아 드립니다</div>
                  </SubLabel>
         

                  <SubLabel>
                    <div>일일 다운로드 횟수 200% 증가세 일일 계약 횟수 500% 증가</div>
                    <div>혁신적인 플랫폼으로 심부름앱의 지각변동</div>
                  </SubLabel>

                  <FlexstartRow style={{ margin:'40px auto 0px', width:"100%"}}>
                    <div style={{width:"40%"}}>
                      <div style={{position:"absolute"}}>
                        <img src={imageDB.playstore} style={{position:"relative", width:"30px", top:"7px", left:"20px"}}/>
                      </div>
                      <AppBtn>{'Google Play'}</AppBtn>
                    </div>
                    <div style={{width:"40%", marginLeft:20}}>
                      <div style={{position:"absolute"}}>
                        <img src={imageDB.appstore} style={{position:"relative", width:"30px", top:"7px", left:"20px"}}/>
                      </div>
                      <AppBtn>{'App Store'}</AppBtn>
                    </div>
                  </FlexstartRow>
       
              </Column>
          

              <video class="background-video" poster={imageDB.introducethumnail} preload="metadata" controls loop onCanPlay={handleCanPlay}>
                <source src={imageDB.introduce1}  type="video/mp4" />
              </video> 
            </Row>
          </div>

          <div style={{height:"700px", width:"100%"}}>
            <Row style={{width:"70%", margin:"150px auto"}}>
              <video class="background-video" poster={imageDB.introducethumnail2} preload="metadata" controls loop onCanPlay={handleCanPlay}>
                <source src={imageDB.introduce2}  type="video/mp4" />
              </video>

              <Column style={{width:"60%",height:"100%", paddingTop:20, paddingLeft:50}}>
                  <MainLabel>
                    <div>구해줘 홍여사 소개</div>
                  </MainLabel>
                  <SubLabel>
                    <div>믿을만한 홍여사 실시간 견적 문의 쉬운 요청</div>
                  </SubLabel>
                  <SubLabel>
                    <div>구해줘 홍여사의 인증 과정을 거친 </div>
                    <div>믿음직한 홍여사와 실시간 채팅을 통해 계약서 작성 </div>
                    <div>일 완료후 돈이 지급되는 ESCRO 시스템</div>
                  </SubLabel>

              </Column>
            </Row>
          </div>






     

          <div style={{height:"650px", backgroundColor:"#fff", width:"100%"}}>
            <Row style={{width:"100%", margin:"50px auto 0px"}}>

            
              <MatchingItem  style={{width:"40%"}}>
                <img src={imageDB.request1} style={{width:"380px"}}/>
              </MatchingItem>

              <Column style={{width:"50%", alignItems:"flex-start"}}>
              <div >
                <HighLight>간편한 등록요청</HighLight>
                <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>3단계만으로 요청가능</div>
                <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>10초만에 요청</div>
              </div>
              <Row style={{marginTop:5}}>
                  <Tag>3단계</Tag>
                  <Tag>10초</Tag>
                  <Tag>실시간</Tag>
              </Row>     
              </Column>
            </Row>
          </div>

          <div style={{height:"630px", backgroundColor:"#fff", width:"100%"}}>
                <Row style={{width:"100%", margin:"0px auto 0px"}}>    
                  <div style={{width:"30%"}}>
                    <HighLight>실시간 매칭</HighLight>
                    <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>긴급한 당신을 위한</div>
                    <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>지금 즉시 매칭 시스템</div>
                  </div>

                  <MatchingItem>
                    <img src={imageDB.matching} style={{width:"300px"}}/>
                    <img src={imageDB.matching2} style={{width:"380px"}}/>
                  </MatchingItem>
            
                </Row>
          </div>


          <div style={{height:"800px", backgroundColor:"#F6FDEC", width:"100%"}}>

            <Column style={{width:"100%", margin:"70px auto 0px"}}>
              <div style={{width:"80%",display:"flex", justifyContent:"center", flexDirection:"column"}}>
                  <HighLight>안전하고 쉬운 계약</HighLight>
                  <div style={{fontSize:38, fontFamily:"Pretendard-SemiBold"}}>모든 거래는 채팅으로 복잡하지 않고 간결하고 안전하게 거래</div>
              </div>
              <MatchingItem style={{marginTop:50}}>
                <img src={imageDB.chat1} style={{width:"350px", marginRight:10}}/>
                <img src={imageDB.chat2} style={{width:"350px", marginRight:10}}/>
                <img src={imageDB.chat3} style={{width:"350px"}}/>
              </MatchingItem>

            </Column>
          </div>



        </Container>
        <StoreInfo />
    </>

  );

}

export default PCGatecontainer;

