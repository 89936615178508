import React, {useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ButtonEx from "../../../common/ButtonEx";
import { BetweenColumn, Column, FlexstartColumn } from "../../../common/Column";
import { AroundRow, BetweenRow, FlexEndRow, FlexstartRow, Row } from "../../../common/Row";
import Text from "../../../common/Text";
import { UserContext } from "../../../context/User";
import { CONFIGMOVE, EventItems, ReviewContent } from "../../../utility/screen";
import { SubKeywordAddress } from "../../../utility/region";

import "../../../screen/css/common.css";
import { FaTemperatureHigh } from "react-icons/fa";

import { GrTransaction } from "react-icons/gr";
import { FaRegHeart } from "react-icons/fa";
import { DiResponsive } from "react-icons/di";
import { TbRelationOneToOne } from "react-icons/tb";
import { RiArrowRightSLine } from "react-icons/ri";
import { uploadImage } from "../../../service/UploadService";
import { Readuserbyusersid, Update_userinfobyusersid } from "../../../service/UserService";
import { FaCamera } from "react-icons/fa";
import { PiLockKeyLight } from "react-icons/pi"
import { BADGE } from "../../../utility/badge";
import { imageDB } from "../../../utility/imageData";
import { UpdateWorkInfoImageAll } from "../../../service/WorkService";
import { UpdateRoomInfoImageAll } from "../../../service/RoomService";
import LottieAnimation from "../../../common/LottieAnimation";
import { LoadingChatAnimationStyle, LoadingProfileAnimationStyle } from "../../../screen/css/common";
import { ReadChatReview } from "../../../service/ChatService";
import { GoRows } from "react-icons/go";
import TimeAgo from 'react-timeago';
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { getDate, getFullTime } from "../../../utility/date";
import ReviewItem from "../../ReviewItem";

import { PiBroom } from "react-icons/pi";

import { FaPen } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";
import { TbGpsFilled } from "react-icons/tb";
import { TbGps } from "react-icons/tb";
import { MdOutlineChangeCircle } from "react-icons/md";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`
  padding-bottom:30px;

`
const style = {
  display: "flex"
};

const BoxItem = styled.div`
  background: #fff;
  display: flex;
  flex-direction : column;
  margin: 0px auto 7px;
  padding: 30px;
  font-size:14px;
`

const BoxImg2 = styled.div`
  border-radius: 50px;
  background: ${({enable}) => enable == true ? ('#fdeda8'):('#ededed')};
  padding: 30px;
  display :flex;
`

const Name = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  width:50%;
`
const Link = styled.div`
  color: #ff7e19;
  font-size: 14px;
  font-family: 'Pretendard-SemiBold';
  padding-left: 5px;

`

const RealName = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding-left:10px;
`
const TemperatureLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: #131313;
  font-family: 'Pretendard-SemiBold';
`
const PointBox = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin : 10px 0px;
  flex-direction : row;
  padding: 15px 10px;

`
const PointBoxInner = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 5px;
 

`
const SubLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 10px;
`
const SubLabelContent = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 16px;
  padding: 20px 0px;
`
const Point = styled.div`
  color: #ff4e19;
  padding: 10px 18px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Jalnan2';
  border: 2px dotted #ff4e19;

`
const ULITEM = styled.div`
  line-height: 2.5;
  margin-top: 10px;
  font-size :16px;
  color :#131313;
`
const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  border-radius: 15px;
  height:110px;
  font-size:15px;
  width:25%;


`
const BoxImg = styled.div`
  border-radius: 50px;
  background: ${({enable}) => enable == true ? ('#fdeda8'):('#ededed')};
  padding: 20px;
  display :flex;
`

const Poupup = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 300px;
  background: #fff;

  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 1px solid #ededed;
  border-bottom: none;

`

const NameTag = styled.div`
  background: #fff;
  color: #131313;
  font-size: 10px;
`

const Label = styled.div`
  font-size :18px;
  font-family : Pretendard-SemiBold;
`

const ResultContent = {
  height: '150px',
  paddingTop: '20px',
  fontSize: '16px',
  outline:"none",
  resize :"none",
  border:"none",
  fontFamily: 'Pretendard-Regular',
  color: '#131313',
  lineHeight: 1.7
}
const SupportTag = styled.div`
  width: 30px;
  background: #ffffff91;
  font-size: 10px;
  color: #ff7e19;
  font-family: 'Pretendard-Bold';
  border: 1px solid #ff7e19;
  border-radius: 5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ContactTag = styled.div`
  width: 30px;
  font-size: 10px;
  color: #1960ff;
  font-family: 'Pretendard-Bold';
  border: 1px solid #1960ff;
  border-radius: 5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CompleteTag = styled.div`
  width: 30px;
  font-size: 10px;
  color: #1990ff;
  font-family: 'Pretendard-Bold';
  border: 1px solid #1990ff;
  border-radius: 5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`


const WorkItems=[
  {name : BADGE.WORKER1, img:"", enable:false,desc : BADGE.WORKERDESC1},
  {name : BADGE.WORKER2, img:"", enable:false,desc : BADGE.WORKERDESC2},
  {name : BADGE.WORKER3, img:"", enable:false,desc : BADGE.WORKERDESC3},
  {name : BADGE.WORKER4, img:"", enable:false,desc : BADGE.WORKERDESC4},
  {name : BADGE.WORKER5, img:"", enable:false,desc : BADGE.WORKERDESC5},
  {name : BADGE.WORKER6, img:"", enable:false,desc : BADGE.WORKERDESC6},
  {name : BADGE.WORKER7, img:"", enable:false,desc : BADGE.WORKERDESC7},
  {name : BADGE.WORKER8, img:"", enable:false,desc : BADGE.WORKERDESC8},
  {name : BADGE.WORKER9, img:"", enable:false,desc : BADGE.WORKERDESC9},
  {name : BADGE.WORKER10, img:"", enable:false,desc : BADGE.WORKERDESC10},
  {name : BADGE.WORKER11, img:"", enable:false,desc : BADGE.WORKERDESC11},
  {name : BADGE.WORKER12, img:"", enable:false,desc : BADGE.WORKERDESC12},

]

const Tag = styled.div`
  padding: 0px 10px;
  background: #ff7e19;
  color: #fff;
  font-size: 10px;
  margin-left:10px;

`
const DisableTag = styled.div`

  padding: 0px 10px;
  background: #EDEDED;
  color: #131313;
  font-size: 10px;
  margin-left:10px;

`

const Empty = styled.div`
min-height: 100px;
display: flex;
justify-content: center;
align-items: center;

`

const MainDataItem = styled.div`
  marginTop:20px;
  padding :5px;
  justify-content : flex-start;
  align-items :flex-start;
  border-radius :5px;
  background-color :  #fff;

  margin-bottom: 5px;
  border  :1px solid #ff7e19;
`
const MainDataItemText = styled.span`
  font-size :12px;
  color :  #ff7e19;
`

const Camera = styled.div`
  position: relative;
  top: 0px;
  left: -35px;
  background: #efefef9e;
  padding: 5px;
  border-radius: 50px;


`
const ActivityColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  line-height: 2.5;
`

const Activity = styled.div`
  background: #fff;
  margin-bottom: 5px;
  display:flex;
  justify-content:center;
  align-items : flex-start;
  color :#131313;
  flex-direction: column;
  width :100%;
  border: 1px solid #ededed;
  border-radius:10px;


`
const Line = styled.div`
  height:5px;
  background:#ededed;
  width: 100%;
`



const MobileProfileConfig =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */

 const fileInput = useRef();


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [temperature, setTemperature] = useState(40);
  const [img, setImg] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentloading, setCurrentloading] = useState(true);
  const [index, setIndex] = useState('');
  const [useritem, setUseritem] = useState({});
  const [badge, setBadge] = useState(false);
  const [reviewitems, setReviewitems] = useState([]);
  const [reviewcontentitems, setReviewcontentitems] = useState([]);
  const [manneritems, setManneritems] = useState([]);
  const [badgedata, setBadgedata] = useState({});

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setTemperature(temperature);
    setCurrentloading(currentloading);
    setLoading(loading);
    setImg(img);
    setIndex(index);
    setUseritem(useritem);
    setReviewitems(reviewitems);
    setManneritems(manneritems);
    setBadgedata(badgedata);
    setReviewcontentitems(reviewcontentitems);
  }, [refresh])

  useEffect(()=>{
      async function FetchData(){

        const USERS_ID = user.users_id;
        const useritem = await Readuserbyusersid({USERS_ID});

        let number = String(useritem.CREATEDT);
        
        setIndex(number.slice(5,number.length));
        setUseritem(useritem);

        const chatitems = await ReadChatReview({USERS_ID});
        console.log("TCL: FetchData -> chatitems", chatitems)
       

        let reviewdata = [];
        let reviewcontentdata = [];
        chatitems.map((chatdata)=>{

          if(chatdata.REVIEWITEMS != undefined 
            && chatdata.REVIEWITEMS.RESULTITEMS != undefined){
            chatdata.REVIEWITEMS.RESULTITEMS.map((data)=>{

              reviewdata.push({userimg :chatdata.OWNER.USERINFO.userimg, 
                username:chatdata.OWNER.USERINFO.nickname, 
                address_name : chatdata.OWNER.USERINFO.address_name, 
                reviewdate:chatdata.REVIEWITEMS.CREATEDAT, 
                reviewtext:data.result});

              data.items.map((subdata)=>{
                if(subdata.count > 0){
                  const FindIndex = reviewcontentdata.findIndex(x=>x.content == subdata.content);

                  if(FindIndex == -1){
                    reviewcontentdata.push({content: subdata.content, count :1});
                  }else{
                    reviewcontentdata[FindIndex].count += 1;
                  }
        
                }
              })
              setReviewcontentitems(reviewcontentdata);
            })


          }
    
        })
        console.log("TCL: FetchData -> reviewdata", reviewdata);
        setReviewitems(reviewdata);

        setImg(user.userimg);
        setCurrentloading(false);
        setRefresh((refesh) => refresh +1);
      }
      FetchData();
  }, [])

  const handleUploadClick = (e) => {
    fileInput.current.click();
  };

  const ImageUpload = async (data, data2) => {
    const uri = data;
    const random = data2;
    const URL = await uploadImage({ uri, random });
    return URL;
  };

    
  const handlefileuploadChange = async (e) => {
    let filename = "";
    setLoading(true);
    setRefresh((refresh) => refresh +1);
    const file = e.target.files[0];
    filename = file.name;

    var p1 = new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;


        img.onload = function() {
          // 원본 이미지 크기
          const originalWidth = img.width;
          console.log("TCL: img.onload -> originalWidth", originalWidth)
       
          const originalHeight = img.height;
   
  
          // 원하는 이미지 크기 (예: 300x300으로 크기 조정)
          const targetWidth = 100;
          const targetHeight = 100;
  
          // Canvas 생성
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // 비율 유지를 위해 축소
          let width = originalWidth;
          let height = originalHeight;
          if (width > height) {
              if (width > targetWidth) {
                  height *= targetWidth / width;
                  width = targetWidth;
              }
          } else {
              if (height > targetHeight) {
                  width *= targetHeight / height;
                  height = targetHeight;
              }
          }
  
          // 리사이즈된 캔버스 크기 설정
          canvas.width = width;
          canvas.height = height;
  
          // 이미지 리사이즈하여 캔버스에 그리기
          ctx.drawImage(img, 0, 0, width, height);
          console.log("TCL: img.onload -> ctx", ctx)
          console.log("TCL: img.onload -> img", img)
  
          // 캔버스 데이터를 Blob으로 변환 (JPEG 포맷, 품질 0.8)
          // Canvas 데이터를 base64로 변환
          const base64Image = canvas.toDataURL('image/jpeg', 0.8);  // 품질 0.8로 JPEG 변환

          resolve(base64Image);

          
        }


      };
    });
    const getRandom = () => Math.random();
    const email = getRandom();

    p1.then(async (result) => {
      const uri = result;

      let url = await ImageUpload(uri, email);
      console.log("TCL: handlefileuploadChange -> email", email)
      const IMGTYPE = true;


      setImg(url);

      user.userimg = url;
      dispatch(user);

      const USERINFO = user;
      const USERS_ID = user.users_id;


      const USERIMG = url;
      // 모든 체팅 업데이트 
      
      // 모든 일감 업데이트
      await UpdateWorkInfoImageAll({USERS_ID, USERIMG });
      // 모든 장소대여 업데이트
      await UpdateRoomInfoImageAll({USERS_ID, USERIMG });

      await Update_userinfobyusersid({USERINFO, USERS_ID});
      console.log("TCL: _handleSave -> user", USERINFO, USERS_ID);


      setLoading(false);
      setRefresh((refresh) => refresh +1);



    });
  };

  

  const _handleNameMove = () =>{
    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.PROFILENAME, TYPE : ""}});
  }


  
  const _handleBadge= (data) =>{
    setBadge(true);
    setBadgedata(data);

    setRefresh((refresh) => refresh +1);
  }

  const _handleClose = (data) =>{
    setBadge(false);

    setRefresh((refresh) => refresh +1);
  }

  const _handleAdjust = () =>{
    navigate("/Mobileladyresume",{state :{REALNAME :user.realname}});
  } 

 
  return (

    <Container style={containerStyle}>


    {badge == true ? (
            <Poupup>
              <Column>

                <Column style={{margin:"20px 0px 20px"}}>
                  <BoxImg2 enable={true}>
                  <img src={imageDB.medal} style={{width:45, height:45}}/>
                  </BoxImg2>
                </Column>

                <Column>
                <div style={{fontFamily:'Pretendard-Bold', fontSize:20}}>
                  {badgedata.name}
                </div>
                
                </Column>

                <Column style={{marginTop:10}}>
                  <div style={{fontFamily:'Pretendard-Light'}}>뱃지를 획득하는 방법 : </div>
                  <div style={{fontFamily:'Pretendard-Light'}}>{badgedata.desc}</div>
                </Column>

            
                <div style={{margin:"20px auto", width:"100%"}}>
                  <ButtonEx text={'닫기'} width={'85'}  
                  onPress={_handleClose} bgcolor={'#fff'} color={'#3a3636'} containerStyle={{fontFamily:"Pretendard-Regular",
                  border :"1px solid"
                  ,boxShadow:"none"}} />
                </div>
  
              
              </Column>
          

          
            </Poupup>
    ) : null}

    {
        currentloading == true  ? (<LottieAnimation containerStyle={LoadingProfileAnimationStyle} animationData={imageDB.loadinglarge}/>)
        :(<div>
          <BoxItem>

              <Column style={{justifyContent:"space-between"}}>
              <Row style={{justifyContent:"flex-start", width:"100%"}}>
                <img src={img}   style={{width:"50px", borderRadius:"50px", height:"50px", objectFit:"cover"}}/>
                <Camera onClick={handleUploadClick}><FaCamera size="18px"/></Camera>
                <Column style={{width:"100%", marginLeft:-20}}>
                  <FlexstartColumn style={{width:"100%"}}>
                    <BetweenRow style={{width:"100%"}}>
                        <Name>{user.nickname}</Name>
                        <Row>
                          <GoPencil color={'#ff7e19'} size={12}/>
                          <Link onClick={_handleNameMove}>{'대화명설정'}</Link>
                        </Row>
                    </BetweenRow>
                     
                    {
                    (user.realname != '' || user.realname != undefined) && <FlexstartRow style={{width:"50%",marginTop:5}}>
                         <NameTag>인증</NameTag>
                         <RealName>{user.realname}</RealName>
                    </FlexstartRow>
                    }
                  </FlexstartColumn>
                </Column>
            
              </Row>  

              <input
              type="file"
              ref={fileInput}
              onChange={handlefileuploadChange}
              style={{ display: "none" }}
              />

            

              {loading == true ? (<LottieAnimation containerStyle={LoadingChatAnimationStyle} animationData={imageDB.loadinglarge}
                      width={"100px"} height={'100px'}/>) :("")}

              </Column>
          </BoxItem>
          <Line/>

          {
            (user.realname != undefined || user.realname != '') &&
            <>
              <BoxItem> 
                <BetweenRow>
                  <Label>소개글입니다</Label>
                  <FlexEndRow style={{width:"30%"}}>
                    <Row>
                      <GoPencil color={'#ff7e19'} size={12}/>
                      <Link onClick={_handleAdjust}>{'소개글수정'}</Link>
                    </Row>
                  </FlexEndRow>
                </BetweenRow>

                {
                  (user.resume != '' && user.resume != undefined) ? 
                  (<div style={{whiteSpace:'pre-line', lineHeight:1.6, marginTop:10, fontSize:16}} >{user.resume}</div>)
                  :(<Empty>소개글이 없습니다</Empty>)
                }
              </BoxItem>
              <Line/>
            </>

          }

          <BoxItem> 
            <Label>대화명 변경 내역입니다</Label>
            <ULITEM>
              {
                useritem.NAMEITEMS.map((data, index) =>(
                  <FlexstartRow>
                  <MdOutlineChangeCircle size={22} color={'#131313'}/>
                  {index == 0 && <div style={{marginLeft:10}}>{data.name}으로 가입
                  <span style={{fontSize:13,color:"#b7b0b0",paddingLeft:10}}>{getDate(data.adjustdate)}</span></div>}
                  {index != 0 && <div style={{marginLeft:10}}>{data.name}으로 변경
                  <span style={{fontSize:13,color:"#b7b0b0",paddingLeft:10}}>{getDate(data.adjustdate)}</span>
                  </div>}
                  </FlexstartRow>
                 
                ))
              }
           
      
            </ULITEM>
          </BoxItem>
          <Line/>

 
          <BoxItem> 
            <Label>주소지 인증 내역입니다</Label>
            <ULITEM>
              {
                useritem.ADDRESSITEMS.map((item)=>(
       
                    <FlexstartRow>
                      <TbGps size={22} color={'#131313'}/>
                      <div style={{marginLeft:10}}>{SubKeywordAddress(item.ADDR)}</div>
                      {
                        item.AUTH == true ?( <Tag>인증</Tag>):( <DisableTag>미인증</DisableTag>)
                      }
                    </FlexstartRow>

                ))
              }
     
            </ULITEM>
          </BoxItem>
          <Line/>

          <BoxItem> 
            <Label>활동 이력입니다</Label>
            <ActivityColumn>
              <Activity> 
                <div style={{padding:10}}>
                  <Row>
                    <SupportTag>의뢰</SupportTag>
                    <div style={{paddingLeft:10,fontSize:16}}>집청소/다산동/2024.09.17</div>
                  </Row>
          
                  <div>30평대/ 남자 / 여자</div>

                </div>

        
                <div style={{height:2,width:"100%",background:"#ededed"}}></div>
                <div style={{padding:10, fontSize:16}}>
                  <div>견적금액 : 5만원대</div>
                </div>
              </Activity>
            </ActivityColumn>
          </BoxItem>
          <Line/>

          <BoxItem> 
            <Label>받은 후기내역 입니다</Label>
            {
              reviewitems.map((data)=>(
                  <ReviewItem item={data}/>
              ))
            }
          </BoxItem>
          <Line/>

          <BoxItem> 
            <Label>받은 매너평가 입니다</Label>
  
            {reviewcontentitems.length > 0 &&
            <div style={{marginTop:20}}>
              {
              reviewcontentitems.map((data, index)=>(
                <MainDataItem check={data.count>0}>
                  <MainDataItemText check={data.count>0}>
                  <FlexstartRow style={{height:"30px"}}>
                    <div style={{fontSize:16}}>{data.imagecontent}</div>
                    <div style={{paddingLeft:10,fontSize:14,fontFamily:"Pretendard-SemiBold"}}>{data.content}
                    <span style={{paddingLeft:5}}>{data.count}개</span>
                    </div>
                  </FlexstartRow>
                  
                </MainDataItemText>
                </MainDataItem>
              ))
              }
            </div>}
   
          </BoxItem>
          <Line/>

          <BoxItem style={{ padding: "30px 10px"}}>
          
            <BetweenRow style={{width:"90%", padding:"0px 20px"}}>   
                <Label>활동뱃지 내역 입니다</Label>  
                <Row>
                  <img src={imageDB.medal} style={{width:24, height:24}}/>
                  <div>0개 보유 중</div>  
                </Row>
            </BetweenRow>
            <Column style={{width:"100%",margin: "20px auto 10px"}} >   
                <BetweenRow style={{flexWrap:"wrap", width:"90%"}}>
                  {
                    WorkItems.map((data, index)=>(
                      <Box onClick={()=>{_handleBadge(data)}}   >
                        <BoxImg enable={data.enable}>          
                          {
                            data.enable == true ?  (
                              <img src={imageDB.medal} style={{width:34, height:34}}/>
                            ) 
                            :(  <PiLockKeyLight size={28} color={"#8c8b8b"}/>)
                          }      
                        </BoxImg>
                        <div style={{ fontSize:12, color:"#131313", fontFamily:"Pretendard-SemiBold", marginTop:5}}>{data.name}</div>
                      </Box>
                    ))
                  }
                </BetweenRow>
            </Column>   

          </BoxItem>
        </div>)
    }





    </Container>
  );

}

export default MobileProfileConfig;

