import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import { UserContext } from "./context/User";
import { useMediaQuery } from "react-responsive";
import Configpage from "./page/main/Configpage";
import Mainpage from "./page/main/Mainpage";
import Mappage from "./page/main/Mappage";
import Roompage from "./page/main/Roompage";
import Workpage from "./page/main/Workpage";
import PCAttendanceEventpage from "./page/PCmain/PCAttendanceEventpage";
import PCCenterpage from "./page/PCmain/PCCenterpage";

import PCCommunityreadpage from "./page/PCmain/PCCommunityreadpage";
import PCCommunitywritepage from "./page/PCmain/PCCommunitywriepage";

import PCHongguidepage from "./page/PCmain/PCHongguidepage";
import PCLoginpage from "./page/PCmain/PCLoginpage";
import PCMainpage from "./page/PCmain/PCMainpage";

import PCPolicypage from "./page/PCmain/PCPolicypage";

import PCRoomguidepage from "./page/PCmain/PCRoomguidepage";
import PCRoompage from "./page/PCmain/PCRoompage";
import PCRoomPricepage from "./page/PCmain/PCRoomPricepage";
import PCRulletEventpage from "./page/PCmain/PCRulletEventpage";
import PCSplashpage from "./page/PCmain/PCSplashpage";
import Splashpage from "./page/sub/Splash/Splashpage";
import PCWorkregistserpage from "./page/PCmain/PCWorkregisterpage";
import PCRoomregisterpage from "./page/PCmain/PCRoomregisterpage";
import PCRegistpage from "./page/PCmain/PCRegistpage";


import {Provider as MyProvider, useDispatch} from 'react-redux';
import PCChatpage from "./page/PCmain/PCChatpage";
import MobileSplashpage from "./page/main/MobileSplashpage";
import MobileMainpage from "./page/main/MobileMainpage";
import MobileRoomppage from "./page/main/MobileRoompage";
import MobileWorkregistserpage from "./page/main/MobileWorkregisterpage";
import MobileRoomregistserpage from "./page/main/MobileRoomregisterpage";
import MobileRegistpage from "./page/main/MobileRegistpage";
import MobileMappage from "./page/main/Mobilemappage";

import MobileSearchHistorypage from "./page/main/MobileSearchHistorypage";
import MobileGatepage from "./page/main/MobileGatepage";

import MobilePolicypage from "./page/main/MobilePolicypage";
import MobilePhonepage from "./page/main/MobilePhonepage";
import PCLifepage from "./page/PCmain/PCLifepage";
import MobileCommunityppage from "./page/main/MobileCommunitypage";
import MobileWorkpage from "./page/main/Mobileworkpage";
import MobileChatpage from "./page/main/MobileChatpage";
import MobileContentpage from "./page/main/MobileContentpage";

import localforage from 'localforage';
import MobileCommunityContentpage from "./page/main/MobileCommunityContentpage";
import MobileRoomWorkpage from "./page/main/MobileRoomworkpage";
import { ALLWORK } from "./store/menu/MenuSlice";
import MobileMapReconfigpage from "./page/main/MobileMapReconfigpage";
import MobileCommunityBoardpage from "./page/main/MobileCommunityBoardpage";
import MobileConfigpage from "./page/main/MobileConfigpage";

import MobileLifeTourDetailPicturepage from "./page/main/MobileLifeTourDetailPicturepage";
import MobileLifeTourAutoPicturepage from "./page/main/MobileLifeTourAutoPicturepage";
import MobileConfigContentpage from "./page/main/MobileConfigContentpage";
import MobileEventdetailpage from "./page/main/MobileEventdetailpage";
import MobileLadyLicenseAuthpage from "./page/main/MobileLadyLicenseAuthpage";
import MobileContactWritepage from "./page/main/MobileContactWritepage";
import MobileContactDocpage from "./page/main/MobileContactDocpage";
import MobileLifeTourRegionpage from "./page/main/MobileLifeTourRegionpage";
import MobileWorkMapconfigpage from "./page/main/MobileWorkMapconfigpage";

import PCGatepage from "./page/PCmain/PCGatepage";
import PCPhonepage from "./page/PCmain/PCPhonepage";
import PCSumPolicypage from "./page/PCmain/PCSumPolicypage";

import PCConfigpage from "./page/PCmain/PCConfigpage";
import PCConfigContentpage from "./page/PCmain/PCConfigContentpage";
import PCGuidepage from "./page/PCmain/PCGuidepage";
import PCLadyLicenseAuthpage from "./page/PCmain/PCLadyLicenseAuthpage";
import PCContactWritepage from "./page/PCmain/PCContactWritepage";
import PCContactDocpage from "./page/PCmain/PCContactDocpage";

import PCListpage from "./page/PCmain/PCListpage";
import PCHongpage from "./page/PCmain/PCHongpage";
import Hongpage from "./page/main/Hongpage";
import MobileListpage from "./page/main/MobileListpage";
import PCExperiencepage from "./page/PCmain/PCExperiencepage";
import MobileCompletepage from "./page/main/MobileCompletepage";
import MobileReviewpage from "./page/main/MobileReviewpage";
import MobileLadyResumepage from "./page/main/MobileLadyResumepage";
import PCGuidepageInfo from "./page/PCmain/PCGuidepageInfo";
import MobileLifeItempage from "./page/main/MobileLifeItempage";

import MobileProfilepage from "./page/main/MobileProfilepage";

import MobileAICategoryCreatepage from "./page/main/MobileAICategoryCreatepage";
import MobileAICategoryListpage from "./page/main/MobileAICategoryListpage";
import MobileAICategoryContentpage from "./page/main/MobileAICategoryContentpage";
import MobileRecipeContentpage from "./page/main/MobileRecipeContentpage";
import PCGuideLifepageInfo from "./page/PCmain/PCGuideLifepageInfo";
import MobileFreezeRecipepage from "./page/main/MobileFreezeRecipepage";

import MobileTourCourseTracepage from "./page/main/MobileTourCourseTracepage";
import MobileTourCourseAnalyzepage from "./page/main/MobileTourCourseAnalyzepage";

import PCLeisurepage from "./page/PCmain/PCLeisurepage";
import PCGamepage from "./page/PCmain/PCGamepage";
import PCMappage from "./page/PCmain/PCMappage";
import PCTourCourseAnalyzepage from "./page/PCmain/PCTourCourseAnalyzepage";
import PCTourCourseTracepage from "./page/PCmain/PCTourCourseTracepage";
import MobileLeisureppage from "./page/main/MobileLeisurepage";
import MobileLeisureContentcontainer from "./container/main/MobileLeisureContentcontainer";
import MobileLeisureContentpage from "./page/main/MobileLeisureContentpage";




const App =() =>  {



  const { user, dispatch } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){

    } 
    FetchData();

  }, [])
  useEffect(()=>{

  },[refresh])

  // useEffect(() => {

  //   localforage.getItem('userconfig')
  //   .then(function(value) {
  //     console.log("TCL: listener -> App userconfig", value)
  //     user.address_name = value.address_name;
  //     user.users_id = value.users_id;
  //     user.latitude = value.latitude;
  //     user.longitude = value.longitude;
  //     user.userimg = value.userimg;
    
  //     dispatch(user);

      
  //   })
  //   .catch(function(err) {

  //   });



  // }, []);



  return (
   
    <Routes>

      <Route
          path="/"
          element={isMobile ?  (<MobileSplashpage />) :   (<PCGatepage />)} 
        />

      <Route path="/Mobilegate" element={<MobileGatepage />} />
      <Route path="/Mobilepolicy" element={<MobilePolicypage />} />
      <Route path="/Mobilephone" element={<MobilePhonepage />} />
      <Route path="/Mobilemain" element={<MobileMainpage />} />
      <Route path="/Mobileroom" element={<MobileRoomppage />} />


      <Route path="/Mobilecommunity" element={<MobileCommunityppage />} />
      <Route path="/Mobilecommunitycontent" element={<MobileCommunityContentpage />} />

      <Route path="/Mobileleisure" element={<MobileLeisureppage />} />
      <Route path="/Mobileleisurecontent" element={<MobileLeisureContentpage />} />



      <Route path="/Mobilecommunityboard" element={<MobileCommunityBoardpage />} />
      <Route path="/Mobileworkregister" element={<MobileWorkregistserpage />} />
      <Route path="/Mobileroomregister" element={<MobileRoomregistserpage />} />
      <Route path="/Mobileregist" element={<MobileRegistpage />} />
      <Route path="/Mobilemap" element={<MobileMappage />} />
      <Route path="/Mobilework" element={<MobileWorkpage />} />
      <Route path="/Mobileworkroom" element={<MobileRoomWorkpage />} />
      <Route path="/Mobilechat" element={<MobileChatpage />} />
      <Route path="/Mobilecontent" element={<MobileContentpage />} />

      <Route path="/Mobileaicategorycreate" element={<MobileAICategoryCreatepage />} />
      <Route path="/Mobileaicategorylist" element={<MobileAICategoryListpage />} />
      <Route path="/Mobileaicategorycontent" element={<MobileAICategoryContentpage />} />
      <Route path="/Mobileprofile" element={<MobileProfilepage />} />
      <Route path="/Mobilesearchhistory" element={<MobileSearchHistorypage />} />
      <Route path="/Mobilemapreconfig" element={<MobileMapReconfigpage />} />
      <Route path="/Mobileworkmapconfig" element={<MobileWorkMapconfigpage />} />
      <Route path="/Mobileconfig" element={<MobileConfigpage />} />
      <Route path ="/Mobileconfigcontent" element={<MobileConfigContentpage />} />
      <Route path="/Mobiletourdetailpicture" element={<MobileLifeTourDetailPicturepage />} />
      <Route path="/Mobiletourautopicture" element={<MobileLifeTourAutoPicturepage />} />
      <Route path ="/Mobileeventdetail" element={<MobileEventdetailpage />} />
      <Route path ="/Mobileladylicense" element={<MobileLadyLicenseAuthpage />} />
      <Route path ="/Mobilecontactwrite" element={<MobileContactWritepage />} />
      <Route path ="/Mobilecontactdoc" element={<MobileContactDocpage />} />
      <Route path ="/Mobilelifetourregion" element={<MobileLifeTourRegionpage />} />
      <Route path ="/Mobilelist" element={<MobileListpage />} />
      <Route path ="/Mobilecomplete" element={<MobileCompletepage />} />
      <Route path ="/Mobilereview" element={<MobileReviewpage />} />
      <Route path ="/Mobilelifeitem" element={<MobileLifeItempage/>}/>
      <Route path ="/Mobilerecipe" element={<MobileRecipeContentpage/>}/>
      <Route path ="/Mobilefreezerecipe" element={<MobileFreezeRecipepage/>}/>
      <Route path ="/Mobiletourcoursetrace" element={<MobileTourCourseTracepage/>}/>
      <Route path ="/Mobiletourcourseanalyze" element={<MobileTourCourseAnalyzepage/>}/>

      <Route path="/Mobileladyresume" element={<MobileLadyResumepage />} />


      <Route path="/PCguidelifeInfo" element={<PCGuideLifepageInfo />} />


      <Route path="/PCGate" element={<PCGatepage />} />
      <Route path="/PClist" element={<PCListpage />} />
      <Route path="/PCmap" element={<PCMappage />} />
      <Route path="/PCchat" element={<PCChatpage />} />
      <Route path="/PCguideInfo" element={<PCGuidepageInfo />} />
      <Route path="/PClife" element={<PCLifepage />} />
      <Route path="/PCleisure" element={<PCLeisurepage />} />
      <Route path="/PCEvent" element={<PCGamepage />} />
      <Route path="/PCSumpolicy" element={<PCSumPolicypage />} />
      <Route path="/PClogin" element={<PCLoginpage />} />
      <Route path="/PCpolicy" element={<PCPolicypage />} />
      <Route path ="/PCtourcourseanalyze" element={<PCTourCourseAnalyzepage/>}/>
      <Route path ="/PCtourcoursetrace" element={<PCTourCourseTracepage/>}/>
      <Route path="/PCPhone" element={<PCPhonepage />} />
      <Route path="/PCGate/PCguide" element={<PCGuidepage />} />

      <Route path="/PCGate/PChong" element={<PCHongpage />} />

      <Route path="/PCMain" element={<PCMainpage />} />
  
 

      <Route path="/PChongguide" element={<PCHongguidepage />} />
      <Route path="/PCcenter" element={<PCCenterpage />} />



      <Route path="/PCroom" element={<PCRoompage />} />
      <Route path="/PCroomprice" element={<PCRoomPricepage />} />
      <Route path="/PCworkregister" element={<PCWorkregistserpage />} />
      <Route path="/PCregist" element={<PCRegistpage />} />
      <Route path="/PCroomregister" element={<PCRoomregisterpage />} />
      <Route path="/PCconfig" element={<PCConfigpage />} />
      <Route path ="/PCconfigcontent" element={<PCConfigContentpage />} />
      <Route path ="/PCladylicense" element={<PCLadyLicenseAuthpage />} />

      <Route path ="/PCcontactwrite" element={<PCContactWritepage />} />
      <Route path ="/PCcontactdoc" element={<PCContactDocpage />} />
 
      <Route path="/PCexperience" element={<PCExperiencepage />} />

      {/* <Route path="/main" element={<Mainpage />} /> */}
      <Route path="/hong" element={<Hongpage />} />
      <Route path="/work" element={<Workpage />} />
      <Route path="/map" element={<Mappage />} />
      <Route path="/room" element={<Roompage />} />
      <Route path="/config" element={<Configpage />} />

  

    </Routes>

  );

  
 
}

export default App;
