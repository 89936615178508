import { Table } from "@mui/material";
import React, {Suspense, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import { Column, FlexstartColumn } from "../common/Column";
import LottieAnimation from "../common/LottieAnimation";
import { BetweenRow, FlexstartRow, Row } from "../common/Row";
import { DataContext } from "../context/Data";
import { UserContext } from "../context/User";
import { LoadingCommunityStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import { ReadCampingRegion } from "../service/LifeService";
import { useSleep } from "../utility/common";
import { imageDB } from "../utility/imageData";
import "./mobile.css";
import { IoMdClose } from "react-icons/io";
import { GrTip } from "react-icons/gr";
import { COLORS } from "../utility/colors";

import "./MobileYoutubeRegion.css"
import CurrentWeather from "./CurrentWeather";
import { CONVENIENCEMENU } from "../utility/life";
import PCSlickSliderComponentEx from "../common/PCSwipeex";


const Container = styled.div`
    width:100%;

`
const style = {
  display: "flex"
};

const DetailLevel = 4;
const DetailMeter =300;
const BasicLevel =8;



const mapstyle = {
  position: "absolute",
  overflow: "hidden",
  top: '10%',
  width:'100%',
  };

const PopupWorkEx = styled.div`
  position: absolute;
  top:100px;
  left :30%;
  width: 40%;
  background: #fff;
  border : 1px solid #000;
  z-index: 5;
  overflow-y: auto;
  padding-bottom: 80px;
  height: 700px;
`

const TableLayout = styled.div`
  overflow-y: scroll;
  height: calc(-100px + 100vh);
  scroll-behavior: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
`
const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapExbtn = styled.div`
  position: relative;
  top: 10px;
  left: 88%;
  z-index: 3;
  background: #f9f9f9;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const HeaderPopupline = styled.div`

  width:30%;
  background:#E3E3E3;
  height:5px;
`
const LableIconLayer = styled.div`
  border-radius: 5px;
  height: 30px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
const LabelText = styled.div`
  margin-left:5px;
  font-family : Pretendard-SemiBold;
  font-size :16px;
`
const MainContent = styled.div`
  margin: unset;
  width: 100%;
  background: #131313;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
  margin-bottom:10px;

`
const ContentText = styled.div`
  font-size: 16px;
  margin: 10px 0px;
  color : ${COLORS.BLACKCOLOR};
  word-wrap: break-word; /* 단어가 길어도 줄바꿈 */
  overflow-wrap: break-word; /* 최신 표준 속성 */
`

const ButtonLayer = styled.div`
  position: fixed;
  bottom: 60px;
  width: 60px;
  height: 60px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: #195eff;
  right: 15%;

  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
  animation: pulse 1s infinite; /* 애니메이션 설정 */
`

const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
`
const Inputstyle={
  background: '#FFF',
  borderRadius:'25px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",

}

const CampingLayer = styled.div`
  width: 85%;
  margin: 10px auto;
  border: 1px solid #b7b7b7;
  padding: 10px;
  border-radius: 10px;
  line-height:1.5;

`
const CampingRegion = styled.div`
  font-size:12px;
  color : #999;

`
const Tag = styled.div`
  border: 1px solid #ededed;
  background: #f9f9f9;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right:5px;
  margin-bottom:5px;

`
const maplayerstyle ={
  position: "absolute",
  width: "70%",
  left: "16%",
  top: "34%",
}

const BannerItems =[


  {type:CONVENIENCEMENU.CONVENIENCECAMPING,image : imageDB.camping, main1:"캠핑장정보", main2:
    "캠핑장 주소, 애완동물 가능여부, 시설, 캠핑장 사진" +
    "\n" +
    "캠핑장 홈페이지, 전화번호, 민간 운영 여부 정보 제공 캠핑장 주변의 실시간 날씨 정보를 제공해줍니다",color:"#ebf7fa"},


]

  
/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const PCLifeCampingRegion =({containerStyle}) =>  {


/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [overlays, setOverlays] = useState([]);
  const [item, setItem] = useState();
  const [currentloading, setCurrentloading] = useState(true);
  const [curmap, setCurMap] = useState({});
  const [search, setSearch] = useState("");
  const [searchpopup, setSearchpopup] = useState(false);
  const [searchitems, setSearchitems] = useState([]);

    useLayoutEffect(() => {
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {};
    }, []);

    useEffect(()=>{
        setOverlays(overlays);
        setCurrentloading(currentloading);
        setItem(item);
        setSearch(search);
        setSearchpopup(searchpopup);
        setSearchitems(searchitems);
    }, [refresh])

    useEffect(()=>{
        async function FetchData(){

            let items = [];
            if(data.campingregionitem.length == 0){
              const campingitem = await ReadCampingRegion();
              campingitem.map((data, index)=>{
  
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data.campingitem, "text/xml");
            
                // 데이터 추출
                const xmlitems = xmlDoc.getElementsByTagName("item");
          
                
                const data2 = [];
  
                for (let i = 0; i < xmlitems.length; i++) {
                  const contentId = xmlitems[i].getElementsByTagName("contentId")[0].textContent; 
                  const facltNm = xmlitems[i].getElementsByTagName("facltNm")[0].textContent;
                  const lineIntro = xmlitems[i].getElementsByTagName("lineIntro")[0].textContent;
                  const intro = xmlitems[i].getElementsByTagName("intro")[0].textContent;
                  const facltDivNm = xmlitems[i].getElementsByTagName("facltDivNm")[0].textContent;
                  const induty = xmlitems[i].getElementsByTagName("induty")[0].textContent;
                  const addr1 = xmlitems[i].getElementsByTagName("addr1")[0].textContent;
                  const resveCl = xmlitems[i].getElementsByTagName("resveCl")[0].textContent;
                  const tooltipme = xmlitems[i].getElementsByTagName("tooltip")[0].textContent;
  
                  const caravInnerFclty = xmlitems[i].getElementsByTagName("caravInnerFclty")[0].textContent;
                  const brazierCl = xmlitems[i].getElementsByTagName("brazierCl")[0].textContent;
                  const sbrsCl = xmlitems[i].getElementsByTagName("sbrsCl")[0].textContent;
                  const sbrsEtc = xmlitems[i].getElementsByTagName("sbrsEtc")[0].textContent;
                  const posblFcltyCl = xmlitems[i].getElementsByTagName("posblFcltyCl")[0].textContent;
                  const animalCmgCl = xmlitems[i].getElementsByTagName("animalCmgCl")[0].textContent;
                  const firstImageUrl = xmlitems[i].getElementsByTagName("firstImageUrl")[0].textContent;
  
                  const mapX = xmlitems[i].getElementsByTagName("mapX")[0].textContent;
                  const mapY = xmlitems[i].getElementsByTagName("mapY")[0].textContent;
                  const tel = xmlitems[i].getElementsByTagName("tel")[0].textContent;
                  const homepage = xmlitems[i].getElementsByTagName("homepage")[0].textContent;
  
                  
                  items.push({contentId,facltNm,lineIntro,intro,facltDivNm,induty,addr1, resveCl, tooltipme,caravInnerFclty,brazierCl,sbrsCl, sbrsEtc,
                    posblFcltyCl,animalCmgCl,firstImageUrl,mapX,mapY,tel,homepage})
                }
  
              
  
              })
  
              data.campingregionitem = items;
              datadispatch(data);
            }
    
            await useSleep(1000);
            ListmapDraw(data.campingregionitem)
        }
        FetchData();
    }, [])

 
    const _handleExmap = () =>{
      navigate("/Mobilemap" ,{state :{WORK_ID :"", TYPE : ""}});
    }


    function findMapIndex(id, items){
        console.log("TCL: findWorkIndex -> workitems", items)
    
       const FindIndex =  items.findIndex(x=>x.contentId  === id)
    
       return FindIndex;
      }

    const _handleControlFromMap = (id, items) =>{


        let FindIndex = findMapIndex(id, items);
    

        setItem(items[FindIndex]);

        setRefresh((refresh) => refresh+1);
    }


    const _handleClose = ()=>{
        setItem(null);
        setRefresh((refresh) => refresh +1);
    } 


    const _handleSearchPopup = () =>{
      setSearchpopup(true);
      setRefresh((refresh) => refresh +1);
    }



    function ListmapDraw(datas){
    console.log("TCL: ListmapDraw -> datas", datas)
    var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
    mapOption = { 
        center: new kakao.maps.LatLng(user.latitude, user.longitude), // 지도의 중심좌표
        level: BasicLevel // 지도의 확대 레벨
    };



    var map = new kakao.maps.Map(mapContainer, mapOption);
    const zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.TOPRIGHT); //
    var overlaysTmp = [];
    var overlays = [];


    for (var i = 0; i < datas.length; i ++){
        let overlay = {
        POSITION : {},
        NAME : "",
        ITEMS :datas,
        id :"",
        }

   
        let latitude = "";
        let longitude = "";


  

        latitude = datas[i].mapY;
        longitude =  datas[i].mapX;


        overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
        overlay.id = datas[i].contentId;
        overlay.NAME = datas[i].facltNm;
        overlay.ITEMS = datas
        overlaysTmp.push(overlay);

    }



    // 오버레이를 지도에 추가하고 클릭 이벤트 처리
    overlaysTmp.forEach(function(overlayData, index) {

      var content = document.createElement('div');
      var customOverlay ={};

      kakao.maps.event.addListener(map, 'zoom_changed', function() {
        // 현재 지도 레벨 가져오기
        var level = map.getLevel();

        console.log('현재 지도 레벨: ' + level);


        content.className = 'maptourregionlay';

        if (level > 9) {
         

        } else {

          // Custom Overlay 내용 생성
  

          content.innerHTML =
          '  <a>' +
          '    <div>' +
          '    <img src="'+ imageDB.camping+'"style="width:34px;"/>' +
          '    </div>' +
          '    <div class="title">'+overlayData.NAME +'</div>' +
          '  </a>' +
          '</div>';

          // Custom Overlay 생성
          var customOverlay = new kakao.maps.CustomOverlay({
              position: overlayData.POSITION,
              content: content,
              clickable: true // 클릭 가능하도록 설정
          });

          var customData = {
              id: overlayData.id,
              items : overlayData.ITEMS
          };
          customOverlay.customData = customData;
          // Custom Overlay 지도에 추가
          customOverlay.setMap(map);

          overlays.push(customOverlay);

                // 클릭 이벤트 등록 
          // 지도에서 클릭 햇을때는 리스트에서 클릭 했을때와 달리 별도로 circle을 표시할 필요는 없다
          content.addEventListener('click', function(event) {
          _handleControlFromMap(customOverlay.customData.id, customOverlay.customData.items);

          });


          // 확대/축소 레벨 제한 설정
          const minLevel = 1;
          const maxLevel = 10;

          window.kakao.maps.event.addListener(map, 'zoom_changed', () => {
      
              const level = map.getLevel();

              if (level < minLevel) {
                  map.setLevel(minLevel);
              } else if (level > maxLevel) {
                  map.setLevel(maxLevel);
              }
          });

        }
      });



    });


    map.setLevel(map.getLevel() +1);
    setCurMap(map);

    //오버레이를 변수에 담아둔다
    setOverlays(overlays);


    // 마커 클러스터러 생성
    var clusterer = new kakao.maps.MarkerClusterer({
      map: map, // 클러스터러가 표시될 지도 객체
      averageCenter: true, // 클러스터의 평균 중심 좌표 사용
      minLevel: 8, // 최소 줌 레벨 (확대 시 클러스터가 해제될 레벨)
      gridSize: 60, // 클러스터 간격 설정
      averageCenter: true,
      minLevel: 7,
      styles: [{ // 클러스터 아이콘 커스텀 스타일
        width: '40px',
        height: '40px',
        background: '#19a4ffc7',
        borderRadius: '50%',
        textAlign: 'center',
        color: '#fff',
        fontSize: '14px',
        lineHeight: '40px'
      }]
    });

    // 마커 클러스터러에 마커 추가
    clusterer.addMarkers(overlays);

    setCurrentloading(false);
    setRefresh((refresh) => refresh +1);



    }

    const _handlePopupClose = () =>{
      setItem(null);
      setRefresh((refresh) => refresh +1);
    }

   const _handleSearchPopupClose = () =>{
   
      setSearchpopup(false);
      setRefresh((refresh) => refresh +1);
   }

   const handleKeyDown = (event)=>{
    if (event.key === 'Enter') {
      Searchfunc();
    }

   }
   
   const Searchfunc = () =>{
    _handleSubmit();
   }
   const _handleSubmit = () =>{

    console.log("MATRIX LOG : MobileLifeCampingRegion : search:", search)
    if(search == ''){
  
      setSearchitems([]);
      setRefresh((refresh) => refresh +1);

      return;
    }

  
    
    let itemsTmp = [];
    data.campingregionitem.map((data)=>{

      const containsWord = data.facltNm.includes(search); // 'React'가 포함되어 있는지 확인

      if(containsWord != ''){
        data["open"] = false;
        itemsTmp.push(data);
      }else{

        const containsWord2 = data.addr1.includes(search); // 'React'가 포함되어 있는지 확인

        if(containsWord2 != ''){
          data["open"] = false;
          itemsTmp.push(data);
        }

        console.log("MATRIX LOG : MobileLifeCampingRegion :불일치:", containsWord)
      }
      
    })
    console.log("MATRIX LOG : MobileLifeCampingRegion : _handleSubmit:", itemsTmp);

    setSearchitems(itemsTmp);
    setRefresh((refresh) => refresh +1);

   }

   const _handleView= (data)=>{
    if(data.open == false){
      data.open = true;
    }
    setSearchitems(searchitems);
    setRefresh((refresh) => refresh +1);
   }

   const _handleViewClose= (data)=>{
    if(data.open == true){
      data.open = false;
    }
    setSearchitems(searchitems);
    setRefresh((refresh) => refresh +1);
   }

   const CampingItem = ({item, width, close}) =>{
    return (
      <div style={{display:"flex", flexDirection:"column",width:"100%" }}>

        {
          close == true && <Row style={{height:"30px"}}>
          <div style={{position:"absolute", right:10, top:5}} onClick={_handlePopupClose} >
            <IoMdClose  size={40}/>
          </div>
        </Row>
        }


      <FlexstartColumn style={{width:`${width}`,margin:"20px auto 20px"}}>
   
        <MainContent>{item.facltNm}</MainContent>
        {
          item.addr1 != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
            <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>주소지</LabelText>

          </FlexstartRow>
          <ContentText>{item.addr1}</ContentText>
          </>
        }
        {
          item.animalCmgCl != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
              <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>애완동물</LabelText>
        
            </FlexstartRow>
            <ContentText>{item.animalCmgCl}</ContentText>
          </>
        }
        {
          item.brazierCl != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
              <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>개별여부</LabelText>
        
            </FlexstartRow>
            <ContentText>{item.brazierCl}</ContentText>
          </>
        }
        {
            item.caravInnerFclty != '' &&
            <>
              <FlexstartRow>
              <LableIconLayer>
              <GrTip color={'#131313'}/>
              </LableIconLayer>
              <LabelText>시설</LabelText>

              </FlexstartRow>
              <ContentText>{item.caravInnerFclty}</ContentText>
            </>
        }
        {
        item.facltDivNm != '' &&
        <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>민간여부</LabelText>

          </FlexstartRow>
          <ContentText>{item.facltDivNm}</ContentText>
        </>
        }
        {
          item.caravInnerFclty != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>시설</LabelText>

            </FlexstartRow>
            <ContentText>{item.caravInnerFclty}</ContentText>
          </>
        }
        {
          item.firstImageUrl != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>캠핑장사진</LabelText>

          </FlexstartRow>
          <div className="view_thumb">
            <img src={item.firstImageUrl } /></div>
          </>
        }
        {
          item.homepage != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>홈페이지</LabelText>

            </FlexstartRow>
            <ContentText>{item.homepage}</ContentText>
          </>
        }
        {
          item.lineIntro != '' &&
          <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>요약</LabelText>

          </FlexstartRow>
          <ContentText>{item.lineIntro}</ContentText>
          </>
        }

        {
          item.intro != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>소개글</LabelText>

            </FlexstartRow>
            <ContentText>{item.intro}</ContentText>
          </>
        }



        {
        item.posblFcltyCl != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>수영장</LabelText>

            </FlexstartRow>
            <ContentText>{item.posblFcltyCl}</ContentText>
          </>
        }

        {
        item.sbrsEtc != '' &&
        <>
          <FlexstartRow>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>기반시설</LabelText>

          </FlexstartRow>
          <ContentText>{item.sbrsEtc}</ContentText>
        </>
        }
        {
          item.tel != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>전화번호</LabelText>

            </FlexstartRow>
            <ContentText>{item.tel}</ContentText>
          </>
        }

        {
          item.tooltipme != '' &&
          <>
            <FlexstartRow>
            <LableIconLayer>
            <GrTip color={'#131313'}/>
            </LableIconLayer>
            <LabelText>주변</LabelText>

            </FlexstartRow>
            <ContentText>{item.tooltipme}</ContentText>
          </>
        }

          <FlexstartRow style={{marginTop:10}}>
          <LableIconLayer>
          <GrTip color={'#131313'}/>
          </LableIconLayer>
          <LabelText>캠핑장 주변 날씨정보</LabelText>

          </FlexstartRow>
       

      </FlexstartColumn>
      <Suspense fallback={<div>Loading...</div>}>
      <CurrentWeather containerStyle={{marginBottom:20}} addr={item.addr1} type={'campingregion'} />
      </Suspense>
    </div>
    )
   }



    return (

    <Container style={containerStyle}>
      {
        item != null && <PopupWorkEx>        
        <CampingItem item={item}  width={'90%'} close = {true}/>
        </PopupWorkEx>
      }
    
      {
        searchpopup == true && <PopupWorkEx>
          <div style={{display:"flex", flexDirection:"column",width:"100%" }}>
            <Row style={{height:"30px"}}>
            <div style={{position:"absolute", right:10, top:5}} onClick={_handleSearchPopupClose} >
            <IoMdClose  size={25}/>
            </div>
            </Row>

            <SearchLayer>
            <input  type="text" style={Inputstyle}
            onKeyDown={handleKeyDown} 
            class="communityinput"
            value={search} onChange={(e)=>{
            setSearch(e.target.value);
            setRefresh((refresh) => refresh +1);

            }}
            placeholder="캠핑장 이름, 지역 입력" />
            <img className ="searchicon" src={imageDB.redsearch} style={{width:20, height:20, position:"absolute", left:'88%'}} onClick={_handleSubmit}/>
            </SearchLayer>

            {
              searchitems.map((data)=>(
                <CampingLayer>
                  <div>{data.facltNm}</div>
                  <BetweenRow>
                  <CampingRegion>{data.addr1}</CampingRegion>
                  {
                    data.open == false ? ( <div style={{color:"#ff7e19", fontSize:12}} onClick={()=>{_handleView(data)}}>상세보기</div>):(
                      <div style={{color:"#ff7e19", fontSize:12}} onClick={()=>{_handleViewClose(data)}}>접기</div>
                    )
                  }
                 
                
                  </BetweenRow>   

                  <FlexstartRow style={{flexWrap:"wrap", marginTop:10}}>
                  {data.firstImageUrl != ''&& <Tag>캠핑장 사진있음</Tag>}
                  {data.facltDivNm != ''&& <Tag>{data.facltDivNm}운영</Tag>}
                  {data.animalCmgCl != ''&& <Tag>애완동물동반{data.animalCmgCl}</Tag>}
                  {data.homepage != ''&& <Tag>홈페이지있음</Tag>}
                  </FlexstartRow>
                  {
                    data.open == true &&
                    <CampingItem item={data} width={'100%'} close={false}/>
                  }
                </CampingLayer>
              ))
            }





          </div>
        </PopupWorkEx>
      }



      {
        currentloading == true  && <LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'}/>
      }

      <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'}/>

      <div style={maplayerstyle}>
        <div id="map" className="Map" style={mapstyle}></div>
    
      </div>

      {
        currentloading == false  &&
        <ButtonLayer onClick={_handleSearchPopup}>
        <div style={{fontSize:18}}>검색</div>
        </ButtonLayer>
      }
  


    </Container>
    );

}

export default PCLifeCampingRegion;

