import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { DataContext } from "../../context/Data";
import { UserContext } from "../../context/User";
import { ReadCommunitySummary } from "../../service/CommunityService";
import { ReadRoom } from "../../service/RoomService";
import { ReadWork } from "../../service/WorkService";
import { useSleep } from "../../utility/common";
import { imageDB } from "../../utility/imageData";

import { ReadCampingRegion, ReadHospitalRegion, ReadHospitalRegion1, ReadPerformanceCinema, ReadPerformanceEvent, ReadTourCountry, ReadTourFestival, ReadTourPicture, ReadTourRegion } from "../../service/LifeService";
import { INCLUDEDISTANCE } from "../../utility/screen";
import "./PCmain.css";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingMainAnimationStyle } from "../../screen/css/common";
import { Row } from "../../common/Row";
import localforage from 'localforage';
import { readuserbydeviceid, updatealluserbydeviceid, Update_usertoken } from "../../service/UserService";
import { googlelocationapiKey } from "../../api/config";
import axios from "axios";

const Container = styled.div`
  height: 100vh;
  display : flex;
  justify-content:center;
  alignItems:center;
  width :100%;


`
const style = {
  display: "flex"
};
const SkipButton = styled.div`
  z-index: 10;
  width: 180px;
  position: absolute;
  height: 50px;
  background: #ff7e19;
  top: 80%;
  left: 87%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 20px;

`


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const PCSplashcontainer =({containerStyle}) =>  {

/** PC 웹 초기 구동시에 데이타를 로딩 하는 component
 * ① 상점정보를 불러온다
 * ② 상점정보를 불러오는 동안 로딩화면을 보여준다
 */

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = useState(true);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  console.log("TCL: PCSplashcontainer -> user", user);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);


  useEffect(()=>{

  },[refresh])

  /**

   */
  useEffect(()=>{


    const fetchLocation = async () => {
      try {
        const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googlelocationapiKey}`);
     
        console.log("MATRIX LOG : fetchLocation : googlelocationapiKey:", googlelocationapiKey, response);

        setLatitude(response.data.location.lat);
        setLongitude(response.data.location.lng);

        // Geocoder를 사용하여 좌표를 주소로 변환
        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.coord2Address(response.data.location.lng, response.data.location.lat, (result, status) => {
          if (status === kakao.maps.services.Status.OK) {
            const address = result[0].address;

            console.log("TCL: FetchLocation -> ", address);
          
            user.address_name = address.address_name;
            user.region_1depth_name = address.region_1depth_name;
            user.region_2depth_name = address.region_2depth_name;
            user.region_3depth_name = address.region_3depth_name;
            user.main_address_no = address.main_address_no;

            geocoder.addressSearch(address.address_name, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                  const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                  user.latitude = result[0].y;
                  user.longitude = result[0].x;
                  dispatch(user);
              }
            });

            const USERINFO = user;

            localforage.setItem('userconfig', USERINFO).then(async function () {
            
              
            }).catch(function (err) {
              console.error('Error saving userconfig:', err);
            });

            FetchData();
   
          }else{
        
          }
        });

      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();

    async function FetchData(){

      navigate("/PCGate");
    }
  
    


  }, [])




 
  return (
    <Row style={{height:"100vh"}}>
    { loading == true && <LottieAnimation  animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}
      />
    }
    </Row>
  );
}

export default PCSplashcontainer;

