import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import { Column, FlexstartColumn } from "../../common/Column";
import { CHATCONTENTTYPE, CHATIMAGETYPE, CHATSELECTFILTER, EventItems, PCCOMMNUNITYMENU, PCMAINMENU } from "../../utility/screen";
import Communitymenu from "../../common/Communitymenu";
import CommunityItem from "../../components/CommunityItem";
import Empty from "../../components/Empty";
import Button from "../../common/Button";
import { ReadCommunity, ReadCommunitySummary, ReadCommunityTop10 } from "../../service/CommunityService";
import { DataContext } from "../../context/Data";
import { sleep, useSleep } from "../../utility/common";
import Chatgate from "../../components/Chatgate";
import Emptychat from "../../components/Emptychat";
import { readuser } from "../../service/UserService";
import { ReadChat, ReadDocChat } from "../../service/ChatService";
import LottieAnimation from "../../common/LottieAnimation";
import { LoadingChat3AnimationStyle, LoadingChatAnimationStyle } from "../../screen/css/common";
import MobileContentcontainer from "../main/MobileContentcontainer";
import PCWorkContentcontainer from "./PCWorkContentcontainer";
import PCRoomContentcontainer from "./PCRoomContentcontainer";
import { collection, getDocs, orderBy,query } from "firebase/firestore";
import { db } from "../../api/config";

import { Helmet } from 'react-helmet';

const Container = styled.div`
    background-color : #f9f9f9;
    width:100%;
    margin:0 auto;
    overflow-x: hidden; /* X축 스크롤을 숨깁니다. */
    overflow-y: hidden; /* Y축 스크롤은 숨깁니다 */
    scrollbar-width: none; /* Firefox용 - 스크롤바 숨기기 */
    &::-webkit-scrollbar{
      display: none;
    }

`
const style = {
  display: "flex"
};


const ReadAlertLayout = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* margin-right: 5px; */
  padding-top: 70px;
  background: #efefefeb;
  z-index: 3;
  width: 100%;
`
const ReadAlertText = styled.div`
  color:#131313;
  font-size:14px;
`
const FilterLayer = styled.div`
  border: 1px solid #ededed;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 5px;
  background-color : ${({enable}) => enable == true ? ("#5b5959"):("#fff")};
  color : ${({enable}) => enable == true ? ("#fff"):("#131313")};
  font-family :${({enable}) => enable == true ? ("Pretendard-SemiBold"):("Pretendard-Regular")};


`

const FilterType = {

}


const PCChatcontainer =({containerStyle}) =>  {

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [unreadview, setUnreadview] = useState(false);
  const [useritems, setUseritems] = useState([]);
  const [chatitems, setChatitems] = useState([]);
  const [currentloading, setCurrentloading] = useState(true);
  const [switchloading, setSwitchloading] = useState(false);

  const [allselect, setAllselect] = useState(true);
  const [ownerselect, setOwnerselect] = useState(false);
  const [supporterselect, setSupporterselect] = useState(false);
  const [unreadselect, setUnreadselect] = useState(false);

  const [item, setItem] = useState({});
  const [owner, setOwner] = useState(false);
  const [name, setName] = useState('');
  const [leftimage, setLeftimage] = useState('');
  const [leftname, setLeftname] = useState('');
  const [index, setIndex] = useState(0);



  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setUnreadview(unreadview);
    setUseritems(useritems);
    setChatitems(chatitems);
    setCurrentloading(currentloading);
    setAllselect(allselect);
    setOwnerselect(ownerselect);
    setSupporterselect(supporterselect);
    setUnreadselect(unreadselect);
    setSwitchloading(switchloading);

    setItem(item);
    setOwner(owner);
    setName(name);
    setLeftimage(leftimage);
    setLeftname(leftname);
    setIndex(index);

  },[refresh])

  const _handleUnread = (unread) =>{
    setUnreadview(unread);
  }


  function getSelectChat(index_){

    if(index_ == index){
      return true;
    }else{
      return false;
    }
 
  }

  useEffect(()=>{

    async function FetchData(){

      const USERS_ID = user.users_id;

      if(USERS_ID == ''){
        navigate("/PClogin");
        return;
      }


      const items = await ReadChat({USERS_ID});
  
    
      let chatitems =[];
      if(allselect == true){
        chatitems = items;
      }else if(ownerselect == true){
        items.map((data)=>{
          if(data.OWNER_ID == user.users_id){
            chatitems.push(data);
          }
        })
      }else if(supporterselect == true){
        items.map((data)=>{
          if(data.SUPPORTER_ID == user.users_id){
            chatitems.push(data);
          }
        })
      }else if(unreadselect == true){
        items.map((data)=>{
          if(data.unReadcount > 0){
            chatitems.push(data);
          }
        })
      }
  
      setChatitems(chatitems);

      setRefresh((refresh) => refresh +1);
    }
    FetchData();
  })
  async function FetchData(){
    const users = await readuser();
    setUseritems(users);
    const USERS_ID = user.users_id;

    const items = await ReadChat({USERS_ID});

    let chatitems =[];
    if(allselect == true){

      
      chatitems = items;
    }else if(ownerselect == true){

      items.map((data)=>{
        if(data.OWNER_ID == user.users_id){
          chatitems.push(data);
        }
      })
    }else if(supporterselect ==true){

      items.map((data)=>{
        if(data.SUPPORTER_ID == user.users_id){
          chatitems.push(data);
        }
      })
    }else if(unreadselect == true){

      items.map((data)=>{
        if(data.unReadcount > 0){
          chatitems.push(data);
        }
      })
    }

 
    if(chatitems.length != 0){
      setChatitems(chatitems);
      setItem(chatitems[0]);
      setOwner(chatitems[0].OWNER_ID == user.users_id);

      if(chatitems[0].OWNER_ID == user.users_id){

        setLeftimage(chatitems[0].SUPPORTER.USERINFO.userimg);
        setLeftname(chatitems[0].SUPPORTER.USERINFO.nickname);
        setName(chatitems[0].OWNER.USERINFO.nickname);
   
      }else{
  

        setLeftimage(chatitems[0].OWNER.USERINFO.userimg);
        setLeftname(chatitems[0].OWNER.USERINFO.nickname);
        setName(chatitems[0].SUPPORTER.USERINFO.nickname);
  
      }

    }

    setCurrentloading(false);

    setRefresh((refresh) => refresh +1);

  
  }
  useEffect(()=>{

    FetchData();
  }, [])

  const _handleChatFilter = (filter)=>{
    if(filter == CHATSELECTFILTER.ALL){

      setAllselect(true);
      setOwnerselect(false);
      setSupporterselect(false);
      setUnreadselect(false);
      FetchData();
      

    }else if(filter == CHATSELECTFILTER.OWNER){

      setAllselect(false);
      setOwnerselect(true);
      setSupporterselect(false);
      setUnreadselect(false);
      FetchData();

    }else if(filter == CHATSELECTFILTER.SUPPORT){
     
      setAllselect(false);
      setOwnerselect(false);
      setSupporterselect(true);
      setUnreadselect(false);
      FetchData();

    }else if(filter == CHATSELECTFILTER.UNREAD){
      setAllselect(false);
      setOwnerselect(false);
      setSupporterselect(false);
      setUnreadselect(true);
      FetchData();
    }

    setRefresh((refresh) => refresh +1);
  }

  const callback=async (item, owner, name, leftimage, leftname, index)=>{
  
    console.log("TCL: callback -> item", item)

    setSwitchloading(true);
    setRefresh((refresh) => refresh +1);
    await sleep(500);
    setItem(item);
    setOwner(owner);
    setName(name);
    setLeftimage(leftimage);
    setLeftname(leftname);
    setIndex(index);
    setSwitchloading(false);
    setRefresh((refresh) => refresh +1);
  }

  function getcontent(contentitem){
  


    if(contentitem.message == -1 || contentitem.message == undefined) 
    {
      if(user.users_id == contentitem.OWNER.USERINFO.users_id){

        if(contentitem.TYPE == PCMAINMENU.HOMEMENU){
          return contentitem.INFO.WORKTYPE + "에" +" "+ leftname +"님 이 지원하였습니다";
        }else{
          return contentitem.INFO.ROOMTYPE + "에" +" "+ leftname +"님 이 지원하였습니다";
        }
  
      }else{
  
        // 주인이 아니라면
        if(contentitem.TYPE == PCMAINMENU.HOMEMENU){
          return contentitem.INFO.WORKTYPE + "에 지원하였습니다";
        }else{
          return contentitem.INFO.ROOMTYPE + "에 지원하였습니다";
        }
  
  
  
      }
    }else{
      if(contentitem.message.CHAT_CONTENT_TYPE  == CHATCONTENTTYPE.RIGHTSIGN
        || contentitem.message.CHAT_CONTENT_TYPE  == CHATCONTENTTYPE.PURCHASE
        || contentitem.message.CHAT_CONTENT_TYPE  == CHATCONTENTTYPE.COMPLETE
        || contentitem.message.CHAT_CONTENT_TYPE  == CHATCONTENTTYPE.REVIEW
        || contentitem.message.CHAT_CONTENT_TYPE  == CHATCONTENTTYPE.LEFTSIGN ){
          return contentitem.message.TEXT[0];
        }else{
          return contentitem.message.TEXT;
        }
    }


  }

  function getUnreadcount(item){
    return item.unReadcount;
  }

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://honglady.com/PCRoom",
    "name": "구해줘 홍여사 계약하기",
 
  };

  return (
    <Container style={containerStyle}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

    {
      currentloading == true ? (<LottieAnimation
      containerStyle={LoadingChatAnimationStyle} animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}/>):(  <Row style={{width:"70%", height:"100vh", margin:"0 auto", background:"#fff"}}>
        <Column style={{background:"#fff", width:"38%", height:"100vh",justifyContent:"unset", borderRight:"1px solid #ededed"}}>
          <ReadAlertLayout>
            <Row style={{paddingLeft:10}}>
            <FilterLayer onClick={()=>{_handleChatFilter(CHATSELECTFILTER.ALL)}} enable ={allselect}>{CHATSELECTFILTER.ALL}</FilterLayer>
            <FilterLayer onClick={()=>{_handleChatFilter(CHATSELECTFILTER.OWNER)}}  enable ={ownerselect}>{CHATSELECTFILTER.OWNER}</FilterLayer>
            <FilterLayer onClick={()=>{_handleChatFilter(CHATSELECTFILTER.SUPPORT)}}  enable ={supporterselect}>{CHATSELECTFILTER.SUPPORT}</FilterLayer>
            <FilterLayer onClick={()=>{_handleChatFilter(CHATSELECTFILTER.UNREAD)}}  enable ={unreadselect}>{CHATSELECTFILTER.UNREAD}</FilterLayer>
            </Row>

          </ReadAlertLayout>

          <>
          {
            chatitems.length != 0 ? (
            <>
              {
                chatitems.map((item, index)=>(
                  <Chatgate  item={item} index={index} callback={callback} 
                  unReadcount ={item.unReadcount}
                  content={getcontent(item)} select ={getSelectChat(index)}/>
                  
                ))
              }
            </> 
          ):(
          <Column style={{paddingTop:200}}>
            <div style={{fontFamily:'Pretendard-Bold', fontSize:20, marginBottom:15}}>채팅 목록이 비어 있어요.</div>
            <div style={{color:"#acacac"}}>일감지원 및 공간대여 지원으로</div>
            <div style={{color:"#acacac"}}>채팅을 시작해보세요.</div>
          </Column>
  
          )
          }
          </>
      
       
   
        </Column>

        {
            chatitems.length == 0 ? (
            <Column style={{background:"#fff", width:"62%",alignItems:"unset"}}>
            <Emptychat content={'대화내역이 없습니다'} height={100}/>
            </Column>)
            :(        
            <Column style={{background:"#fff", width:"62%", height:"100vh",alignItems:"unset"}}>
            {
              switchloading == true ? (<LottieAnimation
              containerStyle={LoadingChat3AnimationStyle} animationData={imageDB.loadinglarge}
              width={"100px"} height={'100px'}/>) :(<>
            {
              (name != '' && item.TYPE== PCMAINMENU.HOMEMENU) ? (<PCWorkContentcontainer 
                REFRESH={chatitems.length == 0}
                ITEM={item} OWNER={owner} NAME= {name} LEFTIMAGE={leftimage} LEFTNAME={leftname}/>)
              :('')
            }   
            {
              (name != ''&& item.TYPE== PCMAINMENU.ROOMMENU) ?(<PCRoomContentcontainer 
                REFRESH={chatitems.length == 0}
                ITEM={item} OWNER={owner} NAME= {name} LEFTIMAGE={leftimage} LEFTNAME={leftname}/>)
              :('')
            }
  
  
             </>)
            }
    
  
       
   
          </Column>)
          
        }


      </Row>)
    }
    </Container>
  );
}

export default PCChatcontainer;

