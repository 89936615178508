export const LIFEMENU={
    TOUR : '국내관광',
    PERFORMANCE : "영화공연",
    FOOD :"식품정보",
    CONVENIENCE : "생활편의",
    MEDICAL : "의료정보",
    WEATHER : "날씨환경",
    BOARD :"나의냉장고",
    RECIPE :"요리레시피",
    LIFEINFORMATION : "생활정보",
    AI :"홍여사 AI",
    PICTURE :"관광지사진",
    WORK :"가사분담",
    YOUTUBE:"유튜브맛집",
    CLIMATE:"날씨정보",
    YOUTUBELIST:"유튜브맛집 리스트",
    RULLET :"룰렛이벤트",
    GAME :"도전홍여사",
    GAMERANK :"도전홍여사 주간순위",
    ATTENDANCE : "출석체크"
}

export const TOURISTMENU ={
    TOURCOURSE : '추천관광코스',
    TOURCOURSEMAP : '추천관광코스 지도',
    TOURCOURSEANALYZE : '추천관광코스 분석',
    TOURREGION : '관광지정보',
    TOURFESTIVAL : '문화축제',
    TOURCOUNTRY : '문화유적',
    TOURPICTURE: '관광지사진',
}
export const PERFORMANCEMENU ={
    PERFORMANCEEVENT : '공연행사',
    PERFORMANCECINEMA : '시설물개방',

}

export const FAMILYMENU ={
    FAMILYABANDON : '유기동물 정보',
    FAMILYLOST : '분실물 정보',
}
export const CONVENIENCEMENU ={
    CONVENIENCECAMPING : '캠핑장정보',
}
export const BOARDMENU ={
    BOARD : '나의냉장고',
}

export const MEDICALMENU ={
    MEDICALMEDICINE : '의약품정보',
    MEDICALHOSPITAL : '의료기관정보',
    FOODINFOMATION: '건강식품',

}

