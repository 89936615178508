import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

import { getVertexAI, getGenerativeModel } from "firebase/vertexai";

export const firebaseConfig = {
    apiKey: "AIzaSyBe1PFtU89t61ULsIPIfowduJyy6PgpFB4",
    authDomain: "help-bbcb5.firebaseapp.com",
    projectId: "help-bbcb5",
    storageBucket: "help-bbcb5.appspot.com",
    messagingSenderId: "78320292657",
    appId: "1:78320292657:web:53aedeeae92644a2da9610",
    measurementId: "G-9NCCYEL925"
};

// Firebase 앱 초기화
const firebaseApp = initializeApp(firebaseConfig);

// 필요한 Firebase 서비스 초기화
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// Initialize the Vertex AI service
const vertexAI = getVertexAI(firebaseApp);

// Initialize the generative model with a model that supports your use case
// Gemini 1.5 models are versatile and can be used with all API capabilities
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });


export { db, auth, storage, firebaseApp, vertexAI, model };


export const googlelocationapiKey = 'AIzaSyCLHECQRnVwQCq3HFj35OQxa5JXjBAs-8Q'; // 여기에 API 키를 입력하세요.
