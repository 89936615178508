
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState, useMemo, Suspense, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../../common/Row";
import { UserContext } from "../../context/User";

import "./mobile.css"
import { imageDB } from "../../utility/imageData";
import LottieAnimation from "../../common/LottieAnimation";

import { Column, FlexstartColumn } from "../../common/Column";
import { DataContext } from "../../context/Data";

import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingCommunityStyle, LoadingSearchAnimationStyle } from "../../screen/css/common";
import { PCCOMMNUNITYMENU } from "../../utility/screen";
import { ReadRECIPE } from "../../service/RecipeService";

import { shuffleArray } from "../../utility/common";
import { getNewDate } from "../../utility/date";
import LazyImage from "../../common/LasyImage";

import { FiPlus } from "react-icons/fi";
import ButtonEx from "../../common/ButtonEx";
import Empty from "./Empty";
import MobileRecipeadd from "../../modal/MobileRecipeadd";
import { ReadFREEZE, UpdateFREEZECHECKid } from "../../service/FreezeService";
import MobileRecipeadjust from "../../modal/MobileRecipeadjust";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyImageex from "../../common/LasyImageex";

import { OrbitControls, TransformControls, useAnimations } from "@react-three/drei";
import { useRef } from "react";




import { useGLTF } from "@react-three/drei";
import { Environment } from '@react-three/drei';

import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import * as THREE from "three";
import { MdDeleteForever } from "react-icons/md";

import { FBXLoader } from "three-stdlib";
import { CiSearch } from "react-icons/ci";
import { animations } from "framer-motion";
import { Text } from '@react-three/drei';



const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;

`
const style = {
  display: "flex"
};



const BoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BoxItem = styled.div`
  width : 49%;
  height : 100%; 
  margin-bottom:40px;

`
const FreezeItem = styled.div`
  width : 100%;
  height : 100%; 
  margin-bottom:40px;
  display : flex;
  flex-direction: row;

`

const FreezeBoxLayer = styled.div`
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FreezeBoxItem = styled.div`
  width : 49%;
  background: ${({enable})=> enable== true ? ('bisque') :('#f9f9f9')};
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction:column;
  border-radius: 5px;
  margin-bottom:5px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}



const RecommendTitle = styled.div`
  font-family: 'Pretendard-Bold';
  margin: 20px 0px;

`
const Recipename = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 14px;
`
const RecommendButton = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 12px;
  color : #999;
`
const AddButton = styled.div`

  font-size: 14px;
  color : #fff;
  background :#ff7e19;
  display:flex;
  padding : 10px;
  border-radius :3px;
  cursor: pointer;
  &:active {
  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`

const LeftButton = styled.div`
  font-size: 14px;
  color : #fff;
  background :#ff7e19;
  display:flex;
  margin-left:10px;
  padding : 10px;
  border-radius :3px;
  cursor: pointer;
  &:active {
  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
`


const Recipetip = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
`
const Recipereview = styled.div`
font-size: 12px;
font-family: 'Pretendard-Light';
margin-top:10px;
`

const Tag1 = styled.div`

font-size: 12px;
background: #fff;
color: #070606;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
`
const Tag2= styled.div`

  font-size: 12px;
  background: #fff;
  color: #070606;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left:5px;
`

const EmptyLine = styled.div`
  height: 2px;
  background: #ededed;
  margin: 20px 0px;

`
const Dday = styled.div`
  font-size: 10px;
  background-color : ${({out}) => out == true ? ('#ff7e19') :('#fff')};
  border-radius: 20px;
  border : ${({out}) => out == true ? ('1px solid #ff7e19') :('1px solid #ff7e19')};
  color : ${({out}) => out == true ? ('#fff') :('#ff7e19')};

  padding: 5px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';
`

const Alarm = styled.div`

  font-size: 10px;
  border: 1px solid #1982ff;
  background-color : #1982ff;
  border-radius: 20px;
  color: #fff;
  padding: 5px 10px;
  margin-left: 10px;
  font-family: 'Pretendard-Bold';


`
const Freezename = styled.div`
  font-size:16px;

`
const Property = styled.div`
  font-size:10px;

`

const MenuButton = styled.div`
  background: ${({enable})=> enable == true ? ('#ff7e19'):('#fff')};
  color: ${({enable})=> enable == true ? ('#fff'):('#131313')};
  padding: 5px;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom:5px;
  font-size:12px;
  width: 50px;
  display: flex;
  justify-content: center;

`
const CheckLayer = styled.div`
  display : flex;
  width :20px;
  height :15px;
`



const FreezeContentDefineItems = [
  {NAME : "", POSITION: [-0.3, 0.5, 0.1], ENDDATE : "" , INDEX :'', STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false },
  {NAME : "", POSITION:[-0.1, 0.5, 0.1], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.1, 0.5, 0.1], ENDDATE : "", INDEX :'' , STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.3, 0.5, 0.1], ENDDATE : "" , INDEX :'', STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.1, 0.27, 0.2], ENDDATE : "", INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false ,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [0.3, 0.27, 0.2], ENDDATE : "", INDEX :'', STARTDATE : "" ,REALNAME:"",ALARM:false ,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.3, 0.05, 0.2], ENDDATE : "" , INDEX :'', STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.1, 0.05, 0.2], ENDDATE : "", INDEX :'' , STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [0.1, 0.05, 0.2], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.3, 0.05, 0.2], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.1, 0.05, 0.2], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [-0.3, 0.05, 0.2], ENDDATE : "", INDEX :''  , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
]

const FreezeContentDefineItems2 = [
  {NAME : "", POSITION: [-0.3, 0.48, 0.2], ENDDATE : "" , INDEX :'', STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false },
  {NAME : "", POSITION:[-0.1, 0.48, 0.2], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.1, 0.48, 0.2], ENDDATE : "", INDEX :'' , STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.3, 0.48, 0.2], ENDDATE : "" , INDEX :'', STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.1, 0.25, 0.3], ENDDATE : "", INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false ,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [0.3, 0.25, 0.3], ENDDATE : "", INDEX :'', STARTDATE : "" ,REALNAME:"",ALARM:false ,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.3, 0.03, 0.3], ENDDATE : "" , INDEX :'', STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.1, 0.03, 0.3], ENDDATE : "", INDEX :'' , STARTDATE : "" ,REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [0.1, 0.03, 0.3], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [0.3, 0.03, 0.3], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
  {NAME : "", POSITION: [-0.1, 0.03, 0.3], ENDDATE : "" , INDEX :'' , STARTDATE : "",REALNAME:"" ,ALARM:false,FREEZE_ID:"",check:false  },
  {NAME : "", POSITION: [-0.3, 0.03, 0.3], ENDDATE : "", INDEX :''  , STARTDATE : "",REALNAME:"",ALARM:false,FREEZE_ID:"",check:false   },
]


const MobileFreezeBoard =memo(({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */




  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [refresh2, setRefresh2] = useState(1);

  const [loading, setLoading] = useState(true);
 

  const [contentItems, setContentItems] = useState(FreezeContentDefineItems);


  const [recipeaddpopup, setRecipeaddpopup] = useState(false);
  const [recipeadjustpopup, setRecipeadjustpopup] = useState(false);



 
  const [visibleImages, setVisibleImages] = useState(10);


  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setVisibleImages((prevVisibleImages) => prevVisibleImages + 10);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

    setLoading(loading);
    setContentItems(contentItems);
 
    setRecipeaddpopup(recipeaddpopup);
    setRecipeadjustpopup(recipeadjustpopup);

  },[refresh])



  const _handleFreeAdjustClick =()=>{
    setRecipeadjustpopup(true);
    setRefresh((refresh) => refresh +1);
  }

  async function FetchData(type){

 

    const USERS_ID = user.users_id;
    const freezeitemsTmp = await ReadFREEZE({USERS_ID});

    data.freezeitems = FreezeContentDefineItems;
    datadispatch(data);
    

    

    contentItems.map((data)=>{
      data.NAME = '';
    })

    if(freezeitemsTmp != -1){

      freezeitemsTmp.map((data,index = 0)=>{
        console.log("TCL: FetchData -> freezeitemsTmp",data.NAME, freezeitemsTmp);

        if(data.NAME != undefined){
          contentItems[index].NAME = data.NAME;
  
          if(data.ENDDATE != ''){
            contentItems[index].NAME += "\n";
            contentItems[index].NAME += DDayCheck(data.ENDDATE);
            contentItems[index].REALNAME = data.NAME;
  
    
          }
          contentItems[index].ENDDATE = data.ENDDATE;
          contentItems[index].FREEZE_ID = data.FREEZE_ID;
          contentItems[index].STARTDATE = data.STARTDATE; 
          contentItems[index].ALARM = data.ALARM;
        }

      })
      setContentItems(contentItems);
    }


    setLoading(false);
    setRefresh((refresh) => refresh+1);
  }
  
  useEffect(()=>{
    FetchData();
  }, [])

  const _handleRecipeSearch = () =>{

    let filteSearchKeywordAry = [];

    let filterItems= data.freezeitems;

    filterItems.map((data)=>{
      if(data.check == true){
        filteSearchKeywordAry.push(data.REALNAME);
      }
      data.check = false;
    })

    if(filteSearchKeywordAry.length > 2){
      alert("재료는 두개만 선택해 주세요");
      return;
    }
    console.log("TCL: [MobileFreezeBoard] _handleRecipeSearch -> filteSearchKeywordAry", filteSearchKeywordAry);
    navigate("/Mobilefreezerecipe", {state : {filterary: filteSearchKeywordAry, name:"나의 냉장고 레시피"}});

    setRefresh((refresh) => refresh + 1);
  }


  async function RefreshFreezeData(type){

    const USERS_ID = user.users_id;
    const freezeitemsTmp = await ReadFREEZE({USERS_ID});
    console.log("TCL: SelectFreezeData -> freezeitemsTmp", freezeitemsTmp);

    data.freezeitem= {};
    data.freezeitems = FreezeContentDefineItems;
    datadispatch(data);

    FreezeContentDefineItems.map((data)=>{
      data.NAME = '';
    })

    if(freezeitemsTmp != -1){
      freezeitemsTmp.map((data,index)=>{
        FreezeContentDefineItems[index].NAME = data.NAME;

        if(data.ENDDATE != ''){
          FreezeContentDefineItems[index].NAME += "\n";
          FreezeContentDefineItems[index].NAME += DDayCheck(data.ENDDATE);
          FreezeContentDefineItems[index].REALNAME = data.NAME;

        }
        FreezeContentDefineItems[index].STARTDATE = data.STARTDATE;
        FreezeContentDefineItems[index].ENDDATE = data.ENDDATE;
        FreezeContentDefineItems[index].FREEZE_ID = data.FREEZE_ID;
        FreezeContentDefineItems[index].ALARM = data.ALARM;

      })
    }
    setContentItems(FreezeContentDefineItems);


    setLoading(false);
    setRefresh((refresh) => refresh+1);
  }


  function DDayCheck (lastdate){
    const today = new Date();
    const targetDate = new Date(lastdate); // 목표 날짜를 지정하세요
    
    // 두 날짜의 차이를 밀리초 단위로 계산
    const diffInTime = targetDate.getTime() - today.getTime();
    
    // 밀리초를 일(day)로 변환
    const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

    if(diffInDays < 0){
      return diffInDays * -1 + "일지남";
    }else{
      return "D - " +diffInDays +"일";
    }

  }


  const MobileRecipeAddCallback = () =>{
    setRecipeaddpopup(false);
    RefreshFreezeData();
    setRefresh((refresh) => refresh +1);
  }

  const MobileRecipeAdjustCallback = () =>{
    setRecipeadjustpopup(false);
    RefreshFreezeData();
    setRefresh((refresh) => refresh +1); 
  }
  const _handleFilterAdd = (dataitem, type) => {
    console.log("MATRIX LOG : MobileFreezeBoard : type:", type)
    console.log("MATRIX LOG : MobileFreezeBoard : dataitem:", dataitem)
    let filterItems= data.freezeitems;
    console.log("MATRIX LOG : MobileFreezeBoard : filterItems:", filterItems)

    if(type == true){
      const FindIndex = filterItems.findIndex(x=>x.REALNAME == dataitem);
      filterItems[FindIndex].check =true;
       
      data.freezeitem= filterItems[FindIndex];
    }else{
      const FindIndex = filterItems.findIndex(x=>x.REALNAME == dataitem);
      filterItems[FindIndex].check =false;
      data.freezeitem= {};
    }



    data.freezeitems = filterItems;

    datadispatch(data);





    
  }

  const _handleadd = () =>{

    let Freezecount = 0;
    contentItems.map((data)=>{
      if(data.NAME != ''){
        Freezecount++;
      }
    })
    if(Freezecount ==12){
      alert("냉장고가 꽉 찾습니다");
      return;
    }
    setRecipeaddpopup(true);
    setRefresh((refresh) => refresh +1);
  }


  const Fridge = () => {
 
    const {animations , scene } = useGLTF("/models/fridge.glb");
    const { actions, names } = useAnimations(animations, scene);  // 애니메이션 가져오기
    const [clicked, setClicked] = useState(false);  // 클릭 상태 관리

    const actionRef = useRef(null);

    useEffect(()=>{
      setClicked(clicked);
    },[refresh])

    useEffect(() => {
      if (animations.length > 0) {
        actionRef.current = actions['Animation']; // 특정 애니메이션 이름 사용
        actionRef.current.setLoop(THREE.LoopOnce);    // 애니메이션 한 번만 재생
        actionRef.current.clampWhenFinished = true;   // 마지막 프레임에서 멈춤
        actionRef.current.play(); 
        actionRef.current.timeScale = 0.3;           // 속도 조절 (0.3배 느리게)
  
      }
    }, [animations]);

  

    return (

      <group>
        {/* 냉장고 외형 */}
        <primitive object={scene} position={[0, -1.15, 0]} />

      </group>
    );
  };

  function Model({ url, position }) {
    const { scene } = useGLTF(url);
    return (
      <>
          <primitive object={scene} scale={0.4} position={position} />
          {/* <Text text="Hello World" fontSize={0.5} color="white" /> */}
          
      </>


    )
  }

  function TextInScene({position, content}) {

    const [color, setColor] = useState("black");
    const [refresh, setRefresh] = useState(1);

    useEffect(()=>{
       setColor(color);
    }, [refresh])

    const _handleClick = () =>{

      let freezeitems = data.freezeitems;

      const lines = content.split('\n');
      const FindIndex = freezeitems.findIndex(x=>x.REALNAME == lines[0]);

      if(color == 'black'){
        freezeitems[FindIndex].check = true;       
      
        setColor("red");   
      }else{
        freezeitems[FindIndex].check =false;

        setColor("black");
      }

      data.freezeitems = freezeitems;
      datadispatch(data);

      setRefresh((refresh) => refresh +1);
    }
    return (
      <Text
        position={position} // 위치 조정
        fontSize={0.025}       // 글자 크기
        color={color}       // 텍스트 색상
        maxWidth={1}         // 최대 텍스트 너비
        onClick={_handleClick}
      >
        {content}
      </Text>
    );
  }


  const BoxWithText = ({content, position,position2, dataItem, callback, index}) => {

    const [color, setColor] = useState("#90775D");

    let freezeitems = data.freezeitems;

    const handleClick = () => {

      const FindIndex = freezeitems.findIndex(x=>x.REALNAME == dataItem.REALNAME);

      console.log("MATRIX LOG : handleClick : freezeitems:", freezeitems[FindIndex])

      if(color == '#90775D'){
 
        
        freezeitems[FindIndex].check = true;       
        data.freezeitem= freezeitems[FindIndex];
        
      }else{

        freezeitems[FindIndex].check =false;

      }

      setColor(color === "#90775D" ? "#ff7e19" : "#90775D");
    
      data.freezeitems = freezeitems;
      datadispatch(data);

      

    };

    const meshRef = useRef();

    const textTexture = useMemo(() => {
      const canvas = document.createElement("canvas");
      canvas.width = 512;
      canvas.height = 512;
      const context = canvas.getContext("2d");
  
      // 배경색
      context.fillStyle = "white";
      context.fillRect(0, 0, canvas.width, canvas.height);
  
      // 글자 스타일
      context.font = "140px Pretendard-SemiBold";

      context.fillStyle = "black";
      context.textAlign = "center";
      context.textBaseline = "middle";




        // 텍스트 줄바꿈 처리
      const lines = content.split('\n');
      const lineHeight = parseInt("80px Pretendard-SemiBold", 10) * 3; // 줄 높이 계산
      const centerY = 512 / 2 - (lines.length * lineHeight) / 2; // 중앙 정렬

      lines.forEach((line, index) => {
        const y = centerY + index * lineHeight +120;
        context.fillText(line, 512 / 2, y);
      });


      // Canvas를 텍스처로 변환
      const texture = new THREE.CanvasTexture(canvas);
      return texture;
    }, []);
  
    return (

        <>

          <TextInScene position={position2} content={content} />

          {index == 0 &&<Model url={"/models/fridgebox1.glb"} position={position} map={textTexture}/>}
          {index == 1 &&<Model url={"/models/fridgebox2.glb"} position={position} map={textTexture}/>}
          {index == 2 &&<Model url={"/models/fridgebox3.glb"} position={position} map={textTexture}/>}
          {index == 3 &&<Model url={"/models/fridgebox4.glb"} position={position} map={textTexture}/>}
          {index == 4 &&<Model url={"/models/fridgebox5.glb"} position={position} map={textTexture}/>}
          {index == 5 &&<Model url={"/models/fridgebox6.glb"} position={position} map={textTexture}/>}
          {index == 6 &&<Model url={"/models/fridgebox7.glb"} position={position} map={textTexture}/>}
          {index == 7 &&<Model url={"/models/fridgebox8.glb"} position={position} map={textTexture}/>}
          {index == 8 &&<Model url={"/models/fridgebox9.glb"} position={position} map={textTexture}/>}
          {index == 9 &&<Model url={"/models/fridgebox10.glb"} position={position} map={textTexture}/>}
        </>


 


    );
  };



  

  const FridgeScene = memo(({}) => {

    const controlsRef = useRef();

 
    return (

      <Canvas 
      dpr={[1, 2]} // 디스플레이 해상도 최적화
      frameloop="demands" // 필요할 때만 렌더링
      gl={{ antialias: true }} // 부드러운 렌더링
      orthographic // 정사각형 카메라 사용
      camera={{
        zoom: 250, // 줌 레벨 고정
        position: [0, 0, 8], // 카메라 위치 (냉장고 앞)
        near: 1,
        far: 300,
      }}
     
      style={{background: "#fff",}}
      >

      {/* 환경 조명: 부드러운 배경광 */}
      {/* <ambientLight intensity={0.1} /> */}


      <Environment files="/models/venice_sunset_1k.hdr" />

      {/* 외부에서 비추는 방향광 */}
      {/* <directionalLight position={[-2, 2, 2]} // 냉장고를 대각선 위에서 비추기
        target-position={[0, 0, 0]} // 냉장고 중심을 타겟
        intensity={5} // 밝기
        color="#ffffff" // 빛 색깔
      />
     */}
      {contentItems.map((data, index) =>(
        <>
          {
            data.NAME != '' &&
         
              <>
                <BoxWithText content={data.NAME} position2={FreezeContentDefineItems2[index].POSITION} index={index} position={data.POSITION} dataItem={data} callback={_handleFilterAdd}/>
              </>
          }  
        </>
      ))}
      <Fridge />

      {/* <MovingCamera/> */}
      <OrbitControls ref={controlsRef} enableDamping />


      </Canvas>


    );
  });



  
  return (

    <Container style={containerStyle}>     

    {
      recipeaddpopup == true && <MobileRecipeadd callback={MobileRecipeAddCallback} />
    }

    {
      recipeadjustpopup == true && <MobileRecipeadjust callback={MobileRecipeAdjustCallback} adjustfreezeitem={data.freezeitem} />
    }

    {
      loading == true ? (<LottieAnimation containerStyle={LoadingSearchAnimationStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'}/>)
      :(
        <Column style={{width:"100%",margin:"-100px auto 0px", height:'100vh'}}>

          <Suspense fallback={<div></div>}>

          <FridgeScene/>


          </Suspense>

          <FlexEndRow style={{width:"90%", marginRight:20, marginBottom:20, position:"fixed", bottom:40}}>
  
            <Column>
              <Row style={{width:"100%", margin:"5px 0px 20px"}}>
              <AddButton onClick={_handleadd}>
                  <FiPlus size={18} color={'#fff'}/>
                  <div>재료추가</div>
                </AddButton>

                <LeftButton onClick={_handleFreeAdjustClick}>
                  <MdDeleteForever size={18} color={'#fff'}/>
                  <div>재료수정</div>
                </LeftButton>

                <LeftButton onClick={_handleRecipeSearch}>
                  <CiSearch size={18} color={'#fff'}/>
                  <div>레시피 찾기</div>
                </LeftButton>
              </Row>
            </Column>
          </FlexEndRow>
 
        </Column>)
    }  


    </Container>
  );

});


export default MobileFreezeBoard;

