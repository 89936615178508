import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";
import { CONFIGMOVE, PCCOMMNUNITYMENU, RULLET_TYPE } from "../../../utility/screen";

import { DataContext } from "../../../context/Data";
import { sleep, useSleep } from "../../../utility/common";
import { getDateEx, getDateFullTime, getNewDate } from "../../../utility/date";
import { readuser, Readuserbyusersid, Update_rulletbyusersid } from "../../../service/UserService";
import LottieAnimation from "../../../common/LottieAnimation";
import { LoadingCommunityStyle } from "../../../screen/css/common";
import {motion} from 'framer-motion';
import ButtonEx from "../../../common/ButtonEx";

const Container = styled.div`
 

`
const style = {
  display: "flex"
};

const EventProcessTag = styled.div`
  background: #ff7e19;
  width: 100px;
  display: flex;
  justify-content: center;
  color: #fff;
  height: 30px;
  align-items: center;
  border-radius: 20px;
`

const EventTitle = styled.div`
  font-size: 16px;
  line-height: 40px;
  width : 85%;
  font-family :Pretendard-SemiBold;

`


const Rulletbg = styled.div`
  text-align: center;

  padding-top: 150px;
  width: 90%;
  margin : 20px auto;



`
const ResultLayer = styled.div`

  font-size: 20px;
  color: #3c4cb2;
  font-weight: 700;
  line-height: 30px;
  z-index: 10;
  position: relative;
  top: 220px;
  font-family :Pretendard-SemiBold;
  margin-bottom:150px;
`
const CongratulationLayer = styled.div`

  font-size: 20px;
  color: #3c4cb2;
  font-weight: 700;
  line-height: 30px;
  z-index: 10;
  position: relative;
  top: -100px;
  font-family :Pretendard-SemiBold;
`



const Comment = styled.div`
  font-size: 14px;
  display: flex;
  padding-left: 20px;
  color: #444;
  height: 30px;
  flex-direction: row;
  justify-content: center;
`


const AudioPlayer = ({ condition }) => {
  const [audio] = useState(new Audio("/sounds/rullet.m4a"));

  const handleBeforeUnload = () => {
    audio.pause();
    audio.currentTime = 0;
    // 추가 작업
  };


  useEffect(() => {
    if (condition) {
      audio.play().catch((err) => {
        console.error("Audio playback failed", err);
      });
    } else {
      audio.pause();
      audio.currentTime = 0; // 재생 위치 초기화
    }

    return () => {
      // 컴포넌트가 언마운트될 때 오디오 종료
      audio.pause();
      audio.currentTime = 0;
    };
  }, [condition, audio]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      audio.pause();
      audio.currentTime = 0;
    };


    // 페이지 이동 이벤트 감지
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [audio]);

  useEffect(() => {

    // 오디오가 끝날 때 이벤트 리스너 추가
    const handleEnded = () => {
      audio.currentTime = 0; // 오디오를 처음으로 되돌림
      audio.play(); // 재생 시작
    };

    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);


  return null;
};


const MobileRulletEvent =({containerStyle}) =>  {

  const [playAudio, setPlayAudio] = useState(false);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [state, setState] = useState(-1);
  const [checkstatus, setCheckstatus] = useState(true);
  const [process, setProcess] = useState(false);
  const [futuredate, setFuturedate] = useState('');
  const [checknum, setChecknum] = useState(-1);

  const [currentloading, setCurrentloading] = useState(true);

  var rolLength = 6; // 해당 룰렛 콘텐츠 갯수
  var setNum; // 랜덤숫자 담을 변수
  var hiddenInput = document.createElement("input");
  hiddenInput.className = "hidden-input";


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCheckstatus(checkstatus);
    setState(state);
    setProcess(process);
    setChecknum(checknum);
    setFuturedate(futuredate);
    setCurrentloading(currentloading);
    setPlayAudio(playAudio);
  },[refresh])

  const playFailBellWithVibration = () => {
    const bellSound = new Audio("/sounds/out.m4a");
    bellSound.play();
  
    if (navigator.vibrate) {
      navigator.vibrate(200); // 200ms 진동
    }
  };


  const playSuccessBellWithVibration = () => {
    const bellSound = new Audio("/sounds/good.mp3");
    bellSound.play();
  
    if (navigator.vibrate) {
      navigator.vibrate(200); // 200ms 진동
    }
  };


  /**
   * 초기 페이지 진입시에는 context에 있는 정보를 가져온다
  
   */
  useEffect(()=>{
    async function FetchData(){
      const today = moment(); // 현재 날짜

      //TODO 두시간 경과된 알을 표시해보자
      const futureDate = today.clone().add(1, 'days'); // 1일 후 날짜
      setFuturedate(getNewDate(futureDate));


      const USERS_ID = user.users_id;

      const useritem = await Readuserbyusersid({USERS_ID});
      console.log("TCL: FetchData -> useritem", useritem,getDateEx(today));
      

      //TODO 두시간 경과 여부를 체크하자
      // if(useritem.RULLET == getDateEx(today)){
      //   setCheckstatus(false);
        
      // }
      setCheckstatus(true);
      setCurrentloading(false);
      setRefresh((refresh) => refresh +1);

    } 
  
    FetchData();
  }, [])


  const rRandom = () => {
    var min = Math.ceil(0);
    var max = Math.floor(rolLength - 1);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const rRotate = () => {
    var panel = document.querySelector(".rouletter-wacu");
    var btn = document.querySelector(".rouletter-btn");
    var deg = [];
    // 룰렛 각도 설정(rolLength = 6)
    for (var i = 1, len = rolLength; i <= len; i++) {
      deg.push((360 / len) * i);

    }

    
    console.log("TCL: rRotate -> deg", deg); 
    // 랜덤 생성된 숫자를 히든 인풋에 넣기
    var num = 0;
    document.body.append(hiddenInput);
    // setNum = hiddenInput.value = rRandom();


    let min = 0;
    let max = 10;
    let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("TCL: rRotate -> randomInt", randomInt)

    

    if(randomInt < 2){
      setNum = RULLET_TYPE.POINT_1000;
    }else if(randomInt >=2 && randomInt <5){
      setNum = RULLET_TYPE.ZERO_ONE;
    }else if(randomInt >=5 && randomInt <6){
      setNum = RULLET_TYPE.POINT_1000;
    }else if(randomInt >=6 && randomInt <7){
      setNum = RULLET_TYPE.POINT_5000;
    }else if(randomInt >=7 && randomInt <=10){
      setNum = RULLET_TYPE.ZERO_ONE;
    }


    setChecknum(setNum);

    setRefresh((refresh) => refresh +1);
    
    // 애니설정
    var ani = setInterval(() => {
      num++;
      panel.style.transform = "rotate(" + 360 * num + "deg)";
    //  btn.disabled = true; //button,input
    //  btn.style.pointerEvents = "none"; //a 태그
      
      // 총 50에 다달했을때, 즉 마지막 바퀴를 돌고나서
      if (num === 80) {
        clearInterval(ani);

        panel.style.transform = `rotate(${deg[setNum]}deg)`;
      }
    }, 80);
  };
  
  // 정해진 alert띄우기, custom modal등
  // 3 이면 꽝
  // 1 이면 꽝 
  // 2 이면 5000 포인트
  // 4 이면 스타벅스
  // 5 이면 신세계
  // 6 이면 꽝
  const RLayerPopup = async(num) => {
  
    setPlayAudio(false);
    console.log("TCL: RLayerPopup -> num", num)
    
    switch (num) {
      case 0:
        playSuccessBellWithVibration();
        break;
      case 2:
        playSuccessBellWithVibration();    
        break;
      default:
        playFailBellWithVibration();
        break;
 
    }

    setCheckstatus(false);
 
    setRefresh((refresh) => refresh +1);

    var btn = document.querySelector(".rouletter-btn");
    //btn.disabled = false; //button,input
    const supdate = await useSleep(500);
    // window.scrollTo(0, document.body.scrollHeight);

  };
  


  const _handlestart = async() =>{

    if(process == true){
      return;
    }

    const USERS_ID = user.users_id;
    const today = moment(); // 현재 날짜
    const RULLETDATE = getDateFullTime(today);  

    Update_rulletbyusersid({RULLETDATE, USERS_ID});

    setPlayAudio(true);
    setProcess(true);
    setRefresh((refresh) => refresh +1);
  
    rRotate();

    const update = await sleep(8500);

    RLayerPopup(setNum);




  }

  const _handlePoint =()=>{

    navigate("/Mobileconfigcontent",{state :{NAME :CONFIGMOVE.POINTCONFIG, TYPE : ""}});

  }



  return (
    <Container style={containerStyle}>
      {currentloading == true ? (<LottieAnimation containerStyle={LoadingCommunityStyle} animationData={imageDB.loadinglarge}
      width={"100px"} height={'100px'}/>):(     <Column margin={'0px auto;'} width={'100%'} style={{background:"#fff"}} >
      <EventTitle>
 
        <BetweenRow>
          <EventTitle style={{textAlign: "left"}}>{'두시간마다 진행할수 있는 룰렛 게임'}</EventTitle>
        </BetweenRow>

      </EventTitle>


      <Rulletbg>
          <div class="rouletter">
            <div class="rouletter-mobilebg">
              <div class="rouletter-wacu"></div>
          </div>
          <div class="rouletter-mobilearrow"><img src = {imageDB.rulletpin} style={{width:40}}/></div>
          {
            checkstatus == true ? (<div class="rouletter-mobilebtn" onClick={_handlestart}><img src = {imageDB.rulletstart} style={{width:100}}/></div>) 
            :(<div class="rouletter-mobilebtn" ><img src = {imageDB.rulletstop} style={{width:100}}/></div>)
          }
          
        </div>
        {
          checkstatus == false && <ResultLayer>
            {
              checknum == RULLET_TYPE.ZERO_ONE && <>
                <div>아쉽네여 다음엔 좋은 결과가 있을거에여</div>
                <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
                <Comment>다음 룰렛은  {futuredate} 다시 진행 가능합니다.</Comment>
                </>
            }

            {
              (checknum == RULLET_TYPE.POINT_1000 ||
              checknum == RULLET_TYPE.POINT_5000 )
              && <>
              <div>축하 합니다. 경품에 당첨 되었습니다</div>
              <Comment>당첨 내용은 내 포인트 내역에서 확인 할수 있습니다</Comment>
              <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
              <Comment>다음 룰렛은  {futuredate} 다시 진행 가능합니다.</Comment>
              <ButtonEx text={'포인트 보러가기'} width={'85'}  
                  onPress={_handlePoint} bgcolor={'#fff'} color={'#3a3636'} containerStyle={{fontFamily:"Pretendard-Regular",
                  border :"1px solid",marginTop:20,boxShadow:"none"}} />
            </>

            }

            {
                checknum == -1 && <>
                  <Comment>룰렛은 하루에 한번 진행 할수 있습니다.</Comment>
                  <Comment>다음 룰렛은  {futuredate} 다시 진행 가능합니다.</Comment>
                  </>
              }
        
          </ResultLayer>
        }

        {
          (checkstatus == false &&  (checknum == RULLET_TYPE.POINT_1000 ||
            checknum == RULLET_TYPE.POINT_5000 ) )&& <CongratulationLayer>
           <motion.div animate={{ scale: [1, 3, 0] }} transition={{ duration: 5 }}>
                <img src={imageDB.congratulation} style={{width:100}}/>
              </motion.div>
          </CongratulationLayer>
        }

      </Rulletbg>

      <AudioPlayer condition={playAudio} />    

    </Column>)

      }
 


    </Container>
  );

}

export default MobileRulletEvent;

