import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import PcAdvertisePopup from "../../modal/PcAdvertisePopup/PcAdvertisePopup";

import StoreInfo from "../../components/StoreInfo";
import { DataContext } from "../../context/Data";


import { ReadWork } from "../../service/WorkService";
import { BetweenRow, FlexstartRow, Row } from "../../common/Row";
import Loading from "../../components/Loading";
import { CONFIGMOVE, FILTERITMETYPE, LoadingType, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { WORKNAME } from "../../utility/work";
import { useSelector } from "react-redux";
import { Column, FlexstartColumn } from "../../common/Column";
import MobileWorkItem from "../../components/MobileWorkItem";
import Label from "../../common/Label";
import { GoNoEntry } from "react-icons/go";

import "./MobileMaincontainer.css";
import "./MobileCommunitycontainer.css";
import MobileStoreInfo from "../../components/MobileStoreInfo";
import { ROOMSIZE } from "../../utility/room";
import { ReadRoom } from "../../service/RoomService";
import PCRoomItem from "../../components/PCRoomItem";
import MobileRoomItem from "../../components/MobileRoomItem";
import LottieAnimation from "../../common/LottieAnimation";
import { useSleep } from "../../utility/common";
import { CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../../utility/life";
import MobileTourLife from "../../components/MobileLifeTour";
import MobilePerformanceLife from "../../components/MobileLifePerformance";
import MobileMedicalLife from "../../components/MobileLifeMedical";
import MobileLifePerformance from "../../components/MobileLifePerformance";
import MobileLifeMedical from "../../components/MobileLifeMedical";
import MobileLifeTour from "../../components/MobileLifeTour";
import MobileLifeFamily from "../../components/MobileLifeFamily";
import MobileLifeConvenience from "../../components/MobileLifeConvenience";


const Container = styled.div`
padding-top:55px;
background-color : #fff;
height: calc(100vh - 50px);
scrollbar-width: none; // 스크롤바 안보이게 하기
overflow-x: hidden; /* X축 스크롤을 숨깁니다. */
`


const style = {
  display: "flex"
};

const FlexMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
  margin:0px auto;
  justify-content: start;
  gap: 5px; /* 요소들 사이에 일정 간격 추가 */

`

const MassBox = styled.div`

  background: ${({bgcolor}) => bgcolor};
  color :  #131313;
  font-size : 13px;
  font-family : 'Pretendard-Regular';
  font-weight:500;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width:${({clickstatus}) => clickstatus == true ? ('88%') :('88%')};
  margin-right: 2px;
  z-index: 2;
  overflow-x: auto;
  flex: 0 0 auto;
  margin-left: 2%;
  margin-bottom: 5px;

  border-radius: 10px;
  padding :10px 10px;

  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`
const MassBox2 = styled.div`

  background: ${({bgcolor}) => bgcolor};
  color :  #131313;
  font-size : 13px;
  font-family : 'Pretendard-Regular';
  font-weight:500;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width:${({clickstatus}) => clickstatus == true ? ('88%') :('88%')};
  margin-right: 2px;
  z-index: 2;
  overflow-x: auto;
  flex: 0 0 auto;
  margin-left: 2%;
  margin-bottom: 5px;

  border-radius: 10px;
  padding :10px 10px;

  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const Box = styled.div`

  background: ${({bgcolor}) => bgcolor};
  color :  #131313;
  font-size : 13px;
  font-family : 'Pretendard-Regular';
  font-weight:500;
  border :  ${({clickstatus}) => clickstatus == true ? ('1px solid #F9F9F9') :(null)};
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width:${({clickstatus}) => clickstatus == true ? ('21.5%') :('21.5%')};
  margin-right: 2px;
  z-index: 2;
  overflow-x: auto;
  flex: 0 0 auto;
  margin-left: 2%;
  margin-bottom: 5px;
  margin-top:10px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding :10px 10px;

  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;

  &:active {
    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }


`

const Guide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Pretendard-SemiBold;
  width: 100%;
  align-items: center;
  padding-left: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size:19px;
`

const EmptyLine = styled.div`

  height: 10px;
  margin : 30px 0px 20px;
  background : #ededed;
  width :100%;

`
  // {name : TOURISTMENU.TOURPICTURE ,img :imageDB.tour},
const CommunityItems =[
  {name : LIFEMENU.AI, img : imageDB.teacher, background :'#f9f9f9', desc:"구해줘 홍여사로 물어보고 카테고리를 만들어 나만의 지식창고 생성"},
  {name : LIFEMENU.BOARD, img : imageDB.freeze, background:'#f9f9f9', desc:"냉장고 식재료관리 유효기간이 다가오면 알람과 레시피 추천"},


  {name : LIFEMENU.WORK, img : imageDB.house, background:'#f9f9f9', desc:"독박 가사는 이제 끝! 모든 가사는 가족과 함께 가사 분담 서비스"},
  // {name : LIFEMENU.YOUTUBE, img : imageDB.youtube, background:'#f9f9f9', desc:"인기 맛집 유투브 에서 소개 되는 지역 기반 맛집 소개 영상 제공"},
  {name : LIFEMENU.CLIMATE, img : imageDB.littlecloud, background:'#f9f9f9', desc:"일일 날씨 및 주간 날씨  현재 기온, 습도, 하늘상태등 실시간 정보를 제공"},
]

const CommunityItems2 =[
  {name : LIFEMENU.RECIPE, img : imageDB.recipe, background:'#f9f9f9'},
  {name : MEDICALMENU.MEDICALMEDICINE, img : imageDB.medical, background:'#f9f9f9'},
  {name : MEDICALMENU.FOODINFOMATION, img : imageDB.healthfood, background:'#f9f9f9'},

]

const CommunityTourItems =[
  {name : TOURISTMENU.TOURCOURSE ,img :imageDB.tourcourse, background:'#f9f9f9', desc:"구해줘 홍여사 AI가 추천드리는 전국관광코스 / 코스별 이동경로 제공"},

  {name : TOURISTMENU.TOURREGION ,img :imageDB.tour, background:'#f9f9f9', desc:"한국 관광공사에 지정된 국내 아름다운 관광지에 대한 세부적인 정보 제공"},
  {name : LIFEMENU.PICTURE, img : imageDB.convenience, background:'#f9f9f9', desc:"한국의 특별한 순간, 사진에 담다 \n대자연의 영원한 풍경\n자연과 사람이 공존하는 이야기"},
  // {name : PERFORMANCEMENU.PERFORMANCEEVENT, img : imageDB.performance, background:'#f9f9f9', desc:"공연 문화예술 할인 예매 정보보고 공연을 쉽게 즐겨보세요"},

]
const CommunityTourItems2 =[
  {name : CONVENIENCEMENU.CONVENIENCECAMPING, img : imageDB.camping, background:'#f9f9f9', desc:"전국캠핑장 정보를 한번에 확인 할수 있도록 제공됨"},
  {name : TOURISTMENU.TOURFESTIVAL ,img :imageDB.tourfestival, background:'#f9f9f9', desc:"전국문화축제주관, 장소, 일시에 대한 정보를 한눈에 볼수 있도록 정리 "},
  {name : PERFORMANCEMENU.PERFORMANCECINEMA, img : imageDB.schoolevent, background:'#f9f9f9'},


 
]
const CommunityEventItems =[
  {name : LIFEMENU.GAME, img : imageDB.challenge, background :'#fff1c6', desc:"게임 한번에 100 포인트 제공 최고기록 달성시 50000포인트 제공"},
  {name : CONFIGMOVE.RULLET, img : imageDB.rullet, background :'#fff1c6', desc:"두시간 마다 한번 제공되는 룰렛 이벤트, 이벤트 참여하여 한번 푸짐한 선물을 받아가세요"},
  {name : LIFEMENU.ATTENDANCE, img : imageDB.attendance, background :'#fff1c6', desc:"꾸준한 구해줘 홍여사 가족임을 축하드립니다. 출석도장 15번 만으로 5000포인트 제공"},
]




/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;




/**
 * 메인 데이타페이지는 
 * ! currentloading이 false 상태 일때만 보여준다
 * TODO 로딩 타입
 * ① 지역설정 타입 currentloading
 */

const MobileLeisurecontainer =({containerStyle}) =>  {

  const {value} = useSelector((state)=> state.menu);

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [currentloading, setCurrentloading] = useState(true);
  const [menu, setMenu] = useState(LIFEMENU.TOUR);


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setCurrentloading(currentloading);
    setMenu(menu);

  },[refresh])

  /**

   */
  useEffect(()=>{
    const now = moment();
 
    async function FetchData(){

      await useSleep(1000);
      setCurrentloading(false);
    } 
    FetchData();

  }, [])

  const _handlemenu= (menu)=>{

    
    setMenu(menu);

    navigate("/Mobilecommunitycontent" ,{state :{name :menu}});



    setRefresh((refresh) => refresh +1);
  }



  return (
    <>

    {

      <Container style={containerStyle}>
  
        <Column style={{marginBottom:30}}>


            <div style={{padding:'0px 15px'}}>
              <Guide>
                <div>행복한 <span style={{color:"#0076CE"}}>여가생활</span>을 위한 정보</div>
              </Guide>      
              <FlexMenu>
                {
                  CommunityTourItems.map((data, index)=>(
                    <MassBox onClick={()=>{_handlemenu(data.name)}} clickstatus={menu == data.name} bgcolor = {data.background} >
                      <img src={data.img} width={50}/>
                      <FlexstartColumn style={{paddingLeft:20,lineHeight:1.5}}>
                      <div style={{ marginTop:10, fontSize:16, color:"#131313", fontFamily:"Pretendard-SemiBold"}}>{data.name}</div>
                      <div style={{ fontSize:14, color:"#777",whiteSpace: "break-spaces"}}>{data.desc}</div>
                      </FlexstartColumn>
         
                    </MassBox>
                  ))
                }
                {
                  CommunityTourItems2.map((data, index)=>(
                    <Box onClick={()=>{_handlemenu(data.name)}} clickstatus={menu == data.name}
                    bgcolor = {data.background} >
                      <img src={data.img} width={50}/>
                      <div style={{ marginTop:10, fontSize:14, color:"#131313", fontFamily:"Pretendard-SemiBold"}}>{data.name}</div>
                    </Box>
                  ))
                }
              </FlexMenu>
            </div>
            

        


        </Column>

        {/* <MobileStoreInfo height={200} containerStyle={{marginBottom:50}} /> */}
      </Container>
    }


    </>


  );

}

export default MobileLeisurecontainer;

