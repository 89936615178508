import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { Column } from "../common/Column";
import LazyImage from "../common/LasyImage";
import { UserContext } from "../context/User";
import { getDateFullTime } from "../utility/date";
import { REQUESTINFO, WORKNAME } from "../utility/work";
import { Seekimage } from "../utility/imageData";
import { FlexstartRow, Row } from "../common/Row";




const Container = styled.div`
  padding: 20px 5px;
`
const style = {
  display: "flex"
};


const ItemLayer = styled.div`
  display:flex;
  flex-direction :row;
  justify-content: center;
  align-items:center;
  width:100%; 
  flex-wrap:wrap;
`

const Item = styled.div`
  display:flex;
  flex-direction :column;
  justify-content: center;
  align-items:flex-start;
  width:${({width}) => width}; 
  height:46px;
  margin-top:10px;

`
const FullItem = styled.div`
  display:flex;
  flex-direction :column;
  justify-content: center;
  align-items:flex-start;
  width:100%; 
  height:46px;

  margin-top:10px;

`

const ItemLabel = styled.div`

  color :#131313;
  font-weight : 700;
  font-size :14px;
  line-height:18.2px;
`
const ItemContent = styled.div`
  color :#636363;
  font-weight: 400;
  font-size:15px;
  line-height: 21.8px;
`


const Requestdoc =({containerStyle,INFO, TYPE, NICKNAME}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);


 
  return (

    <Container style={containerStyle}>

        <label style={{paddingLeft:10, fontFamily:"Pretendard-SemiBold", fontSize:'16px'}}>일감 상세 정보</label>
        <table class="pcworkreport-table" style={{  margin: '10px auto', borderTop: "1px solid #434343"}}>      
          <tbody>
            <tr>
              <td>타입</td>
              <td>
                <FlexstartRow>       
                  <img src={Seekimage(TYPE)} style={{width:40}}/>
                  <div>{TYPE}</div>
                </FlexstartRow>
              </td>
            </tr>
            <tr>
              <td>일감등록한 사람</td>
              <td>{NICKNAME}</td>
            </tr>

            {
              INFO.map((data)=>(
                <>
                {
                data.type =='response' &&
                <tr>
                <td>{data.requesttype}</td>
                <td>
                 <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                 {

                    data.requesttype == REQUESTINFO.ROOM ? (
                      // <img src= {data.result} style={{width:"200px", objectFit:"contain"}}/>

                      <LazyImage src={data.result} containerStyle={{width:'200px',objectFit:"contain" }}/>

                    ) :(
                      <>
                      {
                            (data.requesttype == REQUESTINFO.COMMENT && data.result =='') ? (
                              <div> {'별도 요구사항 없음'}</div>):(
                            <div>
                              {data.requesttype == REQUESTINFO.REQDATE ? (<div>{getDateFullTime(data.result)}</div>) :
                              (<div>{data.result}</div>)
                              }
                            </div>)
                      }
                      </>
                    )
                  }  
                  </div>
                </td>
                </tr>
                }
                </>                  
              ))
            }
          </tbody>
           </table>

    </Container>
  );

}

export default Requestdoc;

