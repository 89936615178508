import React, {memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";
import { Column, FlexstartColumn } from "../common/Column";
import { imageDB } from "../utility/imageData";
import ButtonEx from "../common/ButtonEx";
import "../screen/css/common.css"
import LottieAnimation from "../common/LottieAnimation";


const Container = styled.div`
  width:100%;

`


const PCLifeFreezeBoard = memo(({containerStyle, data}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [number, setNumber] = useState(1);
  const [currentloading, setCurrentloading] = useState(true);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

    setCurrentloading(currentloading);
  }, [refresh])

  async function FetchData(){
       
   
  }

  useEffect(()=>{
  
      FetchData();
  }, [])
  
  const _handleFreeze = ()=>{
    navigate("/PClogin");
  }
 


  return (

    <Container style={containerStyle}>

        <Column style={{alignItems:"flex-start",width:"100%"}}>
     
        <FlexstartColumn style={{justifyContent:"flex-start",alignItems:"flex-start", marginTop:35, width:"100%"}}>


        <video class="background-video2"  autoPlay  controls  loop 
         preload="metadata"
         poster={imageDB.introducethumnail4} // 미리보기 이미지 경로
         >
        <source src={imageDB.introduce4}  type="video/mp4" />
        </video> 

        <ButtonEx text={"나의 냉장고 관리하기"} onPress={_handleFreeze}
        containerStyle={{backgroundColor: "#ff7e19",color :"#fff",borderRadius: "4px", border:"none",boxShadow:"none",
        fontSize: 24, width: "40%", margin:"40px auto", height:"48px"}}/> 


        </FlexstartColumn>


        </Column>
    </Container>
  );

})

export default PCLifeFreezeBoard;

