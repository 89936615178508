
import { Checkbox, Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { ReadCommunitySummary } from "../service/CommunityService";
import TimeAgo from 'react-timeago';
import { getFullTime } from "../utility/date";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingBoardStyle, LoadingCommunityStyle } from "../screen/css/common";
import BoardBox from "./BoardBox";
import { CONFIGMOVE, PCCOMMNUNITYMENU } from "../utility/screen";
import PCBanner from "./PCBanner";
import { CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../utility/life";
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import "./input.css"
import PCLifeCheck from "./PCLifeCheck";
import { lifemenustore } from "../store/jotai";
import { useAtom } from "jotai";
import PCLifeAI from "./PCLifeAI";
import PCLifeFreezeBoard from "./PCLifeFreezeBoard";
import PCLifeWork from "./PCLifeWork";
import PCLifeClimate from "./PCLifeClimate";
import MobileRecipeBoard from "./MobileRecipeBoard";
import PCRecipeBoard from "./PCRecipeBoard";
import MobileLifeMedicalDrug from "./MobileLifeMedicalDrug";
import MobileLifeFoodDrug from "./MobileLifeFoodDrug";
import SlickSliderComponentEx from "../common/Swipeex";
import PCSlickSliderComponentEx from "../common/PCSwipeex";
import PCLifeMedicalDrug from "./PCLifeMedicalDrug";
import PCLifeFoodDrug from "./PCLifeFoodDrug";
import PCLifeClick from "./PCLifeClick";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  width: 100%;
  background :#fff;



`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '20px',
  border : "1px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  padding: 0px 0px 30px;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  width: 30%;


`
const BoxLabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:16px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:100%;


`

const BoxContent = styled.div`
  font-family: "Pretendard-Regular";
  font-size:14px;

`
const BoxWrite = styled.div`
  display : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items: center;
  width : 100%;
  font-size :14px;
`

const BoxImage = styled.div`
  margin-top:5px;
`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}

const Taglabel = styled.div`
  font-family: "Pretendard";
  font-size:12px;
  margin-right:10px;
  min-width:50px;
  display : flex;
  align-items: center;
  justify-content: center;
  background-color:#FFF5F5;
  color :#FF2121;
  border-radius: 5px;

`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;

  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
const TagButton = styled.div`
    padding: 10px 0px;
    color: #131313;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left:5px;
    width:600px;
`
const EventButton = styled.div`
    padding: 10px 0px;
    color: #131313;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left:5px;
    width:300px;
    background: #ededed;
    display: flex;
    justify-content: center;
`

const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`
const ItemLabel = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 20px;
  margin: 10px 0px;

`
const Line = styled.div`
  height: 1px;
  margin: 20px 0px;
  background: #ededed;
  width: 80%;

`
const InputStyle ={
  display: "flex",
  justifycontent: "flex-start",
  height: "20px"
}

const BannerItems =[

  {type:LIFEMENU.GAME,image : imageDB.challenge, main1:"도전 구해줘 홍여사", main2:"게임 한번에 100 포인트 제공 최고기록 달성시 50000포인트 제공", main3:"", color:"#fff1c6"},
  {type:LIFEMENU.RULLET, image : imageDB.rullet, main1:"2시간마다 진행되는 룰렛게임", main2:"두시간 마다 한번 제공되는 룰렛 이벤트, 이벤트 참여하여 한번 푸짐한 선물을 받아가세요", main3:"", color:"#fff1c6"},
  {type:LIFEMENU.ATTENDANCE, image : imageDB.attendance, main1:"출석이벤트 ", main2:"꾸준한 구해줘 홍여사 가족임을 축하드립니다. 출석도장 15번 만으로 5000포인트 제공", main3:"", color:"#fff1c6"},
]

const MainLabel = styled.div`
  font-size: 35px;
  font-family: Pretendard-Bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

`
const SubLabel = styled.div`
  font-size: 20px;
  font-family: Pretendard-Regular;
  color: rgb(124, 124, 124);
  line-height: 1.8;
  margin-top: 20px;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
`

const PCGameBoard =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);



  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [selectmenu] = useAtom(lifemenustore);


  // 체크박스 상태 변경 핸들러
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };





  const BoardItems1 =[
    LIFEMENU.AI,
    LIFEMENU.BOARD,
    LIFEMENU.WORK,
    LIFEMENU.CLIMATE,
    LIFEMENU.RECIPE,
    MEDICALMENU.MEDICALMEDICINE,
    MEDICALMENU.FOODINFOMATION,
  

  ]
  const BoardItems2 =[
    TOURISTMENU.TOURCOURSE,
    CONVENIENCEMENU.CONVENIENCECAMPING,
    TOURISTMENU.TOURFESTIVAL,
    PERFORMANCEMENU.PERFORMANCEEVENT,
    TOURISTMENU.TOURREGION,
    LIFEMENU.PICTURE,
    PERFORMANCEMENU.PERFORMANCECINEMA,


  ]
  const BoardItems3 =[
    LIFEMENU.GAME,
    CONFIGMOVE.RULLET,
    LIFEMENU.ATTENDANCE,


  ]

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

    setLoading(loading);


  },[refresh])



  useEffect(()=>{

  }, [])

  const _handlegamestart = () =>{
    navigate("/PClogin");
  }


  return (
    <>
    <Container style={containerStyle}>     
    <Row style={{marginTop:10,width:"100%",margin:"0 auto", display:"flex", alignItems:"flex-start", justifyContent:"flex-start"}}>
    
      <Column style={{flexWrap: "wrap", width:"100%", margin:"20px auto", justifyContent:"flex-start", alignItems:"flex-start"}}>
 
      <div style={{height:"550px", width:"100%",backgroundColor:"#fff"}}>
        <Row style={{width:"70%", margin:"100px auto"}}>


          <Column style={{width:"40%",height:"100%", paddingLeft:50}}>
              <video class="background-video3"  autoPlay muted  loop >
                <source src={imageDB.introduce6}  type="video/mp4" />
              </video> 
          </Column>

          <Column style={{width:"60%",height:"100%", paddingLeft:50}}>
              <MainLabel>
                <div>구해줘 홍여사 게임 이벤트</div>
              </MainLabel>
              <SubLabel>
                <div>구해줘 홍여사 게임하고 할때마다 100포인트 지급</div>
              </SubLabel>

              <SubLabel>
                <div>매주 순위 발표에 의해 1등부터 3등까지 5000 포인트 에서 10000포인트 지금 </div>
                <div>획득된 포인트는 언제든지 도움요청시 현금처럼 사용 가능 </div>
              </SubLabel>

              {/* <FlexstartRow style={{width:"100%"}}>
              <ButtonEx text={'게임 참여하기'} width={'60'}  
                  onPress={_handlegamestart} bgcolor={'#fff'} color={'#3a3636'} containerStyle={{fontFamily:"Pretendard-Regular",
                  border :"1px solid",marginTop:80,boxShadow:"none", marginLeft:0}} />
              </FlexstartRow> */}
          </Column>
      


        </Row>
      </div>

      <div style={{height:"450px", width:"100%",backgroundColor:"#fff1c6"}}>
        <Row style={{width:"50%", margin:"0px auto 30px"}}>
          <Column style={{width:"60%",height:"100%", paddingTop:20, paddingLeft:0}}>

          <MainLabel>
            <div>룰렛 이벤트</div>
          </MainLabel>
          <SubLabel>
            <div>두시간 마다 한번 제공되는 룰렛 이벤트</div>
          </SubLabel>
          <SubLabel>
            <div>이벤트 참여하여 한번 푸짐한 선물을 받아가세요 </div>
            <div>획득된 포인트는 언제든지 도움요청시 현금처럼 사용 가능 </div>
          </SubLabel>
          <SubLabel>
         
          </SubLabel>


          </Column>
          <Column style={{width:"40%",height:"100%", marginTop:50}}>
            <video class="background-video3"  autoPlay muted  loop >
                <source src={imageDB.introduce7}  type="video/mp4" />
              </video> 
          </Column>
        </Row>
      </div>

      <div style={{height:"500px", width:"100%",backgroundColor:"#f7e4e4"}}>
        <Row style={{width:"70%", margin:"30px auto"}}>


          <Column style={{width:"40%",height:"100%", paddingTop:30, paddingLeft:50}}>
            <img src={imageDB.attendance2} style={{width:200}}/>
          </Column>

          <Column style={{width:"60%",height:"100%", paddingTop:30, paddingLeft:50}}>

              <MainLabel>
                <div>구해줘 홍여사 출석 이벤트</div>

              </MainLabel>
              <SubLabel>
                  <div>15일 누적 출석이 완료되면 5000point를 드립니다.</div>
                  <div>획득된 포인트는 언제든지 도움요청시 현금처럼 사용 가능 </div>   
              </SubLabel>

              <SubLabel>
       
              </SubLabel>

              {/* <FlexstartRow style={{width:"100%"}}>
                <ButtonEx text={'출석 하러가기'} width={'60'}  
                  onPress={_handlegamestart} bgcolor={'#f7f1eb'} color={'#3a3636'} containerStyle={{fontFamily:"Pretendard-Regular",
                  border :"1px solid",marginTop:40,boxShadow:"none", marginLeft:0}} />
              </FlexstartRow> */}

          </Column>
      


        </Row>
      </div>



      </Column>

    </Row>
    </Container>
    </>
  );
}

export default PCGameBoard;

