
import { setRef, Table } from "@mui/material";
import React, {memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { BetweenRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { shuffleArray, useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { LoadingCommunityStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import "../screen/css/common.css"
import { FaArrowDownLong } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./mobile.css";
import { ReadTOURCOURSE, ReadTOURCOURSECODE } from "../service/TourCourseService";
import { IoFlagSharp } from "react-icons/io5";
import { PCMAINMENU } from "../utility/screen";
import { TOURISTMENU } from "../utility/life";
import MobileLifeTourMapCourse from "./MobileLifeTourMapCourse";
import PCLifeTourMapCourse from "./PCLifeTourMapCourse";
import PCSlickSliderComponentEx from "../common/PCSwipeex";




const Container = styled.div`
  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  height: 1500px;
  overflow-y : auto; 
  scrollbar-width: none;
  width:100%;


`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  padding: 0px 0px 30px;
  border-bottom: 1px solid #131313;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.8;
  width:100%;
  font-family: "Pretendard-Light";


`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}

const Taglabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:14px;
  margin-right:10px;
  color :#131313;
  min-width:50px;
  display : flex;
  align-items: center;
  justify-content: flex-start;
`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;

  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`

const CourseItemLayer = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size:16px;
  margin: 10px 0px;

`
const Box = styled.div`
  border :1px solid #999;
  width: 20%;
  height:230px;
  margin: 10px auto;
  padding: 20px;
  border-radius: 10px;
  background:#fff;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

  transform: scale(0.95); /* 눌렀을 때 크기 조정 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }
`
const InnerLabel = styled.div`

  background: #fff;
  color: #131313;
  border : 1px solid #ededed;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
  margin-top: 5px;
`

const CourseName = styled.div`
    margin-bottom: 10px;
    font-size: 16px;
    color: #131313;
    background: #7A45E5;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Pretendard-Bold';
    color :#fff;
`


const Move = styled.span`
    font-size: 14px;
    color: #999;
    margin-left: 10px;
    padding: 5px 5px;
    display: flex;
    flex-direction : row;
    justify-content : center;
    align-items : center;

`
const CheckPoint = styled.div`
    width: 30px;
    border-radius: 30px;
    height: 30px;
    background: rgb(218 217 217);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FilterEx = styled.div`
    position: fixed;
    width: 500px;
    z-index: 10;
    padding: 0px 10px;
    bottom: 50px;
    left: 33%;

`

const ViewBtn = styled.div`
  margin: 20px auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  height: 47px;
  font-size: 18px;
  font-family: Pretendard-SemiBold;
  width: 33%;
  background: #ff7e19;

`

const BannerItems =[

  {type:TOURISTMENU.TOURCOURSE,image : imageDB.tourcourse, main1:"전국관광코스", main2:"구해줘 홍여사 AI가 테마가 있는 관광코스를 추천해드립니다 ", main3:"코스별 관광지 정보 및 이동경로, 소요시간을 제공" +
    "\n" + 
    "구해줘 홍여사에서 엄선한 관광코스를 확인해보세요" ,color:"#ebf7fa"},

]


/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;


const PCLifeTourCourse =memo(({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [courseitems, setCourseitems] = useState([]);
  const [codeitems, setCodeitems] = useState([]);
  const [mapview, setMapview] = useState(false);
 


  function SeekCourseName(number){

    const FindIndex = codeitems.findIndex(x=>x["코스ID"] == number);

    let name ="";
    name = codeitems[number]["코스명"];

    return name;

  }

  function RegionNameErase(name){
    let result = name.replace('(', '');  // 모든 HTML 태그 제거
    let result2 = result.replace(')', '  ');  // 모든 HTML 태그 제거
    return result2;
  }

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setLoading(loading);
    setCourseitems(courseitems);
    setCodeitems(codeitems);
    setMapview(mapview);
    console.log("MATRIX LOG : courseitems:",courseitems)

  }, [refresh])

   
  useEffect(()=>{


  async function FetchData(){

    const codeitemsTmp = await ReadTOURCOURSECODE({});
    console.log("MATRIX LOG : FetchData : codeitemsTmp:", codeitemsTmp);

    setCodeitems(codeitemsTmp[0].TOURCOURSECODEITEM);

    const itemsTmp = await ReadTOURCOURSE({});

    const groupedItemsTmp = itemsTmp[0].TOURCOURSEITEM.reduce((result, item) => {
   	
      // item.category 값이 이미 존재하는지 확인
      const key = item["코스 아이디"];
      if (!result[key]) {
        result[key] = []; // category 값이 없으면 배열 초기화
      }
      
      result[key].push(item); // 해당 category에 item 추가
      return result;
    }, {});
    


    const keysWithSizes = Object.keys(groupedItemsTmp).map((key) => ({
      key,
      
      size: groupedItemsTmp[key].length,
      items: groupedItemsTmp[key].sort((a, b) => a["코스순서"] - b["코스순서"])
    }));

    let CompleteItems = [];

    keysWithSizes.map((data)=>{
      CompleteItems.push(data.items);
    })

    setCourseitems((CompleteItems));
    setRefresh((refresh) => refresh +1);
    setLoading(false);
  }


    FetchData();
  }, [])



  const _handleCourseanalyze = (name,data ) => {
   // console.log("MATRIX LOG : MobileLifeTourCourse : data:", data)
    navigate("/PCtourcourseanalyze" ,{state :{name :name, COURSEITEM : data}});
   // navigate("/Mobiletourcoursetrace" ,{state :{eventname :eventname, COURSEITEM : data}});
  }

  const _handleMap =()=>{
   setMapview(true);
   setRefresh((refresh) => refresh +1);
  }
  const _handleList =()=>{
    setMapview(false);
    setRefresh((refresh) => refresh +1);
   }

  return (

    <Container style={containerStyle}>    

    <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#ec8260'}/>



    {loading == true ? (<LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
        width={"100px"} height={'100px'} />) :(
          <>
           {mapview == false ?
            (<BetweenRow style={{flexWrap:"wrap"}}>
          {
            courseitems.map((data, index)=>(
            <Box key={data[0]["코스 아이디"]} onClick={()=>{_handleCourseanalyze(SeekCourseName(data[0]["코스 아이디"] -1), data)}} >

              <CourseName>
                {SeekCourseName(data[0]["코스 아이디"] -1)?.slice(0, 17)}
                {SeekCourseName(data[0]["코스 아이디"] -1)?.length > 17 ?  "..." : null}

          

                </CourseName>
  
            <>
            {
              data.map((item, index) =>(
                <>
              {
                  index < 6 && <CourseItemLayer>
                  <FlexstartRow>
                     <div>{RegionNameErase(item["관광지명"])}</div>
                   </FlexstartRow>
                        
                 </CourseItemLayer>
                }
                </>
       
            
              ))
            }
            </>
            </Box>
            ))
          }
            </BetweenRow>):(<PCLifeTourMapCourse></PCLifeTourMapCourse>)}

            {mapview == false ?
              (<FilterEx>
                <ViewBtn onClick={_handleMap}>지도로 보기</ViewBtn> 
              </FilterEx>) :(<FilterEx>
                <ViewBtn onClick={_handleList}>리스트로 보기</ViewBtn> 
              </FilterEx>)}
          </>
         )}

   
      

  
    </Container>
  );

});

export default PCLifeTourCourse;


