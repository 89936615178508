import React, { useEffect, useState } from 'react';
import {motion} from 'framer-motion';
import styled from 'styled-components';



import { PiLockKeyLight } from "react-icons/pi"
import { sleep } from '../utility/common';
import { imageDB } from '../utility/imageData';


const GridContainer  = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 70px); /* 6열 */
  grid-gap: 7px;
  justify-content: center;
`

const GridItem = styled.div`
  width:75px;
  height: 75px;
  display: flex;
  margin-bottom:5px;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: white;
  font-weight: bold;
  border-radius: 5px;

  transition: 'transform 0.5s ease-in-out', // 부드러운 애니메이션
`
const gridColumnSize = 4;
const gridRowSize = 5;


const GameItems = [
  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  // {image:imageDB.game11,open:false,index:11},
  // {image:imageDB.game12,open:false,index:12},

  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  // {image:imageDB.game11,open:false,index:11},
  // {image:imageDB.game12,open:false,index:12},



]


function shuffleArray(array) {
  // Fisher-Yates 알고리즘을 사용한 배열 섞기
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function RotateCardBasic({image, open, index}) {

  const [grid, setGrid] = useState(GameItems);

  useEffect(() => {
    const initialArray = Array.from({ length: gridColumnSize * gridRowSize }, (_, i) => i + 1); // 1 ~ 36
    const shuffledArray = shuffleArray([...grid]);
    setGrid(shuffledArray);
    
    // 정렬 과정 표시
    let step = 0;
    const interval = setInterval(() => {
      const newArray = shuffleArray([...grid]);
      setGrid(newArray);
      step++;
      if (step > 1000) clearInterval(interval); // 정렬 반복 횟수 제한
    }, 300); // 300ms마다 배열 업데이트

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 정리
  }, []);



  return (
    <GridContainer>
    {grid.map((data, index) => (
      <GridItem key={index}>
       <img src={data.image} style={{width:50}}/>
      </GridItem>
    ))}
  </GridContainer>
  );
}

export default RotateCardBasic;


