import React, { Fragment, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import {Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";

import { DataContext } from "../../context/Data";

import "./PCRegister.css"
import { BetweenColumn, Column, FlexstartColumn } from "../../common/Column";
import Button from "../../common/Button";
import IconButton from "../../common/IconButton";
import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../common/Row";

import { HOMECLEAN_REQUESTINFO, Requestbabycaremessages, Requestbusinesscleanmessages, Requestcarryloadmessages, Requestcleanmessages, Requestdoghospitalmessages, Requestdogwalkmessages, Requesterrandmessages, Requestfoodpreparemessages, Requestgohospitalmessages, Requestgooutschoolmessages, REQUESTINFO, Requestlessonmessages, Requestmovecleanmessages, Requestpatientcaremessages, Requestrecipetranmitmessages, Requestschooleventmessages, Requestshoppingmessages, WORKNAME, WORKPOLICY } from "../../utility/work";
import { sleep, useSleep } from "../../utility/common";
import { imageDB, IsWork, Seekimage } from "../../utility/imageData";
import Text from "../../common/Text";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { ko, se } from 'date-fns/locale';
import { DAYOPTION, OPTIONTYPE, PCDAYMENU } from "../../utility/screen";
import SelectItem from "../../components/SelectItem";
import { MdDataUsage, MdTurnedInNot } from "react-icons/md";
import "./table.css";
import { Requestlargemessages, Requestmediummessages, Requestroommessages, Requestsmallmessages, ROOMSIZE } from "../../utility/room";

import { CreateWork, CreateWorkInfo, ReadWorkByuserid } from "../../service/WorkService";
import { CreateRoom, CreateRoomInfo, ReadRoomByuserid } from "../../service/RoomService";
import ImageUploadComponent from "../../components/ImageUpload";
import Label from "../../common/Label";
import MobileSuccessPopup from "../../modal/MobileSuccessPopup/MobileSuccessPopup";
import { RiRectangleFill } from "react-icons/ri";

import MobileWorkMapPopup from "../../modal/MobileWorkMapPopup/MobileWorkMapPopup";
import { getDateFullTime } from "../../utility/date";
import { WORKSTATUS } from "../../utility/status";
import { ALLREFRESH } from "../../store/menu/MenuSlice";
import { useDispatch } from "react-redux";



const Container = styled.div`
  background :#FFF1AA;

  display:flex;
  flex-direction:column;
  width:100%;
  margin: 70px auto;
  background-color: #f0f0f0;
  background-image: radial-gradient(circle, #000000 1px, transparent 1px);
  background-size: 50px 50px;
`
const ContentLayer = styled.div`
  display: flex;
  flex-direction : column;
  width: 100%;
  justify-content:flex-start;
  align-items:flex-start;
  margin : 0px auto;
  font-size : 16px;
  font-weight:400;
  color :#131313;
  background:#fff;
  height:3600px;


`

const Title = styled.div`
  font-size: 20px;
  line-height: 60px;
  font-weight :600;
  margin-left:10px;

`

const Itemlayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top:10px;
  margin-left:20px;
  width:${({width}) => width};


`

const ItemLeftBox = styled.div`
  background: #fff;
  border :1px solid #ededed;
  border-radius: 10px;
  padding: 20px;
  margin: 5px 10px 0px;
  color: #131313;
  display: flex;
  flex-direction: column;
  width: ${({width}) => width};
  font-size: 16px;
  text-align: left;
  min-width:200px;
  font-weight:400;
  box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07);


`;


const SelectLayer = styled.div`
  border: 1px solid #C3C3C3;
  width: 48%;
  margin: 5px 0px;
  background: ${({check}) => check == true ? ('#ff7e19'):('#fff')};
  color: ${({check}) => check == true ? ('#fff'):('#131313')};
  font-weight:600;
  border-radius: 5px;
  font-size:12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
`;


const ProgressLayer = styled.div`
  position: absolute;
  background: #ff7e19;
  width: 60%;
  border-radius: 5px;
  padding : 5px;
  color: rgb(255, 255, 255);
  top: 10px;
  z-index: 5;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

`


const ProgressLayerText = styled.div`

  font-size:16px;
  font-weight:700;

`

export const StyledCalendarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`



// 캘린더를 불러옴
export const StyledCalendar = styled(Calendar)`

width: 100%;
background: white;
border: none;
line-height: 1.125em;
font-size: 14px; /* 글자 크기 */
text-decoration: none; /* 밑줄 제거 */

.react-calendar__navigation button {
  color: #4d4d4d;
  min-width: 44px;
  background: none;
  font-size: 20px; /* 네비게이션 버튼 글자 크기 */
  margin-top: 8px;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 14px; /* 요일 이름 글자 크기 */
  color: #6b6b6b;
  font-weight:500;
  text-decoration: none; /* 밑줄 제거 */
}

.react-calendar__tile {
  background: none;
  font-size: 16px; /* 날짜 타일 글자 크기 */
  color: #4d4d4d;
  padding: 5px 6.6667px;
}

.react-calendar__tile--now {
  font-size: 14px;

 }
 .react-calendar__tile:disabled {
  color: #d6cfcf !important;
 }

.react-calendar__tile--active {
  background: #ff7e19 !important;
  color: white;
  border-radius : 20px;
}

.react-calendar__tile--hover {
  background: #1087ff;
}
`;


const CommentContent = {
  width: '98%',
  height: '88px',
  fontSize: '14px',
  fontFamily: 'Pretendard-Regular',
  lineHeight: 2,
  outline:"none",
  resize :"none",
  border:"1px solid #E3E3E3",
}
const DayBtn = styled.div`
  height: 34px;
  width: 58px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({check}) => check == true ? ('#ff7e19'):('#fff')};
  color: ${({check}) => check == true ? ('#fff'):('#131313')};


`

const RegistHeader = styled.div`
  display : flex;
  flex-direction : row;
  justify-content : center;
  align-items:center;
  background: rgb(255, 255, 255);
  height: 120px;
  position: fixed;
  z-index: 5;
  width: 30%;
  border :1px solid #ededed;

`


const { kakao } = window;

const mapstyle = {
  width:'270px',
  height:'320px'
};


const PCRegistcontainer =({containerStyle, type, totalset}) =>  {

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [roomimg, setRoomimg] = useState('');
  const [comment, setComment] = useState('');
  const [addrpopup, setAddrpopup] = useState(false);
  const [regionindex, setRegionindex] = useState(0);



  let msgs = [];
  if(type == WORKNAME.HOMECLEAN){
    msgs = Requestcleanmessages;
  }else if(type ==WORKNAME.BUSINESSCLEAN){
    msgs = Requestbusinesscleanmessages;
  }else if(type ==WORKNAME.MOVECLEAN){
    msgs = Requestmovecleanmessages;
  }else if(type ==WORKNAME.FOODPREPARE){
    msgs = Requestfoodpreparemessages;
  }else if(type ==WORKNAME.ERRAND){
    msgs = Requesterrandmessages;
  }else if(type ==WORKNAME.CARRYLOAD){
    msgs = Requestcarryloadmessages;
  }else if(type ==WORKNAME.GOOUTSCHOOL){
    msgs = Requestgooutschoolmessages;
  }else if(type ==WORKNAME.BABYCARE){
    msgs = Requestbabycaremessages;
  }else if(type == WORKNAME.LESSON){ 
    msgs = Requestlessonmessages;
  }else if(type == WORKNAME.PATIENTCARE){ 
    msgs = Requestpatientcaremessages;
  }else if(type == WORKNAME.GOHOSPITAL){ 
    msgs = Requestgohospitalmessages;
  }else if(type == WORKNAME.RECIPETRANSMIT){ 
    msgs = Requestrecipetranmitmessages;
  }else if(type == WORKNAME.GOSCHOOLEVENT){ 
    msgs = Requestschooleventmessages;
  }else if(type == WORKNAME.SHOPPING){ 
    msgs = Requestshoppingmessages;
  }else if(type == WORKNAME.GODOGHOSPITAL){ 
    msgs = Requestdoghospitalmessages;
  }else if(type == WORKNAME.GODOGWALK){ 
    msgs = Requestdogwalkmessages;
  }else if(type ==ROOMSIZE.ONESIZE 
    || type == ROOMSIZE.TWOSIZE 
    || type == ROOMSIZE.THREESIZE
    || type == ROOMSIZE.FOURSIZE
    || type == ROOMSIZE.FIVESIZE
    || type == ROOMSIZE.SIXSIZE
    || type == ROOMSIZE.SEVENSIZE
    || type == ROOMSIZE.EIGHTSIZE){ 
    msgs = Requestroommessages;
  }



  const [messages, setMessages] = useState(msgs);

  const [stepdata, setStepdata] = useState(0);
  const [stepstr, setStepstr] = useState('');
  const [selectdate, setSelectdate] = useState('');

  const [allweeks, setAllweeks] = useState(false);
  const [dayitems, setDayitems] = useState([]);


  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [registWorkSuccess, setRegistWorkSuccess] = useState(false);
  const [registRoomSuccess, setRegistRoomSuccess] = useState(false);

  const useCommentRef= useRef(null);
  const useCompleteRef = useRef(null);

  const [checkindex, setCheckindex] = useState(0);



    /*
  초기값을 제거
  */
  const _handleReset = () =>{
    let msgs = [];
    async function Fetchdata(){
      if(type == WORKNAME.HOMECLEAN){ 
        msgs = Requestcleanmessages;
      }else  if(type == WORKNAME.BUSINESSCLEAN){ 
        msgs = Requestbusinesscleanmessages;
      }else if(type == WORKNAME.MOVECLEAN){ 
        msgs = Requestmovecleanmessages;
      }else if(type == WORKNAME.FOODPREPARE){ 
        msgs = Requestfoodpreparemessages;
      }else if(type == WORKNAME.ERRAND){ 
        msgs = Requesterrandmessages;
      }else if(type == WORKNAME.GOOUTSCHOOL){ 
        msgs = Requestgooutschoolmessages;
      }else if(type == WORKNAME.BABYCARE){ 
        msgs = Requestbabycaremessages;
      }else if(type == WORKNAME.LESSON){ 
        msgs = Requestlessonmessages;
      }else if(type == WORKNAME.PATIENTCARE){ 
        msgs = Requestpatientcaremessages;
      }else if(type == WORKNAME.GOHOSPITAL){ 
        msgs = Requestgohospitalmessages;
      }else if(type == WORKNAME.RECIPETRANSMIT){ 
        msgs = Requestrecipetranmitmessages;
      }else if(type == WORKNAME.GOSCHOOLEVENT){ 
        msgs = Requestschooleventmessages;
      }else if(type == WORKNAME.CARRYLOAD){ 
          msgs = Requestcarryloadmessages;
      }else if(type == WORKNAME.SHOPPING){ 
        msgs = Requestshoppingmessages;
      }else if(type == WORKNAME.GODOGHOSPITAL){ 
        msgs = Requestdoghospitalmessages;
      }else if(type == WORKNAME.GODOGWALK){ 
        msgs = Requestdogwalkmessages;
      }

      msgs.map((data, index)=>{
        if(data.type == 'request' 
        || data.type =='requestroom' 
        || data.type =='requestcomment' 
        || data.type == 'requesttimemoney'
        || data.type == 'requesthelp'
        || data.type == 'requesttarget'
        || data.type =='requestdate' 
        || data.type =='requestregion' 
        || data.type =='requestcomplete'){
          data.show = false;
          data.selected = false;

          if(data.selectitems != undefined){
            data.selectitems.map((select=>{
              select.selected = false;
            }))
          }
          if(data.targetareaselectitems != undefined){
            data.targetareaselectitems.map((select=>{
              select.selected = false;
            }))
          }
          if(data.targetpositionselectitems != undefined){
            data.targetpositionselectitems.map((select=>{
              select.selected = false;
            }))
          }

          if(data.moneyselectitems != undefined){
            data.moneyselectitems.map((select=>{
              select.selected = false;
            }))
          }

          if(data.timeselectitems != undefined){
            data.timeselectitems.map((select=>{
              select.selected = false;
            }))
          }

          if(data.helpageselectitems != undefined){
            data.helpageselectitems.map((select=>{
              select.selected = false;
            }))
          }

          if(data.helpgenderselectitems != undefined){
            data.helpgenderselectitems.map((select=>{
              select.selected = false;
            }))
          }


        }else if(data.type =='response'){
          data.result ="";
          data.show =false;
        }
      })
      setMessages(msgs); 
      setAddress('');

      let str = totalset + "단계만 설정 하시면 등록이 완료됩니다";
      setStepstr(str);
      setStepdata(0);
   
      await useSleep(1500);
      msgs[1].show = true;
      setRefresh((refresh) => refresh +1);

      window.scrollTo(0, 0);
    }
    Fetchdata();

  }


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setMessages(messages);
    setStepdata(stepdata);
    setAllweeks(allweeks);
    setDayitems(dayitems);
    setStepstr(stepstr);
    setSelectdate(selectdate);
    setMarkers(markers);
    setMap(map);
    setAddress(address);
    setLatitude(latitude);
    setLongitude(longitude);
    setRoomimg(roomimg);
    setRegistWorkSuccess(registWorkSuccess);
    setRegistRoomSuccess(registRoomSuccess);
    setAddrpopup(addrpopup);
    setRegionindex(regionindex);
    setCheckindex(checkindex);
  },[refresh])


  /**
   * 공간 대여일 경우는 첫번째 인덱스와 두번째 인덱스는 안보여주고 바로 기간 설정 할수 있도록한다
   */
  useEffect(()=>{

    _handleReset();

    async function FetchData(){
     
      let str = totalset + "단계만 설정 하시면 등록이 완료됩니다";
      setStepstr(str);

      await useSleep(1500);

      messages[1].show = true;

      setRefresh((refresh) => refresh +1);

    } 
    FetchData();
  }, [])





 /**
  * 선택지에서 선택 한 아이템에 selected 를 true로 해준다
  */
  const _handlecheck = (index, key) =>{
    messages[index].selectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].selectitems.findIndex(x=>x.key == key);
    messages[index].selectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }



  /**
   * 재설정하기 위해 필요한 함수
   */
  const _handleAdjust =(index) =>{
    messages[index-1].selected = false;
    setRefresh((refresh) => refresh +1);
  }   

  const _handletargetpostioncheck = (index, key) =>{
    
    console.log("TCL: _handletargetpostioncheck -> ", messages[index] );
    messages[index].targetpositionselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].targetpositionselectitems.findIndex(x=>x.key == key);
    messages[index].targetpositionselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handletargetareacheck = (index, key) =>{
    messages[index].targetareaselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].targetareaselectitems.findIndex(x=>x.key == key);
    messages[index].targetareaselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  

  const _handletimecheck = (index, key) =>{
    
    console.log("TCL: _handletargetpostioncheck -> ", messages[index] );
    messages[index].timeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].timeselectitems.findIndex(x=>x.key == key);
    messages[index].timeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlemoneycheck = (index, key) =>{
    messages[index].moneyselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].moneyselectitems.findIndex(x=>x.key == key);
    messages[index].moneyselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }


  const _handlehelpgendercheck = (index, key) =>{
    
    console.log("TCL: _handletargetpostioncheck -> ", messages[index] );
    messages[index].helpgenderselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].helpgenderselectitems.findIndex(x=>x.key == key);
    messages[index].helpgenderselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handleoptioncheck = (index, key) =>{
    

    messages[index].prepareoptionselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].prepareoptionselectitems.findIndex(x=>x.key == key);
    messages[index].prepareoptionselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlecontentcheck = (index, key) =>{
    

    messages[index].preparecontentselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].preparecontentselectitems.findIndex(x=>x.key == key);
    messages[index].preparecontentselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlepreparecasecheck = (index, key) =>{
    

    messages[index].preparecaseselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].preparecaseselectitems.findIndex(x=>x.key == key);
    messages[index].preparecaseselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlehelpagecheck = (index, key) =>{
    

    messages[index].helpageselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].helpageselectitems.findIndex(x=>x.key == key);
    messages[index].helpageselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }



  const _handlehelpcontentcheck = (index, key) =>{
    messages[index].helpcontentselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].helpcontentselectitems.findIndex(x=>x.key == key);
    messages[index].helpcontentselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlegooutschooltargetcheck = (index, key) =>{
    messages[index].goouttargetselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].goouttargetselectitems.findIndex(x=>x.key == key);
    messages[index].goouttargetselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }


  const _handlegooutschooltimecheck = (index, key) =>{
    messages[index].goouttimeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].goouttimeselectitems.findIndex(x=>x.key == key);
    messages[index].goouttimeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlebabycaretimecheck = (index, key) =>{
    messages[index].babycaretimeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].babycaretimeselectitems.findIndex(x=>x.key == key);
    messages[index].babycaretimeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlebabycaretargetcheck = (index, key) =>{
    messages[index].babycaretargetselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].babycaretargetselectitems.findIndex(x=>x.key == key);
    messages[index].babycaretargetselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlebabycarerangecheck = (index, key) =>{
    messages[index].babycarerangeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].babycarerangeselectitems.findIndex(x=>x.key == key);
    messages[index].babycarerangeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlelessonrangecheck = (index, key) =>{
    messages[index].lessonrangeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].lessonrangeselectitems.findIndex(x=>x.key == key);
    messages[index].lessonrangeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlelessontimecheck = (index, key) =>{
    messages[index].lessontimeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].lessontimeselectitems.findIndex(x=>x.key == key);
    messages[index].lessontimeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlepatienttargetcheck = (index, key) =>{
    messages[index].patientcaretargetselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].patientcaretargetselectitems.findIndex(x=>x.key == key);
    messages[index].patientcaretargetselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlepatientrangecheck = (index, key) =>{
    messages[index].patientcarerangeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].patientcarerangeselectitems.findIndex(x=>x.key == key);
    messages[index].patientcarerangeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlepatientgendercheck = (index, key) =>{
    messages[index].patientcaregenderselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].patientcaregenderselectitems.findIndex(x=>x.key == key);
    messages[index].patientcaregenderselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlepatienttimecheck = (index, key) =>{
    messages[index].patientcaretimeselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].patientcaretimeselectitems.findIndex(x=>x.key == key);
    messages[index].patientcaretimeselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlecarryloadcontentcheck = (index, key) =>{
    messages[index].carryloadcontentselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].carryloadcontentselectitems.findIndex(x=>x.key == key);
    messages[index].carryloadcontentselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlehospitaltargetcheck = (index, key) =>{
    messages[index].hospitaltargetselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].hospitaltargetselectitems.findIndex(x=>x.key == key);
    messages[index].hospitaltargetselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  const _handlehospitalgendercheck = (index, key) =>{
    messages[index].hospitalgenderselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].hospitalgenderselectitems.findIndex(x=>x.key == key);
    messages[index].hospitalgenderselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handlerecipeoptioncheck = (index, key) =>{
    messages[index].recipeoptionselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].recipeoptionselectitems.findIndex(x=>x.key == key);
    messages[index].recipeoptionselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handleschooleventcheck = (index, key) =>{
    messages[index].schooleventselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].schooleventselectitems.findIndex(x=>x.key == key);
    messages[index].schooleventselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }
  const _handleshoppingcontentcheck = (index, key) =>{
    messages[index].shoppingcontentselectitems.map((data)=>{
      data.selected = false;
    })
    const FindIndex = messages[index].shoppingcontentselectitems.findIndex(x=>x.key == key);
    messages[index].shoppingcontentselectitems[FindIndex].selected= true;
    setRefresh((refresh) => refresh +1);
  }

  


  /**
  * 선택하게 되면
  * ! ① 해당 인덱스를 선택하게 되었다고 표시 해주고
  * ! ② 다음 첫번째 인덱스에 결과값을 넣어주고 보여줌을 표시 하자
  * ! ③ 다음 두번째 인덱스를 보여주도록 한다
  * ! 선택이 전부 되었으면 부드럽게 이동할수 있도록 한다
  */

  const _handleNext = async (index) =>{

    const FindIndex = messages[index].selectitems.findIndex(x=>x.selected == true);
    if(FindIndex == -1){
      return;
    }

    let data = messages[index].step;

    if(messages[index+2].show != true){
      let str = totalset +"단계중 "+ messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }
    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+1].result = messages[index].selectitems[FindIndex].response; //!TODO
    messages[index+2].show =true;

    await sleep(1000);

    window.scrollTo({
      top: window.scrollY + 200, // 스크롤할 Y 위치
      behavior: 'smooth', // 부드럽게 스크롤
    });

    setRefresh((refresh) => refresh +1);
  }


  /**
   * 지역 선택
   */
  const _handleRegionNext = async(index) =>{
    setRegionindex(index);
    setAddrpopup(true);
    setRefresh((refresh) => refresh +1);

    // if(type ==ROOMSIZE.SMALLER || type == ROOMSIZE.SMALL 
    //   || type == ROOMSIZE.MEDIUM
    //   || type == ROOMSIZE.LARGE
    //   || type == ROOMSIZE.EXLARGE
    //   ){
    //   useCompleteRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'end',
    //   });
    // }else{
    //   useCommentRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'end',
    //   });
    // }
 

  }

  const workmapPopupcallback =(data)=>{
  console.log("TCL: workmapPopupcallback -> data", data);

  if(data == '' || data == undefined){
    return;
  }

  const geocoder = new kakao.maps.services.Geocoder();
  geocoder.addressSearch(data, async function(result, status) {
    // API 호출 결과 처리
    if (status === kakao.maps.services.Status.OK) {
      // 좌표 정보를 가져온다
      const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
      console.log("위도:", coords.getLat());
      console.log("경도:", coords.getLng());


      let latitude = coords.getLat();
      let longitude = coords.getLng();

      messages[regionindex].selected = true;
      messages[regionindex+1].show = true;
      messages[regionindex+1].result = data;
      messages[regionindex+1].latitude = latitude;
      messages[regionindex+1].longitude = longitude;
      console.log("TCL: _handleRegionNext -> address", data,messages)
 

      setAddress(data);
      let stepdata = messages[regionindex].step;
      if(messages[regionindex+2].show != true){
      let str = totalset +"단계중 "+messages[regionindex].step+"단계를 설정하였습니다";
        setStepdata(stepdata);
        setStepstr(str);
      }   
      messages[regionindex+2].show = true;
      setAddrpopup(false);
      setRefresh((refresh) => refresh +1);

    }
  });




  }
  const _handleCurrentRegionNext = async(index) =>{
  console.log("TCL: _handleCurrentRegionNext -> index", index, messages)

    if(user.address_name == ''){
      return;
    }
    
    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+1].result = user.address_name;
    messages[index+1].latitude = user.latitude;
    messages[index+1].longitude = user.longitude;

    setAddress(user.address_name);

    setRegionindex(index);

    let stepdata = messages[index].step;

    if(messages[index+2].show != true){
    let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(stepdata);
      setStepstr(str);
    }   


    messages[index+2].show = true;




    setRefresh((refresh) => refresh +1);


  }

  /**
   * 타켓 선택
   */
  const _handleTargetNext = async(index) =>{

    let data = messages[index].step;

    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+2].show = true;
    let resultindex = 0;
    let FindIndex = 0;

    if(messages[index].targetpositionselectitems != undefined){
      FindIndex =  messages[index].targetpositionselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HOMECLEANTARGET
          || x.requesttype == REQUESTINFO.MOVECLEANTARGET
          || x.requesttype == REQUESTINFO.PREPARETARGET
          || x.requesttype == REQUESTINFO.BUSINESSCLEANTARGET);
      
          if(resultindex != -1){
            messages[resultindex].result = messages[index].targetpositionselectitems[FindIndex].response; //!TODO
          }
      }
    }



    if(messages[index].targetareaselectitems != undefined){
      FindIndex = messages[index].targetareaselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HOMECLEANRANGE
          || x.requesttype == REQUESTINFO.MOVECLEANRANGE
          || x.requesttype == REQUESTINFO.BUSINESSCLEANRANGE);
    
        if(resultindex != -1){
          messages[resultindex].result = messages[index].targetareaselectitems[FindIndex].response; //!TODO
        }
    
      }
    }



    if(messages[index].timeselectitems != undefined){
      FindIndex = messages[index].timeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HOMECLEANTIME 
          || x.requesttype == REQUESTINFO.MOVECLEANTIME
          || x.requesttype == REQUESTINFO.TARGETTIME
          || x.requesttype == REQUESTINFO.PREPARETIME
          || x.requesttype == REQUESTINFO.BUSINESSCLEANTIME);
    
        if(resultindex != -1){
          messages[resultindex].result = messages[index].timeselectitems[FindIndex].response; //!TODO
        }
      }
    }



    if(messages[index].moneyselectitems != undefined){
      FindIndex = messages[index].moneyselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HOMECLEANMONEY 
          || x.requesttype == REQUESTINFO.BUSINESSCLEANMONEY
          || x.requesttype == REQUESTINFO.PREPAREMONEY
          || x.requesttype == REQUESTINFO.TARGETMONEY
          || x.requesttype == REQUESTINFO.GOOUTSCHOOLMONEY
          || x.requesttype == REQUESTINFO.MOVECLEANMONEY);
    
        if(resultindex != -1){
          messages[resultindex].result = messages[index].moneyselectitems[FindIndex].response; //!TODO
        }
      }
    }


    if(messages[index].helpgenderselectitems != undefined){
      FindIndex = messages[index].helpgenderselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HELPGENDER);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].helpgenderselectitems[FindIndex].response; //!TODO
        }
    
      }
    }

 
    if(messages[index].helpageselectitems != undefined){
      FindIndex = messages[index].helpageselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.HELPAGE);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].helpageselectitems[FindIndex].response; //!TODO
        }
    
      }
    }


    if(messages[index].prepareoptionselectitems != undefined){
      FindIndex = messages[index].prepareoptionselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PREPAREOPTION);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].prepareoptionselectitems[FindIndex].response; //!TODO
        }
    
      }
    }


    if(messages[index].preparecontentselectitems != undefined){
      FindIndex = messages[index].preparecontentselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PREPARECONTENT);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].preparecontentselectitems[FindIndex].response; //!TODO
        }
    
      }
    }


    if(messages[index].preparecaseselectitems != undefined){
      FindIndex = messages[index].preparecaseselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PREPARECASE);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].preparecaseselectitems[FindIndex].response; //!TODO
        }
    
      }
    }

    if(messages[index].helpcontentselectitems != undefined){
      FindIndex = messages[index].helpcontentselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.ERRANDCONTENT);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].helpcontentselectitems[FindIndex].response; //!TODO
        }
    
      }
    }


    if(messages[index].goouttimeselectitems != undefined){
      FindIndex = messages[index].goouttimeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOOUTSCHOOLTIME);
  
        if(resultindex != -1){
          messages[resultindex].result = messages[index].goouttimeselectitems[FindIndex].response; //!TODO
        }
    
      }
    }

    if(messages[index].goouttargetselectitems != undefined){
      FindIndex = messages[index].goouttargetselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOOUTSCHOOLTARGET);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].goouttargetselectitems[FindIndex].response; //!TODO
        }
      }
    }


    if(messages[index].goouttargetselectitems != undefined){
      FindIndex = messages[index].goouttargetselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOOUTSCHOOLTARGET);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].goouttargetselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].babycaretimeselectitems != undefined){
      FindIndex = messages[index].babycaretimeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype == REQUESTINFO.BABYCARETIME);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].babycaretimeselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].babycaretargetselectitems != undefined){
      FindIndex = messages[index].babycaretargetselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype == REQUESTINFO.BABYCARETARGET);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].babycaretargetselectitems[FindIndex].response; //!TODO
        }
      }
    }


    if(messages[index].babycarerangeselectitems != undefined){
      FindIndex = messages[index].babycarerangeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.BABYCARERANGE);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].babycarerangeselectitems[FindIndex].response; //!TODO
        }
      }
    }
    if(messages[index].lessontimeselectitems != undefined){
      FindIndex = messages[index].lessontimeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.LESSONTIME);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].lessontimeselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].lessonrangeselectitems != undefined){
      FindIndex = messages[index].lessonrangeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.LESSONRANGE);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].lessonrangeselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].patientcaretimeselectitems != undefined){
      FindIndex = messages[index].patientcaretimeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PATIENTCARETIME);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].patientcaretimeselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].patientcaretargetselectitems != undefined){
      FindIndex = messages[index].patientcaretargetselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PATIENTCARETARGET);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].patientcaretargetselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].patientcarerangeselectitems != undefined){
      FindIndex = messages[index].patientcarerangeselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PATIENTCARERANGE);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].patientcarerangeselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].patientcaregenderselectitems != undefined){
      FindIndex = messages[index].patientcaregenderselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.PATIENTCAREGENDER);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].patientcaregenderselectitems[FindIndex].response; //!TODO
        }
      }
    }
    if(messages[index].carryloadcontentselectitems != undefined){
      FindIndex = messages[index].carryloadcontentselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.CARRAGELOADCONTENT);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].carryloadcontentselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].hospitaltargetselectitems != undefined){
      FindIndex = messages[index].hospitaltargetselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOHOSPITALTARGET);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].hospitaltargetselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].hospitalgenderselectitems != undefined){
      FindIndex = messages[index].hospitalgenderselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOHOSPITALGENDER);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].hospitalgenderselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].recipeoptionselectitems != undefined){
      FindIndex = messages[index].recipeoptionselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.RECIPEOPTION);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].recipeoptionselectitems[FindIndex].response; //!TODO
        }
      }
    }
    if(messages[index].schooleventselectitems != undefined){
      FindIndex = messages[index].schooleventselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.GOSCHOOLEVENTCONTENT);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].schooleventselectitems[FindIndex].response; //!TODO
        }
      }
    }

    if(messages[index].shoppingcontentselectitems != undefined){
      FindIndex = messages[index].shoppingcontentselectitems.findIndex(x=>x.selected == true);
      if(FindIndex != -1){
        resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.SHOPPINGCONTENT);
        if(resultindex != -1){
          messages[resultindex].result = messages[index].shoppingcontentselectitems[FindIndex].response; //!TODO
        }
      }
    }
    

    resultindex =  messages.findIndex(x=>x.requesttype ==REQUESTINFO.REQDATE);
  
    if(resultindex != -1){
      messages[resultindex].result = Date.now();
    }





    if(messages[index+3].show != true){
      let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }

    const RegionIndex =  messages.findIndex(x=>x.type == 'requestregion');
    console.log("TCL: _handleTargetNext -> RegionIndex", RegionIndex)

    messages[RegionIndex].show = true;

    setRefresh((refresh) => refresh +1);
    await sleep(1000);

    window.scrollTo({
      top: window.scrollY + 200, // 스크롤할 Y 위치
      behavior: 'smooth', // 부드럽게 스크롤
    });


  }

 /**
 * 시간과 금액 선택
 */
  const _handleTimeMoneyNext = async(index) =>{

    let data = messages[index].step;
    console.log("TCL: _handleTimeMoneyNext -> index", index, messages[index].step);

   

    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+2].show = true;

    let FindIndex = messages[index].timeselectitems.findIndex(x=>x.selected == true);
    if(FindIndex == -1){
      return;
    }

    messages[index+1].result = messages[index].timeselectitems[FindIndex].response; //!TODO

    FindIndex = messages[index].moneyselectitems.findIndex(x=>x.selected == true);
    if(FindIndex == -1){
      return;
    }
    if(messages[index+3].show != true){
      let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }
  


    messages[index+2].result = messages[index].moneyselectitems[FindIndex].response; //!TODO
    messages[index+3].show = true;

    setRefresh((refresh) => refresh +1);
    await sleep(1000);

    window.scrollTo({
      top: window.scrollY + 200, // 스크롤할 Y 위치
      behavior: 'smooth', // 부드럽게 스크롤
    });


  }


/**
 * 도움받을 사람 선택
 */
  const _handleHelpNext = async(index) =>{
  console.log("TCL: _handleHelpNext -> index", index)

    let data = messages[index].step;



    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+2].show = true;

    let FindIndex = messages[index].helpgenderselectitems.findIndex(x=>x.selected == true);
    if(FindIndex == -1){
      return;
    }

    messages[index+1].result = messages[index].helpgenderselectitems[FindIndex].response; //!TODO

    FindIndex = messages[index].helpageselectitems.findIndex(x=>x.selected == true);
    if(FindIndex == -1){
      return;
    }

    if(messages[index+3].show != true){
      let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }


    messages[index+2].result = messages[index].helpageselectitems[FindIndex].response; //!TODO
    messages[index+3].show = true;
    
    setRefresh((refresh) => refresh +1);
    await sleep(2000);

    window.scrollTo({
      top: window.scrollY + 200, // 스크롤할 Y 위치
      behavior: 'smooth', // 부드럽게 스크롤
    });

  }


  const _handleCommentNext = async(index)=>{
    let data =messages[index].step;

    let str = totalset + "단계 모두 설정 완료하였습니다";
    setStepstr(str);
    setStepdata(totalset);

    messages[index].selected = true;
    messages[index+1].show = true;
    messages[index+1].result = comment;
    messages[index+2].show =true;
    messages[index+2].info = user.nickname +"님이 작성하신 요구사항은 다음과 같습니다";
    messages[index+3].result  = Date.now();
 
 

    await useSleep(500);
    useCompleteRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  /**
   * ! 공간 대여 일 경우는 사진 데이타 결과값을 이미 넣어주고 선택 된것으로 해준다

   */
  const _handleRoomNext = (index) =>{
    let data = messages[index].step;
    let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
    setStepdata(data);
    setStepstr(str);

    messages[index].selected = true;
    messages[index+1].show = false;
    messages[index+1].result = roomimg; //!TODO
    messages[index+2].show =true;


    const FindIndex = messages.findIndex(x=>x.requesttype == REQUESTINFO.REQDATE);

    if(FindIndex != -1){
      messages[FindIndex].result= Date.now();
    }
  
    setRefresh((refresh) => refresh +1);
  }

  /**
   * 날짜 선택
   */
  const _handleDateNext = (index) =>{

    if(allweeks  == true){
      if(dayitems.length ==0){
        return;
      }
    }

    let data = messages[index].step;

    if( messages[index+2].show  != true){
      let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }



    messages[index].selected = true;
    messages[index+1].show = true;

    // 기간값을 넣어주자
    // ①②③④
    // ① 일정 시점을 선택 했다고 하면 date 가 있는거다
    if(selectdate != ''){
      messages[index+1].result = selectdate;
    }else{
        // ② 매일을 선택 했다고 하면 
        if(allweeks  == false){
          messages[index+1].result = '매일';
        }else{
          // ③ 매일을 선택 했다고 하면 
          let str = "매주";
          dayitems.map((day, index)=>{
            str += day;
            str += '요일';
            str += ' ';
          })

          messages[index+1].result = str;
        }
    }

    messages[index+2].show =true;
    setRefresh((refresh) => refresh +1);
  }

  const _handleCalendarDateNext = (index)=>{

    if(selectdate == ''){
      return;
    }

    let data = messages[index].step;

    if( messages[index+2].show  != true){
      let str = totalset +"단계중 "+messages[index].step+"단계를 설정하였습니다";
      setStepdata(data);
      setStepstr(str);
    }

  
    messages[index].selected = true;
    messages[index+1].show = true;

    // 기간값을 넣어주자
    // ①②③④
    // ① 일정 시점을 선택 했다고 하면 date 가 있는거다
    if(selectdate != ''){
      messages[index+1].result = selectdate;
    }else{
        // ② 매일을 선택 했다고 하면 
        if(allweeks  == false){
          messages[index+1].result = '매일';
        }else{
          // ③ 매일을 선택 했다고 하면 
          let str = "매주";
          dayitems.map((day, index)=>{
            str += day;
            str += '요일';
            str += ' ';
          })

          messages[index+1].result = str;
        }
    }

    messages[index+2].show =true;

    setRefresh((refresh) => refresh +1);
  }
  const _handleReqComplete = async() =>{

    let workinfo = [];

    // setRefresh((refresh)=>refresh +1);

    if(type ==ROOMSIZE.ONESIZE 
      || type == ROOMSIZE.TWOSIZE 
      || type == ROOMSIZE.THREESIZE
      || type == ROOMSIZE.FOURSIZE
      || type == ROOMSIZE.FIVESIZE
      || type == ROOMSIZE.SIXSIZE
      || type == ROOMSIZE.SEVENSIZE
      || type == ROOMSIZE.EIGHTSIZE
      ){

        messages.map((data, index)=>{
          if(data.type == 'response')
          {
            workinfo.push(data);
          } 
        });
        console.log("TCL: _handleReqComplete -> workinfo", workinfo)
      const USERS_ID= user.users_id;



      const roomitems = await ReadRoomByuserid({USERS_ID});

      if(roomitems.length > 0){
        const FindIndex = workinfo.findIndex(x=>x.requesttype == '지역');

        let min = 1;
        let max = 9;

        let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        let randomInt2 = Math.floor(Math.random() * (max - min + 1)) + min;
     
        let latitude = workinfo[FindIndex].latitude + (randomInt * 0.0001);
        let longitude = workinfo[FindIndex].longitude + (randomInt2 * 0.0001);
        workinfo[FindIndex].latitude = latitude;
        workinfo[FindIndex].longitude =  longitude;
      }


      const ROOM_INFO = workinfo;
      const ROOMTYPE = type;
      const WORK_STATUS = WORKSTATUS.OPEN;
      const NICKNAME = user.nickname;
      const room = await CreateRoom({USERS_ID, ROOMTYPE, ROOM_INFO,NICKNAME, WORK_STATUS});
      setRegistRoomSuccess(true);
  
    }else{

      messages.map((data, index)=>{
        if(data.type == 'response')
        {
          workinfo.push(data);
        } 
      });


      const USERS_ID= user.users_id;
      const workitems = await ReadWorkByuserid({USERS_ID});

      if(workitems.length > 0){
        const FindIndex = workinfo.findIndex(x=>x.requesttype == '지역');

        let min = 1;
        let max = 9;

        let randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        let randomInt2 = Math.floor(Math.random() * (max - min + 1)) + min;
     
        let latitude = workinfo[FindIndex].latitude + (randomInt * 0.0001);
        let longitude = workinfo[FindIndex].longitude + (randomInt2 * 0.0001);
        workinfo[FindIndex].latitude = latitude;
        workinfo[FindIndex].longitude =  longitude;
      }

      const USERIMG = user.userimg;
      const WORK_INFO = workinfo;
      const WORKTYPE = type;
      const NICKNAME = user.nickname;
      const WORK_STATUS = WORKSTATUS.OPEN;
      const work = await CreateWork({USERS_ID,USERIMG,USERIMG,WORKTYPE, WORK_INFO,NICKNAME,WORK_STATUS});
      setRegistWorkSuccess(true);

    }



  }
  /**
   * DatePicker에서 쓰는 함스
   */
  const handleDateChange = (newDate) => {

    console.log("new date", newDate);
    setSelectdate(newDate.toLocaleDateString());
    setRefresh((refresh) => refresh +1);
  };

  /**
   * 콤보박스에서 선택한 결과값이 오는데 기간 설정
   */
  const selectcallback =(data)=>{

    if(data == PCDAYMENU.ALLWEEKS)
    {
      setAllweeks(true);
    }else{
      setAllweeks(false);
    }
    setRefresh((refresh) => refresh + 1);
  }
  /**
   * 기간 계산시 사용 중복여부를 체크하고 중복이 안되어 잇으면 배열에 넣는다
   * 예제 ) 매주 월요일 화요일 수요일
   */
  const _handleWeekDate = (Date) =>{
    const FindIndex = dayitems.findIndex(x=>x == Date);
    if(FindIndex != -1){
      dayitems.splice(FindIndex, 1);
    }else{
      dayitems.push(Date);
    }
    setDayitems(dayitems);
    setRefresh((refresh) => refresh + 1);
  }

  /**
   * 기간 계산시 사용
   */
  function FindDay(Date){
    const FindIndex = dayitems.findIndex(x=>x == Date);
    console.log("TCL: _handleWeekDate -> dayitems", dayitems, Date)

    if(FindIndex != -1){
      return true;
    }else{
      return false;
    }
  }

  /**
   * 단계여부를 체크하는 함수
   * response에서 responseshow는 고객 지도와 공간사진을 보여주지 않도록 하기 위해 미리 설정 해두는 장치
   */

  function seekstepcheck(index){

    return 0;

  }

  /**
   * 
   */
  const imageuploadcallback =(img)=>{
    
    setRoomimg(img);
    setRefresh((refresh) => refresh +1);
  }

  const worksuccesscallback =() =>{
    setRegistWorkSuccess(false);
    setRefresh((refresh) => refresh +1);
    _handleReset();
    navigate("/PClist");
  }

  

  return (
    <>
      {
        registWorkSuccess == true && <MobileSuccessPopup callback={worksuccesscallback} content ={'정상적으로 등록되었습니다'} />
      }

      <Container style={containerStyle}>
 

        <div style={{width:"30%", margin:"0 auto" }}>
          <RegistHeader>

            <AroundRow style={{width:"100%", paddingTop:40}}>
              <Row>
                {
                  IsWork(type) &&<img src={Seekimage(type)} style={{width:40}}/>
                }
                <Title>
                  {
                    IsWork(type) ? (<div>{type}</div>) :(<div>{type}공간대여</div>)
                  }
              
                </Title>
              </Row>

              <Row style={{alignItems:"unset"}}>
                  <progress class="progress" id="progress" value={stepdata/totalset *100} min="0" max="100" style={{width:230}}></progress>
                  <div style={{paddingLeft:10}}>
                    <div style={{display:"flex"}}>
                      <Text containerStyle={{fontFamily:"Pretendard-Bold"}} value={ parseInt(stepdata/totalset *100) + '%'} size={18} color={'#FF4E19'} ></Text>
                    </div>
                  </div>   
              </Row>
            </AroundRow>

            <ProgressLayer progress={parseInt(stepdata / totalset * 100 + 25)}>
                <ProgressLayerText>
                {
                  stepdata == 0 ? (
                    <>
                      <span style={{color:"#FFF"}}>총{totalset}</span><span>단계를 설정 하면  등록이 완료됩니다</span>
                    </>
                  ):(
                    <>
                      <span >총{totalset} 단계중</span><span style={{color:"#FFF", fontWeight:700, marginLeft:5}}>{stepdata}단계</span><span>를 설정하였습니다</span>
                    </>
                  )
                }
                </ProgressLayerText>
            
            </ProgressLayer>


          </RegistHeader>


        <ContentLayer>
        {
          messages.map((data, index) => (
          <Fragment>
          {("initialize" == data.type && data.show == true) && (
              <Itemlayer width={'90%'} style={{marginTop:140}}>      
                <ItemLeftBox width={'90%'}>
                <span style={{letterSpacing:"-1px", width:"100%"}}>{data.info}</span> 
                </ItemLeftBox>       
              </Itemlayer>
          )}
          {/* 고객요구사항 선택 */}
          {("request" == data.type && data.show == true) && (
              <div className="fade-in-bottom" style={{width:"100%"}}>
                <Itemlayer width={'90%'}>
                  <ItemLeftBox width={'90%'}>
                    <FlexstartRow>
                    <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
                    </FlexstartRow>
          
                  
                        <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                        { data.selectitems.map((subdata)=>(
                          <SelectLayer  check={subdata.selected} onClick={()=>{_handlecheck(index, subdata.key)}}>
                            <div>{subdata.request}</div>
                            {/* {
                              subdata.selected == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                            } */}
                            
                          </SelectLayer>
                        ))}
                        </BetweenRow>

                        <Button containerStyle={{border: '1px solid #ececec', fontSize:14}} onPress={()=>{_handleNext(index)}} height={'34px'} width={'100%'} radius={'4px'}  bgcolor={'#fff'} color={'#131313'} text={'저장'}/>

                      
                  </ItemLeftBox>  
                </Itemlayer>
              </div>
          )}
          {/* 날짜 선택 */} 
          {("requestdate" == data.type && data.show == true) && (
            <div className="fade-in-bottom" style={{width:"100%"}}>
              <Itemlayer width={'90%'}>
                  <ItemLeftBox width={'90%'}>
                  <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
                    <div style={{marginTop:15}}>
                    {
                      messages[2].result =='정기적' ? (  
                        <Fragment>

                        <SelectItem
                          option={OPTIONTYPE.DAYOPTION}
                          callback={selectcallback}
                        />
                        {
                          allweeks == true && <Row style={{flexWrap:"wrap", justifyContent:"flex-start", gap:"10px", marginTop:"5px"}}>
                            


                            <DayBtn onClick={()=>{_handleWeekDate('일')}} enable ={FindDay('일')} >
                              일
                              {/* {
                                FindDay('일') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>
                            <DayBtn onClick={()=>{_handleWeekDate('월')}} enable ={FindDay('월')}>
                              월
                              {/* {
                                FindDay('월') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>
                            <DayBtn onClick={()=>{_handleWeekDate('화')}}  enable ={FindDay('화')} >
                              화
                              {/* {
                                FindDay('화') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>     
                            <DayBtn onClick={()=>{_handleWeekDate('수')}}  enable ={FindDay('수')} >
                              수
                              {/* {
                                FindDay('수') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>  
                            <DayBtn onClick={()=>{_handleWeekDate('목')}}  enable ={FindDay('목')} >
                              목
                              {/* {
                                FindDay('목') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>  
                            <DayBtn onClick={()=>{_handleWeekDate('금')}}  enable ={FindDay('금')} >
                              금
                              {/* {
                                FindDay('금') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              } */}
                            </DayBtn>  
                            <DayBtn onClick={()=>{_handleWeekDate('토')}}  enable ={FindDay('토')} >
                              토
                              {/* {
                                FindDay('토') == true ? (<div style={{paddingLeft:10}}><img src={imageDB.enablecheck} style={{width:"16px", hieght:"14px"}}/></div>):(<div style={{paddingLeft:10}}><img src={imageDB.check_d} style={{width:"16px", hieght:"14px"}}/></div>)
                              }          */}
                              
                            </DayBtn>


                          </Row>
                        }

                        <Button containerStyle={{border: '1px solid #ececec', fontSize:14, marginTop:10}} onPress={()=>{_handleDateNext(index)}} height={'34px'} width={'100%'} radius={'4px'}  bgcolor={'#fff'} color={'#131313'} text={'저장'}/>
                        </Fragment>
                      ):(<Fragment>
                        <StyledCalendarWrapper>
                            <StyledCalendar
                              value={selectdate}
                              onChange={handleDateChange}
                              formatDay={(locale, date) => moment(date).format("D")} // 일 제거 숫자만 보이게
                              formatYear={(locale, date) => moment(date).format("YYYY")} // 네비게이션 눌렀을때 숫자 년도만 보이게
                              formatMonthYear={(locale, date) => moment(date).format("YYYY. MM")} // 네비게이션에서 2023. 12 이렇게 보이도록 설정
                              calendarType="gregory" // 일요일 부터 시작
                              showNeighboringMonth={false} // 전달, 다음달 날짜 숨기기
                              next2Label={null} // +1년 & +10년 이동 버튼 숨기기
                              prev2Label={null} // -1년 & -10년 이동 버튼 숨기기
                              minDetail="year" // 10년단위 년도 숨기기
                              minDate={new Date()} // 오늘 날짜 이전은 선택 불가
                              locale={ko} // 한국어 로케일 설정
                              dateFormat="yyyy년 MM월 dd일" // 한국어 형식으로 날짜 표시
                            />
                        </StyledCalendarWrapper>

                        <Button containerStyle={{border: '1px solid #ececec', fontSize:14, marginTop:30}} onPress={()=>{_handleCalendarDateNext(index)}} height={'34px'} width={'100%'} radius={'4px'}   bgcolor={'#fff'} color={'#131313'} text={'저장'}/>
                      </Fragment> )
                    }

                    </div>
                  </ItemLeftBox>       
                </Itemlayer>

            </div>      
          )}
          {/* 대상 선택 */}   {/* 시간과 금액 선텍 */}   {/* 도움받을 사람 선택 */} 
          {("requesttarget" == data.type && data.show == true) && (
            <div className="fade-in-bottom" style={{width:"100%"}}>
              <Itemlayer width={'90%'}>
                  <ItemLeftBox width={'90%'}>
                  <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
                  
                  {/* 대상 */}
                  {
                    data.targetpositionselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'대상'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.targetpositionselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handletargetpostioncheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>            
                      </SelectLayer>
                    ))}
                    </BetweenRow>
                    </>
                  }

                  {/* 범위 */}
                  {
                    data.targetareaselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'범위'}</span> 
                    </FlexstartRow>
      
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.targetareaselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handletargetareacheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>                        
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 작업시간 */}
                  {
                    data.timeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                      <img src={imageDB.memo2} style={{width:18}} />
                      <span style={{paddingLeft:5}}>{'홍여사 작업시간'}</span> 
                    </FlexstartRow>

                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.timeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handletimecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 예상금액 */}
                  {
                    data.moneyselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'홍여사 예상금액'}</span> 
                    </FlexstartRow>

                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.moneyselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlemoneycheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 장보기옵션 */}
                  {
                    data.prepareoptionselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'장보기옵션'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.prepareoptionselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handleoptioncheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 식사준비내용 */}
                  {
                    data.preparecontentselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'식사준비내용'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.preparecontentselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlecontentcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>            
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 시간대별 식사 */}
                  {
                    data.preparecaseselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'홍여사 식사준비'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.preparecaseselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlepreparecasecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 도움종류  */}
                  {
                    data.helpcontentselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'도움종류'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.helpcontentselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlehelpcontentcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 등하원대상  */}
                  {
                    data.goouttargetselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'등하원장소'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.goouttargetselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlegooutschooltargetcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 등하원시간  */}
                  {
                    data.goouttimeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'등하원시간'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.goouttimeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlegooutschooltimecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 아이돌봄시간  */}
                  {
                    data.babycaretimeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'아이돌봄시간'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.babycaretimeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlebabycaretimecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 아이돌봄대상  */}
                  {
                        
                    data.babycaretargetselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'아이돌봄대상'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.babycaretargetselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlebabycaretargetcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 아이돌봄장소  */}
                  {
                    data.babycarerangeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'아이돌봄장소'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.babycarerangeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlebabycarerangecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 레슨시간대  */}
                  {      
                    data.lessontimeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'레슨시간대'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.lessontimeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlelessontimecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 레슨과목  */}
                  {
                    data.lessonrangeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'레슨과목'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.lessonrangeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlelessonrangecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 간병시간대  */}
                  {
                    data.patientcaretimeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'간병시간대'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.patientcaretimeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlepatienttimecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 간병대상  */}
                  {
                    data.patientcaretargetselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'간병대상'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.patientcaretargetselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlepatienttargetcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 간병장소  */}
                  {
                    data.patientcarerangeselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'간병장소'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.patientcarerangeselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlepatientrangecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 환자성별  */}
                  {
                    data.patientcaregenderselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'환자성별'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.patientcaregenderselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlepatientgendercheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 집안수리  */}
                  {
                    data.carryloadcontentselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'집안수리'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.carryloadcontentselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlecarryloadcontentcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 병원같이갈 대상  */}
                  {
                    data.hospitaltargetselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'병원같이갈 환자'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.hospitaltargetselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlehospitaltargetcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 병원같이갈 대상성별  */}
                  {
                    data.hospitalgenderselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'병원같이갈 환자성별 '}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.hospitalgenderselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlehospitalgendercheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 학교행사 내용 */}
                  {
                    data.schooleventselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'학교행사 내용  '}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.schooleventselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handleschooleventcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }
                  {/* 장볼곳 내용 */}
                  {
                    data.shoppingcontentselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'장볼곳'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.shoppingcontentselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handleshoppingcontentcheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }

                  {/* 희망성별 */}
                  {
                    data.helpgenderselectitems != undefined && <>
                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'홍여사 희망성별'}</span> 
                    </FlexstartRow>
              
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.helpgenderselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlehelpgendercheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>
                      </SelectLayer>
                    ))}
                    </BetweenRow>

                    </>
                  }


                  {/* 희망연령대 */}
                  {
                    data.helpageselectitems != undefined && <>

                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'홍여사 희망연령대'}</span> 
                    </FlexstartRow>
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.helpageselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlehelpagecheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>        
                      </SelectLayer>
                    ))}
                    </BetweenRow>
                    </>
                  }

                  {/* 요리비법옵션 */}
                  {
                    data.recipeoptionselectitems != undefined && <>

                    <FlexstartRow style={{marginTop:20}}>
                    <img src={imageDB.memo2} style={{width:18}} />
                    <span style={{paddingLeft:5}}>{'요리비법옵션'}</span> 
                    </FlexstartRow>
                    <BetweenRow top={5} style={{flexWrap:'wrap', margin: '10px 0px'}}>
                    { data.recipeoptionselectitems.map((subdata)=>(
                      <SelectLayer  check={subdata.selected} onClick={()=>{_handlerecipeoptioncheck(index, subdata.key)}}>
                        <div>{subdata.request}</div>        
                      </SelectLayer>
                    ))}
                    </BetweenRow>
                    </>
                  }


                  <Button containerStyle={{border: '1px solid #ececec', fontSize:14}} onPress={()=>{_handleTargetNext(index)}} height={'34px'} width={'100%'} radius={'4px'}   bgcolor={'#fff'} color={'#131313'} text={'저장'}/>     
                  </ItemLeftBox>       
                </Itemlayer>

            </div>      
          )}
    

          {/* 지역 선택 */} 
          {("requestregion" == data.type && data.show == true) && (
          <div className="fade-in-bottom" style={{width:"100%"}}>
            <Itemlayer width={'90%'}>
            <ItemLeftBox style={{width:"90%"}}>
              <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
              <Column>


              <Row style={{width:"100%"}}>
              <Button containerStyle={{fontSize:14, marginTop:"10px", marginRight:"5px"}} onPress={()=>{_handleCurrentRegionNext(index)}} height={'34px'} width={'50%'} radius={'4px'} bgcolor={'#fff'} color={'#131313'} text={'기본주소지'}/>  
              <Button containerStyle={{ fontSize:14, marginTop:"10px"}} onPress={()=>{_handleRegionNext(index)}} height={'34px'} width={'50%'} radius={'4px'} bgcolor={'#ecebeb'} color={'#131313'} text={'주소선택'}/>    
              </Row>
    
            
              {
                addrpopup == true && <MobileWorkMapPopup callback={workmapPopupcallback} />
              }
            
              </Column>

              {
                address != '' && <FlexstartRow style={{marginTop:15}}>
                <img src={imageDB.movegps} style={{width:24}}/>
                <div style={{fontSize:"14px"}} >{address}</div>
             </FlexstartRow>
              }

            </ItemLeftBox>  
          </Itemlayer>  
          </div>
          )}
          {/* 홍여사에게 요청할 내용 */} 
          {("requestcomment" == data.type && data.show == true) && (
          <div className="fade-in-bottom" style={{width:"100%"}} ref={useCommentRef}>
            <Itemlayer width={'85%'}>
              <ItemLeftBox style={{width:"85%", height:"180px"}}>
                <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
                <div style={{marginTop:15, height:200,  top: '30px'}}>
                  <textarea maxlength={40} style={CommentContent} value={comment}  onChange={(e) => {setComment(e.target.value);}}
                  placeholder={'필수입력사항아님. 40자 이내로 입력'}
                  />
                  <Row>
                    <Button containerStyle={{border: '1px solid #ececec', fontSize:16, marginTop:10}} onPress={()=>{_handleCommentNext(index)}} height={'34px'} width={'100%'} radius={'4px'}   bgcolor={'#636363'} color={'#fff'}text={'저장'}/>
                  </Row>
                </div>
              </ItemLeftBox>  
            </Itemlayer>  
          </div>
          )}
            {/* 공간 선택 */} 
          {("requestroom" == data.type && data.show == true) && (
          <div className="fade-in-bottom" style={{width:"100%"}}>
                  <Itemlayer width={'100%'}>
                  <ItemLeftBox style={{width:"100%", height:"350px"}}>
                    <span style={{letterSpacing:"-1px"}}>{data.info}</span> 
                    <Column>
                      <ImageUploadComponent callback={imageuploadcallback}/>
                      <Button containerStyle={{border: '1px solid #ececec', fontSize:16, marginTop:10, fontWeight:600}} onPress={()=>{_handleRoomNext(index)}} height={'34px'} width={'100%'} radius={'4px'}  bgcolor={'#636363'} color={'#fff'} text={'저장'}/>
                    </Column>
                  </ItemLeftBox>  
                </Itemlayer>  
          </div>
          )}
          {/* 요구사항 문서 확인 */} 
          {("requestcomplete" == data.type && data.show == true) && (
            <div className="fade-in-bottom" style={{width:"100%"}} ref={useCompleteRef}>
                <Itemlayer width={'90%'}>
                <ItemLeftBox width={'90%'}>
                  <span style={{fontSize:14}}>{data.info}</span>
                  <table className="workregist-table" style={{marginTop:20}}>
            
                    <tbody>
                      {
                        messages.map((data)=>(
                          <>
                          {
                          data.type =='response' &&
                          <tr>
                          <td>{data.requesttype}</td>
                          <td>
                          {
                            data.requesttype == REQUESTINFO.ROOM ? (
                              <img src= {data.result} style={{width:"160px", height:"160px", objectFit:"contain"}}/>
                            ) :(<div>
                              {
                                data.requesttype == REQUESTINFO.REQDATE ? (<div>{getDateFullTime(data.result)}</div>) :
                                (<div>{data.result}</div>)
                                      
                              } 
                              </div>)
                          }  
                          </td>
                          </tr>
                          }
                          </>                  
                        ))
                      }
                    </tbody>
                  </table>
                  <div style={{display:"flex", flexDirection:"row", margin:'10px auto', width:'100%',justifyContent: "space-between" }}>
        
                    <IconButton  icon ={'reset'} containerStyle={{border: '1px solid #C3C3C3', fontSize:16, marginTop:10, fontWeight:600}} onPress={_handleReset} height={'44px'} width={'48%'} radius={'4px'} bgcolor={'#FFF'} color={'#131313'} text={'다시작성하기'}/>
                    <IconButton  icon ={'save'}  containerStyle={{border: 'none', fontSize:16, marginTop:10, fontWeight:600}} onPress={()=>{_handleReqComplete(index)}} height={'44px'} width={'48%'} radius={'4px'} bgcolor={'#FF7125'} color={'#fff'} iconcolor={'#fff'} text={'등록하기'}/>

                  </div>
                </ItemLeftBox>  
                </Itemlayer>
            </div>
          )}
    
          </Fragment>
        ))}
        </ContentLayer>
        </div>
      </Container>
    </>
  );
}

export default PCRegistcontainer;
