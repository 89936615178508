import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';

import PCChatcontainer from "../../container/PCmain/PCChatcontainer";

import { UserContext } from "../../context/User";
import PCChatLayout from "../../screen/LayoutPC/Layout/PCChatLayout";

import PCLayout from "../../screen/LayoutPC/Layout/PCLayout";
import { PCMAINMENU } from "../../utility/screen";

import { Helmet } from "react-helmet";
import PCGateLayout from "../../screen/LayoutPC/Layout/PCGateLayout";

const Container = styled.div`

`
const style = {
  display: "flex"
};

const PCChatpage =() =>  {

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    async function FetchData(){
    } 
    FetchData();
  }, [])
  useEffect(()=>{

  },[refresh])

 
  return (
    <>
    <Helmet>
    <title>채팅하기</title>
    <meta name="description" content="구해줘 홍여사에서는 체팅을 통해 실시간으로 안전하게 도움 거래를 할수가 있어요" />
    <link rel="canonical" href="https://honglady.com/PCChat" />
    </Helmet>
    <PCGateLayout main={true} name={""} height={80}>
        <PCChatcontainer/>
    </PCGateLayout>
    </>
  );

}

export default PCChatpage;

