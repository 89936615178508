import React, { Component, createRef, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../context/User";
import moment from "moment";
import { imageDB, Seekimage } from "../../utility/imageData";
import { BetweenRow, FlexEndRow, FlexstartRow, Row } from "../../common/Row";
import { ReadRoomByIndividually, ReadWork, ReadWorkByIndividually } from "../../service/WorkService";
import { CommaFormatted } from "../../utility/money";
import { WORKSTATUS } from "../../utility/status";
import IconButton from "../../common/IconButton";
import { Column } from "../../common/Column";
import { DataContext } from "../../context/Data";
import { FaArrowLeft } from "react-icons/fa";

import "./PCmap.css"
import { Any } from "@react-spring/web";
import Button from "../../common/Button";
import PcFilterPopup from "../../modal/PcFilterPopup/PcFilterPopup";
import PCWorkMapItem from "../../components/PCWorkMapItem";
import { IoSearchCircle } from "react-icons/io5";
import { ref } from "firebase/storage";
import { sleep, useSleep } from "../../utility/common";
import { REQUESTINFO, WORKNAME, WORKPOLICY } from "../../utility/work";
import { FILTERITMETYPE, PCMAINMENU } from "../../utility/screen";
import Position from "../../components/Position";
import { ReadRoom } from "../../service/RoomService";
import PCRoomMapItem from "../../components/PCRoomMapItem";

import { IoMdTennisball } from "react-icons/io";
import Requestdoc from "../../components/Requestdoc";
import { CreateChat, ReadChat } from "../../service/ChatService";
import { Readuserbyusersid } from "../../service/UserService";
import MobileSuccessPopup from "../../modal/MobileSuccessPopup/MobileSuccessPopup";
import { LoadingChatAnimationStyle, LoadingListStyle, LoadingMapStyle } from "../../screen/css/common";
import LottieAnimation from "../../common/LottieAnimation";
import WorkerregistPopup from "../../modal/WokerregistPopup/WorkerregistPopup";
import { WORKER } from "../../store/menu/MenuSlice";
import { useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import {motion} from 'framer-motion';

const Container = styled.div`
  max-height:1000px;
  width: 70%;
  margin : 0 auto;
  
`
const mapstyle = {
  position: "absolute",
  overflow: "hidden",
  top: '10%',
  width:'70%',
};
const ListContainer= styled.div`

  overflow-y: scroll;
  height: calc(100vh - 200px);
  margin-bottom: 100px;
  margin-top:20px;
  &::-webkit-scrollbar{
    display: none;
  }
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
`

const WorkLayoutstyle={
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems:"flex-end"
}
const WorkItemPopupstyle={
    height: '100%',
    width: '350px',
    backgroundColor: 'rgb(240, 240, 240)',
    zIndex: 5,
    position: "absolute",
    top: '80px',
}
const WorkItemPopupClosestyle={
  position: "absolute",
  right: '-70px',
}
const GuideStyle={
  position:"absolute",
  right:'20px',
  bottom:'20px',
  zIndex:5,
  display:"flex",
  flexDrirection :"row",
  display:'flex',
  justifyContent:"center",
  alignItems:"center"

}
const GuideTextStyle={
  background: '#a1a2a4a3',
  color: '#fff',
  padding: '5px 10px',
  display:'flex',
  justifyContent:"center",
  alignItems:"center"

}
const GuideButtonStyle={
  background: '#1960ff',
  color: '#fff',
  padding: '5px 10px',
  marginRight:10,
  borderRadius: 10,
  display:'flex',
  justifyContent:"center",
  alignItems:"center"
}
const ParentPopup = styled.div`
  height: 600px;
`

const PopupWorkEx = styled.div`
    position: absolute;
    width: 350px;
    background: #f9f9f9;
    border :2px double;
    height: 100%;
    z-index: 2;
    padding:0px 10px;
    top: 100px;
    left:18%;
    height:750px;
    overflow-y:auto;
 

`

const FilterEx = styled.div`
  position: fixed;
  width: 500px;
  z-index: 10;
  padding: 0px 10px;
  bottom: 40px;
  left: 33%;

`
const ViewBtn = styled.div`
  margin: 20px auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #131313;
  height: 47px;
  font-size: 18px;
  font-family: Pretendard-SemiBold;
  width: 33%;
  background: #fbf7f4;
  border: 1px solid #000;

`

const PlusButtonLayer = styled.div`
  position: fixed;
  width: 500px;
  z-index: 10;
  padding: 0px 10px;
  bottom: 50px;
  right: 5%;

`

const PlusBtn = styled.div`
  margin: 20px auto 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #fff;
  height: 47px;
  font-size: 30px;
  font-family: Pretendard-SemiBold;
  width: 70px;
  background: #ff7e19;
`

const ItemLayer = styled.div`
  display:flex;
  flex-direction :row;
  justify-content: center;
  align-items:center;
  width:100%; 
  flex-wrap:wrap;
`

const Item = styled.div`
  display:flex;
  flex-direction :column;
  justify-content: center;
  align-items:flex-start;
  width:${({width}) => width}; 
  height:46px;
  margin-top:10px;

`
const FullItem = styled.div`
  display:flex;
  flex-direction :column;
  justify-content: center;
  align-items:flex-start;
  width:100%; 
  height:46px;

  margin-top:10px;

`

const ItemLabel = styled.div`

  color :#131313;
  font-weight : 700;
  font-size :14px;
  line-height:18.2px;
`
const ItemContent = styled.div`
  color :#636363;
  font-weight: 400;
  font-size:15px;
  line-height: 21.8px;
`


const RegistLayer = styled.div`
  color: rgb(255, 126, 25);
  font-family: Pretendard-SemiBold;
  position: fixed;
  bottom: 110px;
  width: 70%;
  background: #fff;
  border: 4px solid #ededed;
  border-radius: 10px;
  z-index :10;
`

const Box = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction:column;
  height: 90px;
  width: 12%;
  margin-right: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 15px;

`
const BoxImg = styled.div`
  background: #f9f9f9;
  border-radius: 100px;
  background: ${({clickstatus}) => clickstatus == true ? ('#f8f8f8') :('#f8f8f8;') };
  border: ${({clickstatus}) => clickstatus == true ? ('3px solid #ededed') :('') };
  padding: 10px;
`
/**
/**
 * 카카오맵을 연동 하기 위해서 kakao 변수를 선언 해둔다
 */
const { kakao } = window;

const DetailLevel = 2;
const DetailMeter =300;
const BasicLevel =6;

/**
 * 홍여사의 핵심서비스
 * dataContext에 있는 WorkItem 정보를 가져 와서 뿌려준다
 * 화면은 크게 세개로 구성 되어 있다
 * 좌측 : 일감을 나열
 * 중간(팝업형태) :좌측일감이나 우측일감을 누르면 팝업 형태로 나옴 (기본은 나오지 않는 상태를 유지)
 * 우측 : 지도 형태로 표현
 * TODO 시용자 지역범위를 설정 할수 있도록 하자
 * TODO 사용자 지역범위 내에서 가장 먼거리 부터 표현
 * @returns 
 */

const WorkItems=[
  {name : WORKNAME.HOMECLEAN, img:imageDB.house},
  {name :WORKNAME.BUSINESSCLEAN, img:imageDB.business},
  {name :WORKNAME.MOVECLEAN, img:imageDB.move},
  {name :WORKNAME.FOODPREPARE, img:imageDB.cook},
  {name :WORKNAME.ERRAND, img:imageDB.help},
  {name :WORKNAME.GOOUTSCHOOL, img:imageDB.gooutschool},
  {name :WORKNAME.BABYCARE, img:imageDB.babycare},
  {name :WORKNAME.LESSON, img:imageDB.lesson},
  {name :WORKNAME.PATIENTCARE, img:imageDB.patientcare},
  {name :WORKNAME.CARRYLOAD, img:imageDB.carry},
  {name :WORKNAME.GOHOSPITAL, img:imageDB.hospital},
  {name :WORKNAME.RECIPETRANSMIT, img:imageDB.recipe},
  {name :WORKNAME.GOSCHOOLEVENT, img:imageDB.schoolevent},
  {name :WORKNAME.SHOPPING, img:imageDB.shopping},
  {name :WORKNAME.GODOGHOSPITAL, img:imageDB.doghospital},
  {name :WORKNAME.GODOGWALK, img:imageDB.dog},
]

const PCMapcontainer =({containerStyle, ID, TYPE}) =>  {

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const reduxdispatch = useDispatch();


  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const [selectworkitemindex, setSelectworkitemindex] = useState(-1);
  const [selectroomitemindex, setSelectroomitemindex] = useState(-1);
  const [item, setItem] = useState({});

  const [guidedisplay, setGuidedisplay] = useState(false);
  const [overlays, setOverlays] = useState([]);



  const [popupstatus, setPopupstatus] = useState(false);
  const [curmap, setCurMap] = useState({});
  const [circle, setCircle] = useState(null);

  const [supporterwork, setSupporterwork] = useState(false);
  const [ownerwork, setOwnerwork] = useState(false);
  const [closework, setClosework] = useState(false);
  const [supportWorkSuccess, setSupportWorkSuccess] = useState(false);

  const [workerregist, setWorkerregist] = useState(false);

  const [currentloading, setCurrentloading] = useState(true);
  const [plusstatus, setPlusstatus] = useState(false);
  const [totalset, setTotalset] = useState(0);

  const itemRefs = useRef([]);


  const _handleworkselect = (checkmenu) =>{


    let totalset = 0;

    if(checkmenu == WORKNAME.HOMECLEAN){
      setTotalset(WORKPOLICY.HOMECLEAN);
      totalset = WORKPOLICY.HOMECLEAN;
    }else if(checkmenu == WORKNAME.BUSINESSCLEAN){
      setTotalset(WORKPOLICY.BUSINESSCLEAN);
      totalset = WORKPOLICY.BUSINESSCLEAN;
    }else if(checkmenu == WORKNAME.MOVECLEAN){
      setTotalset(WORKPOLICY.MOVECLEAN);
      totalset = WORKPOLICY.MOVECLEAN;
    }else if(checkmenu == WORKNAME.FOODPREPARE){
      setTotalset(WORKPOLICY.FOODPREPARE);
      totalset = WORKPOLICY.FOODPREPARE;
    }else if(checkmenu == WORKNAME.GOOUTSCHOOL){
      setTotalset(WORKPOLICY.GOOUTSCHOOL);
      totalset = WORKPOLICY.GOOUTSCHOOL;
    }else if(checkmenu == WORKNAME.BABYCARE){
      setTotalset(WORKPOLICY.BABYCARE);
      totalset = WORKPOLICY.BABYCARE;
    }else if(checkmenu == WORKNAME.LESSON){
      setTotalset(WORKPOLICY.LESSON);
      totalset = WORKPOLICY.LESSON;
    }else if(checkmenu == WORKNAME.PATIENTCARE){
      setTotalset(WORKPOLICY.PATIENTCARE);
      totalset = WORKPOLICY.PATIENTCARE;
    }else if(checkmenu == WORKNAME.GOHOSPITAL){
      setTotalset(WORKPOLICY.GOHOSPITAL);
      totalset = WORKPOLICY.GOHOSPITAL;
    }else if(checkmenu == WORKNAME.RECIPETRANSMIT){
      setTotalset(WORKPOLICY.RECIPETRANSMIT);
      totalset = WORKPOLICY.RECIPETRANSMIT;
    }else if(checkmenu == WORKNAME.GOSCHOOLEVENT){
      setTotalset(WORKPOLICY.GOSCHOOLEVENT);
      totalset = WORKPOLICY.GOSCHOOLEVENT;
    }else if(checkmenu == WORKNAME.GODOGHOSPITAL){
      setTotalset(WORKPOLICY.GODOGHOSPITAL);
      totalset = WORKPOLICY.GODOGHOSPITAL;
    }else if(checkmenu == WORKNAME.GODOGWALK){
      setTotalset(WORKPOLICY.GODOGWALK);
      totalset = WORKPOLICY.GODOGWALK;
    }else if(checkmenu == WORKNAME.CARRYLOAD){
      setTotalset(WORKPOLICY.CARRYLOAD);
      totalset = WORKPOLICY.CARRYLOAD;
    }else if(checkmenu == WORKNAME.ERRAND){
      setTotalset(WORKPOLICY.ERRAND);
      totalset = WORKPOLICY.ERRAND;
    }else if(checkmenu == WORKNAME.SHOPPING){
      setTotalset(WORKPOLICY.SHOPPING);
      totalset = WORKPOLICY.SHOPPING;
    }


    setRefresh((refresh) => refresh +1);

    navigate("/PCregist",{state :{WORKTYPE :checkmenu, WORKTOTAL : totalset}});

  }


  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);

  useEffect(()=>{
    setLoading(loading);
    setItem(item);
    setItems(items);
    setGuidedisplay(guidedisplay);
    setOverlays(overlays);
    setSelectworkitemindex(selectworkitemindex);
    setSelectroomitemindex(selectroomitemindex);
    setPopupstatus(popupstatus);
    setCurMap(curmap);
    setCircle(circle);
    setSupporterwork(supporterwork);
    setOwnerwork(ownerwork);
    setClosework(closework);
    setSupportWorkSuccess(supportWorkSuccess);
    setWorkerregist(workerregist);
    setCurrentloading(currentloading);
    setPlusstatus(plusstatus);
    setTotalset(totalset);
  },[refresh])

  /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  useEffect(() => {
    document.documentElement.style.scrollbarGutter = 'stable'; // 추가

    if (show) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

/**
 * 전체 일감에서 work_id 만을 가지고 해단 일감 정보를 가져온다 
 */
  function findWorkIndex(work_id, items){
  

   const FindIndex =  items.findIndex(x=>x.WORK_ID  === work_id)

   return FindIndex;
  }

  function findRoomIndex(room_id, items){


   const FindIndex =  items.findIndex(x=>x.ROOM_ID  === room_id)

   return FindIndex;
  }





  /**
   * 페이지에서 넘어 왔을때
   */

  const _handleSelectWork2 = async(index, items, map) =>{
 
    _handleWorkPopup(items[index].WORK_ID, items);

    const FindIndex = items[index].WORK_INFO.findIndex(x=>x.requesttype =='지역');

    let latitude = items[index].WORK_INFO[FindIndex].latitude;
    let longitude =  items[index].WORK_INFO[FindIndex].longitude;
  
    map.setCenter(new kakao.maps.LatLng(latitude, longitude));

     // 마감되었는지 확인 필요
     if(items[index].WORK_STATUS == 1){
      setClosework(true);
     }

  
     const WORK_ID = items[index].WORK_ID;
     
     const WORK_INFO = await ReadWorkByIndividually({WORK_ID});

     if(WORK_INFO.USERS_ID == user.users_id){
        setOwnerwork(true);
     }


    // 지원 햇는지 확인 필요
    const USERS_ID = user.users_id;
    const chatitems = await ReadChat({USERS_ID});


    if(chatitems != -1){
      chatitems.map((data)=>{
        if( data.TYPE == PCMAINMENU.HOMEMENU){
          if(data.INFO.WORK_ID == items[index].WORK_ID){
            if(data.SUPPORTER_ID == USERS_ID){
              setSupporterwork(true);
            }
          }
        }
      })
    }

    setRefresh((refresh) => refresh+1);



  }

  const _handleSelectRoom2 = async(index, items, map) =>{
 
    _handleRoomPopup(items[index].ROOM_ID, items);

    const FindIndex = items[index].ROOM_INFO.findIndex(x=>x.requesttype =='지역');
    console.log("TCL: PCMapcontainer -> ROOM  FindIndex", FindIndex, index)
    
    let latitude = items[index].ROOM_INFO[FindIndex].latitude;
    let longitude =  items[index].ROOM_INFO[FindIndex].longitude;

    map.setCenter(new kakao.maps.LatLng(latitude, longitude));

    // 마감되었는지 확인 필요
    if(items[index].ROOM_STATUS == 1){
      setClosework(true);
      }

  
      const ROOM_ID = items[index].ROOM_ID;
      
      const ROOM_INFO = await ReadRoomByIndividually({ROOM_ID});

      if(ROOM_INFO.USERS_ID == user.users_id){
        setOwnerwork(true);
      }


    // 지원 햇는지 확인 필요
    const USERS_ID = user.users_id;
    const chatitems = await ReadChat({USERS_ID});
    console.log("TCL: PCMapcontainer -> chatitems", chatitems,items[index].ROOM_ID)


    if(chatitems != -1){
      chatitems.map((data)=>{
        if( data.TYPE == PCMAINMENU.ROOMMENU){
          console.log("TCL: PCMapcontainer -> data.INFO.ROOM_ID ", data.INFO.ROOM_ID,items[index].ROOM_ID )
          if(data.INFO.ROOM_ID == items[index].ROOM_ID){
        
            if(data.SUPPORTER_ID == USERS_ID){
              setSupporterwork(true);
            }
          }
        }
      })
    }

    
    setRefresh((refresh) => refresh+1);

  }

  /**
   * 지도에서 클릭 햇을때 사용되는 함수
   */
  const _handleControlFromMap = async (id, items) =>{

    
    let FindIndex = findWorkIndex(id, items);



    if(FindIndex != -1){
      setSelectworkitemindex(FindIndex);
      setSupporterwork(false);
      setOwnerwork(false);
      setClosework(false);
  
    
      setRefresh((refresh) => refresh+1);
      setSelectroomitemindex(-1);
      setItem(items[FindIndex]);
 


       // 마감되었는지 확인 필요
       if(items[FindIndex].WORK_STATUS == 1){
        setClosework(true);
       }

    
       const WORK_ID = items[FindIndex].WORK_ID;
       
       const WORK_INFO = await ReadWorkByIndividually({WORK_ID});

       if(WORK_INFO.USERS_ID == user.users_id){
          setOwnerwork(true);
       }


      // 지원 햇는지 확인 필요
      const USERS_ID = user.users_id;
      const chatitems = await ReadChat({USERS_ID});


      if(chatitems != -1){
        chatitems.map((data, index)=>{
          if( data.TYPE == PCMAINMENU.HOMEMENU){
            if(data.INFO.WORK_ID == items[FindIndex].WORK_ID){
              if(data.SUPPORTER_ID == USERS_ID){
                setSupporterwork(true);
              }
            }
          }
        })
      }


      setRefresh((refresh) => refresh+1);

    }else{
      FindIndex = findRoomIndex(id, items);
      if(FindIndex != -1){
        setSelectroomitemindex(FindIndex);
        setSupporterwork(false);
        setOwnerwork(false);
        setClosework(false);

        setRefresh((refresh) => refresh+1);

        setSelectworkitemindex(-1);
        setItem(items[FindIndex]);


        // 마감되었는지 확인 필요
        if(items[FindIndex].ROOM_STATUS == 1){
         setClosework(true);
        }
 
     
        const ROOM_ID = items[FindIndex].ROOM_ID;
        
        const ROOM_INFO = await ReadRoomByIndividually({ROOM_ID});

        if(ROOM_INFO.USERS_ID == user.users_id){
           setOwnerwork(true);
        }
 
 
       // 지원 햇는지 확인 필요
       const USERS_ID = user.users_id;
       const chatitems = await ReadChat({USERS_ID});
       console.log("TCL: _handleControlFromMap -> chatitems", chatitems)
 

      if(chatitems != -1){
        chatitems.map((data, index)=>{
          if( data.TYPE == PCMAINMENU.ROOMMENU){
            if(data.INFO.ROOM_ID == items[FindIndex].ROOM_ID){
              if(data.SUPPORTER_ID == USERS_ID){
                setSupporterwork(true);
              }
            }
          }
        })
      }



       setRefresh((refresh) => refresh+1);

      }

      setRefresh((refresh) => refresh+1);
    }
 
    setRefresh((refresh) => refresh+1);
  }


    /**
   * 좌측 리스트에서 클릭 햇을때도 이 함수를 호출해 준다
   * 전달 받은 work_id 를 가지고 이에 대한 정보를 불러온다
   * 
   */
  const _handleWorkPopup=async(work_id, items) =>{
    setSelectroomitemindex(-1);
    setSelectworkitemindex(-1);
    setRefresh((refresh) => refresh + 1);
    await useSleep(200);

    const FindIndex = findWorkIndex(work_id, items);
    setSelectworkitemindex(FindIndex);
    
    setItem(items[FindIndex]);
    setRefresh((refresh) => refresh+1);
  }

  const _handleRoomPopup=async(room_id, items) =>{
    setSelectworkitemindex(-1);
    setSelectroomitemindex(-1);
    setRefresh((refresh) => refresh + 1);
    await useSleep(200);

    const FindIndex = findRoomIndex(room_id, items);
    setSelectroomitemindex(FindIndex);
    setItem(items[FindIndex]);
    setRefresh((refresh) => refresh+1);
  }

  const popupcallback = () =>{
    setPopupstatus(false);
    setRefresh((refresh) => refresh +1);
  }

  /**
   * 지도와 리스트 를 그리도록 한다
   * ! 지도객체가 먼저 만들어졌는지 확인이 필요
   * ! 데이타를 먼저 채워준다(setWorkitems) 왜냐하면 지도 그려지기 전에 먼저 좌측에 항목이 나와야 부드럽게 처리
   * ! overlaycustom을 배열로 만들어주고 클릭 이벤트를 넣어준다 클릭이벤트시에 해당 데이타를 가져올수 있으며
   * TODO 해당 객체의 css를 변경할수 있는데 이부분도 주석
   * ! marker도 그려준다
   * ! overlaycustom 클릭시에 해당 클릭한 일감을 중심좌표로 이동시키고 확대 레벨은 DetailLever로 유지 시킨다(초기 구동시에 확대레벨은 4이다)
   * ! map 의 기본 보기 범위는  최대 범위 3 최소 범위 9 그리고 기본 레벨은 4이다
   * TODO 클릭시에 WorkItemPopup 을 표시 해주는 부분 주석
   * ! 현재 주소지에 근거하여 보여줄 범위를 정해준다
   * TODO 현재는 남양주 다산동으로 테스트 하기 위해 설정
   * 리스트에서 위치 이동을 위해 refs 배열에 값을 세팅해준다
   * ! 하이라이트 표시 는 css로 적용
   */
  async function ListmapDraw(datas, ID){

    setLoading(true);
    setRefresh((refresh) =>refresh +1);

    var mapContainer = document.getElementById('map'), // 지도를 표시할 div 
    mapOption = { 
          center: new kakao.maps.LatLng(user.latitude, user.longitude), // 지도의 중심좌표
          level: BasicLevel // 지도의 확대 레벨
    };

    var map = new kakao.maps.Map(mapContainer, mapOption);


    const coords = new window.kakao.maps.LatLng(user.latitude, user.longitude);
    map.setCenter(coords);
    setLoading(false);
    setRefresh((refresh) => refresh +1);
  

    var overlaysTmp = [];
    var overlays = [];

    const latitude = user.latitude;
    const longitude = user.longitude;

     const workdatas = await ReadWork({latitude, longitude});



     let items = [];

     if(workdatas != -1){
        workdatas.map((data, index) =>{
          data["TYPE"] = FILTERITMETYPE.HONG;
          items.push(data);
        })
     }


     console.log("TCL: FetchData -> items", items);
     setItems(items);

    
    for (var i = 0; i < items.length; i ++){
      let overlay = {
        POSITION : {},
        TYPE : "",
        OVERLAYTYPE:"",
        PRICE : "",
        STATUS: "",
        ID : "",
        ITEMS :datas,
      }

      let FindIndex = "";
      let PRICE ="";
      let latitude = "";
      let longitude = "";


      if(items[i].TYPE == FILTERITMETYPE.HONG){
        FindIndex = items[i].WORK_INFO.findIndex(x=>x.requesttype =='금액');
        PRICE = items[i].WORK_INFO[FindIndex].result;
        
        FindIndex = items[i].WORK_INFO.findIndex(x=>x.requesttype =='지역');
  
        latitude = items[i].WORK_INFO[FindIndex].latitude;
        longitude =  items[i].WORK_INFO[FindIndex].longitude;
  
  
        overlay.POSITION = new kakao.maps.LatLng(latitude, longitude);
        overlay.PRICE = PRICE;
        overlay.TYPE = FILTERITMETYPE.HONG;
        overlay.OVERLAYTYPE = items[i].WORKTYPE;
        overlay.STATUS = items[i].WORK_STATUS;
        overlay.ID = items[i].WORK_ID;
        overlay.ITEMS = items
        overlaysTmp.push(overlay);
      }

    }

    console.log("TCL: ListmapDraw -> overlaysTmp", datas,overlaysTmp)

    // 오버레이를 지도에 추가하고 클릭 이벤트 처리
    overlaysTmp.forEach(function(overlayData, index) {
    

        // Custom Overlay 내용 생성
        var content = document.createElement('div');
        content.className = 'mapcustomoverlay';

        if(overlayData.STATUS == WORKSTATUS.OPEN){
          content.innerHTML =
          '  <a>' +
          // '    <div>' +
          // '    <img src="'+ Seekimage(overlayData.OVERLAYTYPE) +'"style="width:32px;"/>' +
          // '    </div>' +
          '    <div class="title">'+overlayData.OVERLAYTYPE +'</div>' +
          '    <div class="price">'+overlayData.PRICE +'</div>' +
          '  </a>' +
          '</div>';
        }else{
          content.innerHTML =
          '  <a style="background:#A3A3A3">' +
          // '    <div>' +
          // '    <img src="'+ Seekimage(overlayData.OVERLAYTYPE) +'"style="width:32px;"/>' +
          // '    </div>' +
          '    <div class="titleclose">'+overlayData.OVERLAYTYPE +'(마감)</div>' +
          '    <div class="priceclose">'+overlayData.PRICE +'</div>' +
          '  </a>' +
          '</div>';
        }

        // Custom Overlay 생성
        var customOverlay = new kakao.maps.CustomOverlay({
            position: overlayData.POSITION,
            content: content,
            clickable: true // 클릭 가능하도록 설정
        });

        var customData = {
          id: overlayData.ID,
          items : overlayData.ITEMS
        };
        customOverlay.customData = customData;
        // Custom Overlay 지도에 추가
        customOverlay.setMap(map);

        overlays.push(customOverlay);
      
        // 클릭 이벤트 등록 
        // 지도에서 클릭 햇을때는 리스트에서 클릭 했을때와 달리 별도로 circle을 표시할 필요는 없다
        content.addEventListener('click', function(event) {
    
          _handleControlFromMap(customOverlay.customData.id, customOverlay.customData.items);

        });

  

    });


    //오버레이를 변수에 담아둔다
    setOverlays(overlays);
    setRefresh((refresh) => refresh +1);


    // 확대/축소 레벨 제한 설정
    const minLevel = 1;
    const maxLevel = 9;


    window.kakao.maps.event.addListener(map, 'drag', () => {
      setSelectworkitemindex(-1);

      setRefresh((refresh) => refresh +1);

      // 드래그 중에 실행할 코드
    });



    setCurMap(map);
    setRefresh((refresh) => refresh +1);
    if(ID != "" && TYPE == PCMAINMENU.HOMEMENU){
 
        const FindIndex = findWorkIndex(ID, datas);

        if(FindIndex == -1){
          return;
        }

        console.log("TCL: ListmapDraw WORK -> FindIndex", FindIndex,datas)
        _handleSelectWork2(FindIndex, datas, map);
    }
 




  
   
  }


  useEffect(()=>{
    async function FetchData(){

  
      ListmapDraw(items, ID);


    } 
    FetchData();
  }, [])

  useEffect(()=>{

    async function FetchData(){
      await sleep(1500);
      setCurrentloading(false);
    }

    FetchData();

    setRefresh((refresh) => refresh +1);
  },[])



  const _handleWorkSupport=async(WORK_ID)=>{


    const WORK_INFO = await ReadWorkByIndividually({WORK_ID});


    let USERS_ID = WORK_INFO.USERS_ID;
    let OWNER = await Readuserbyusersid({USERS_ID});


    if(OWNER == -1){
      return;
    }

    USERS_ID = user.users_id;;
    const SUPPORTER = await Readuserbyusersid({USERS_ID});

    if(SUPPORTER.USERINFO.worker == false){
      setWorkerregist(true);
      setRefresh((refresh) => refresh +1);
      return;
    }


    const OWNER_ID = OWNER.USERS_ID;



    const SUPPORTER_ID = SUPPORTER.USERS_ID;
    const TYPE = PCMAINMENU.HOMEMENU;
    const INFO = WORK_INFO;

    console.log("TCL: _handleReqComplete -> WORK_INFO",OWNER,SUPPORTER,WORK_INFO);


    const createchat = await CreateChat({OWNER, OWNER_ID,  SUPPORTER, SUPPORTER_ID, INFO,
      TYPE});

    setSupportWorkSuccess(true);
    setRefresh((refresh) => refresh +1);



  }

  const _handleRoomSupport=async(ROOM_ID)=>{

    const ROOM_INFO = await ReadRoomByIndividually({ROOM_ID});

  
    let USERS_ID = ROOM_INFO.USERS_ID;
    let OWNER = await Readuserbyusersid({USERS_ID});

    console.log("TCL: _handleReqComplete -> ROOM_INFO", USERS_ID, OWNER)
    if(OWNER == -1){
      return;
    }
    const OWNER_ID = OWNER.USERS_ID;

    USERS_ID = user.users_id;;
    const SUPPORTER = await Readuserbyusersid({USERS_ID});
    const SUPPORTER_ID = SUPPORTER.USERS_ID;
    const TYPE = PCMAINMENU.ROOMMENU;
    const INFO =ROOM_INFO;
    const createchat = await CreateChat({OWNER, OWNER_ID,  SUPPORTER, SUPPORTER_ID, INFO,
      TYPE});

    setSupportWorkSuccess(true);
    setRefresh((refresh) => refresh +1);




  }


  /**
   * TODO 팝업을 닫있을때 지도를 원상 복구 해주자 !!!!
   */
  const _handleClose = () =>{
    setSelectworkitemindex(-1);
    setSelectroomitemindex(-1);
    curmap.setLevel(BasicLevel);
  }
  const positioncallback =()=>{}

  const supportsuccesscallback = () =>{
    setSupportWorkSuccess(false);
    setSelectroomitemindex(-1);
    setSelectworkitemindex(-1);
    setRefresh((refresh) => refresh +1);
  }

  const workerregistcallback = (data) =>{
    setWorkerregist(false);
    setRefresh((refresh) => refresh +1);

    if(data == 'move'){
      reduxdispatch(WORKER());
    }
  }

  const _handleFilter = () =>{

    setPopupstatus(true);
    setRefresh((refresh) => refresh +1);
  }

  const _handlelist = () =>{

    navigate("/PClist");
  }
  const _handlePlus = () =>{
    setPlusstatus(!plusstatus);
    setRefresh((refresh) => refresh +1);
  }



  return (
    <Container style={containerStyle}
    className={`app-container ${plusstatus ? 'blur-background' : ''}`}>
       
      {
        supportWorkSuccess == true && <MobileSuccessPopup callback={supportsuccesscallback} content ={'일감에 정상적으로 지원되었습니다'} />
      }



      {
        selectworkitemindex != -1 && 
        <ParentPopup>
        <PopupWorkEx>
          <Requestdoc INFO ={item.WORK_INFO} TYPE ={item.WORKTYPE} NICKNAME ={item.NICKNAME}/>
          <div style={{display:"flex", flexDirection:"row",marginBottom: "20px",justifyContent: "space-around", width:"80%", margin:"0 auto" }}>
              {
                closework == true ?
                (<Button text={"이미 마감된 일감"}
                containerStyle={{backgroundColor: "#EDEDED", color :"#999",borderRadius: "4px", border:"none",
                fontSize: 16,height:44,width: "50%",}}/> ):(
                  <>
                    {
                      supporterwork == true && 
                      <Button text={"이미 지원한 일감"} 
                      containerStyle={{backgroundColor: "#EDEDED", color :"#999",borderRadius: "4px", border:"none",
                      fontSize: 16,height:44,width: "50%",}}/> 
                    }

                    {
                      ownerwork == true && 
                      <Button text={"본인이 등록한 일감"} 
                      containerStyle={{backgroundColor: "#EDEDED", color :"#999",borderRadius: "4px", border:"none",
                      fontSize: 16,height:44,width: "50%",}}/> 
                  
                    }      
                  </>

                )
            
              }
              {
                (supporterwork ==false && ownerwork == false && closework == false) && 
                <Button text={"지원하기"} onPress={()=>{_handleWorkSupport(item.WORK_ID)}}
                containerStyle={{backgroundColor: "#FF7125", color :"#fff",borderRadius: "4px", border:"none",
                fontSize: 16,height:44,width: "50%",}}/> 

              }

              <Button text={"닫기"} onPress={_handleClose}
              containerStyle={{backgroundColor: "#fff",color :"#131313",border :"1px solid #C3C3C3",borderRadius: "4px", 
              fontSize: 16,height:44, marginRight:5, width: "50%",marginBottom:15, marginLeft:5}}/>   

       
          </div>
        </PopupWorkEx>

        </ParentPopup>

      }

     
    
      { loading == true  ? (<LottieAnimation containerStyle={LoadingListStyle} animationData={imageDB.loadinglarge}
              width={"100px"} height={'100px'} />) :(     <Column>
      
                <div style={{display:"flex", width:'100%'}}>
                  <div id="map" className="Map" style={mapstyle}></div>
                </div>  
        

                {
                  plusstatus == true  ?        
                  (<RegistLayer>
                  <motion.div
                  key={'point'} // 키가 변경될 때마다 애니메이션 재생
                  initial={{ y: 30, opacity: 0 }}  // 시작 상태 (아래에서 시작)
                  animate={{ y: 0, opacity: 1 }}   // 목표 상태 (위로 올라오면서 나타남)
                  transition={{ type: 'spring', stiffness: 100, duration: 1 }} 
                  
                  >
                  <div >

                  <FlexstartRow style={{paddingLeft:20, paddingTop:20}}>{'도움받을 일을 선택해보세요'}</FlexstartRow>

                  <BetweenRow style={{width:"95%",background:"fff", margin:"0 auto"}}>
                      {
                        WorkItems.map((data, index)=>(
                          <Box onClick={()=>{_handleworkselect(data.name)}} >
                            <BoxImg bgcolor ={data.bgcolor} ><img src={data.img} style={{width:35, height:35}}/></BoxImg>
                            <div style={{ fontSize:12, color:"#131313", fontFamily:"Pretendard-SemiBold", marginTop:"5px"}}>{data.name}</div>
                          </Box>
                        ))
                      }
                    </BetweenRow>


                  </div>
                  </motion.div>
                  </RegistLayer>) :(null) 
                }

                <FilterEx style ={{height:'80px'}}>
                  <ViewBtn onClick={_handlelist}>리스트로 보기</ViewBtn> 
                </FilterEx>


                <PlusButtonLayer>
                <PlusBtn onClick={_handlePlus}>
                {
                  plusstatus == true ? (<div style={{display:"flex",fontSize: 30}}>{'-'}</div>):(<div style={{display:"flex",fontSize: 30}}>{'+'}</div>)
                }
                </PlusBtn> 
              </PlusButtonLayer>

              </Column>)  
      }
 



    </Container>
  );

}

export default PCMapcontainer;

