import React, { Fragment, useContext, useEffect, useInsertionEffect, useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imageDB } from "../../../utility/imageData";
import { UserContext } from "../../../context/User";
import { Badge, setRef } from "@mui/material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import Image from "../../../common/Image";
import { WORKNAME } from "../../../utility/work";
import { WORK } from "../../../utility/db";
import { colors } from "../../../theme/theme";
import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import Categorymenu from "../../../common/Categorymenu";
import { CENTERTYPE, CONFIGMOVE, EventItems, EVENTTYPE, GUIDETYPE, LAWTYPE, LoadingType, PCMAINMENU } from "../../../utility/screen";


import { ROOMSIZE, ROOMSIZEDISPALY } from "../../../utility/room";
import { GoPlus } from "react-icons/go";
import { DataContext } from "../../../context/Data";
import { model } from "../../../api/config";
import PcAipopup from "../../../modal/PcAiPopup/PcAiPopup";
import Loading from "../../../components/Loading";
import localforage from 'localforage';

import { useDispatch, useSelector } from "react-redux";
import { ALLWORK, HOMECLEAN,BUSINESSCLEAN,
  MOVECLEAN,FOODPREPARE,ERRAND,GOOUTSCHOOL,BABYCARE,LESSON,PATIENTCARE,CARRYLOAD,
  GOHOSPITAL,RECIPETRANSMIT,GOSCHOOLEVENT,SHOPPING,GODOGHOSPITAL,GODOGWALK,ALLROOM, SMALLROOM, MEDIUMROOM, LARGEROOM, TOURREGION, TOURCOUNTRY, TOURFESTIVAL, TOURCITY, TOURPICTURE, PERFORMANCEEVENT, PERFORMANCECINEMA, MEDICALMEDICINE, MEDICALHOSPITAL, FOODHISTORY, FOODINFOMATION, CONVENIENCECAMPING, COMMUNITY,
  ONESIZE, TWOSIZE, THREESIZE, FIVESIZE, FOURSIZE, SIXSIZE, SEVENSIZE, EIGHTSIZE, RESET} from "../../../store/menu/MenuSlice"
import { CONVENIENCEMENU, FAMILYMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU, WEATHERMENU } from "../../../utility/life";

import "./PCMainheader.css";
import "./PCGateheader.css";
import { sleep } from "../../../utility/common";
import { readuserbyphone, Update_usertoken } from "../../../service/UserService";
import { Column, FlexstartColumn } from "../../../common/Column";
import { MdKeyboardArrowDown } from "react-icons/md";


const PCHeader = styled.div`
  height: ${({height}) => height}px;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 1400px;


`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding:10px 0px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 4%);

`;


const OneHeaderOptionMenu = styled.div`
  display: flex;
  flex-direction:row;
  font-size:14px;
  justify-content:space-around;
  width:40%;
`
const OneHeaderLoginMenu = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 30px;
  align-items: center;
`;

const EventDesc = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-left:20px;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
`

const MainMenuText = styled.div`
  font-size: 20px;
  color: ${({clickstatus}) => clickstatus == true ? ('#ff7e19') :('#131313') };
  font-weight: ${({clickstatus}) => clickstatus == true ? ('600') :('400') };
`

const EventMainText ={
  fontSize: '14px',
  color: '#fff',
  fontFamily:"Pretendard-SemiBold",
  fontWeight:600,
}

const EventSubText ={
  fontSize: '14px',
  color: '#fff',
  fontWeight:400,
}

const EventBtn ={
  display:"flex",
  justifyContent:"space-between",
  width:'30%',

}


const CategoryLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height:30px;
`;
const CategoryItem = styled.div`
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  display: inline-block;
  color: #595959;

`




const OneContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;
  background-color: #f39435;
  position: fixed;
  z-index: 5;
  color: #595959;
  font-size:14px;
`;

const TwoContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  z-index: 5;


  
`;




const LineControl = styled.div`

  width: 100%;
  position: absolute;
  margin-top: 45px;
`;

const Inputstyle ={
  border: '1px solid #C3C3C3',
  background: '#fff',
  width: '100%',
  borderRadius:'5px',
  fontSize:'16px',
  padding :'8px'

}



const Searchstyle={
  position: "relative",
  left: '-35px'
}
const Search2style={
  position: "relative",
  left: '0px',
  top: '5px'
}


const LoginBtn = styled.div`
  padding: 10px 30px;
  background: #ffd6ac;
  border-radius: 10px;
`;

const NavItemLayer = styled.div`

  position: absolute;
  left : ${({left}) => left};
`
const DropdownContent = styled.div`
display: block;
border: none;
height: 110px;
position: absolute;
background-color: #fff;
width: 100%;
z-index: 1;
text-align: left;
color: #636363;
font-size: 14px;
flex-direction: row;
font-weight: 700;
padding: 15px;
top: 120px;
left: 10px;
font-size: 14px;
line-height: 2;
border-bottom: 1px solid #ededed;

`
const MedicalItems= [
  MEDICALMENU.MEDICALMEDICINE,
  MEDICALMENU.MEDICALHOSPITAL,

]

const Attendance = styled.div`

  font-family : Pretendard-SemiBold;
  background: #ff7e19;
  color: #fff;
  border-radius: 20px;
  padding: 3px 10px;
  font-size:12px;
  width:80px;

`

const LogoLayer = styled.div`
  display: flex;
  color : #ff7e19;
  font-size:22px;
  width:20%;
  align-items:center;
  justify-content: flex-start;
`;
const ButtonLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : space-between;
  align-items:center;
  font-size:16px;
  width: 50%;

`

const DownloadLayer = styled.div`

  display : flex;
  flex-direction: row;
  justify-content : flex-end;
  align-items:center;
  font-size:16px;

`
const DownloadButton = styled.div`
  color: #ff7e19;
  background: #ff7e1938;
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 5px;
    
`
const Submenu = styled.div`
  padding:10px 10px;
  font-family:Pretendard-Regular;
  font-size: 14px;
`
const Popupmenu = styled.div`
  width : 110px;
  display : flex;
  flex-direction: column;
  justify-content : flex-start;
  align-items:flex-start;
  position:absolute; 
  top:50px;
  background:#fff;
  padding:10px 0px;
  cursor: pointer;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* 기본 그림자 */
  &:active {

    transform: scale(0.95); /* 눌렀을 때 크기 조정 */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* 눌렀을 때 그림자 축소 */
  }

`

const MeuItems = [
  {url :"/PCList", value:"도움요청",menulabel:"구해줘 홍여사 도움요청"},
  {url :"/PCChat", value:"채팅하기",menulabel:"구해줘 홍여사 채팅하기"},
  {url :"/PClife", value:"일상생활",menulabel:"구해줘 홍여사 라이프생활"},
  {url :"/PCleisure", value:"여가생활",menulabel:"구해줘 홍여사 여가생활"},
  {url :"/PCEvent", value:"이벤트",menulabel:"구해줘 홍여사 이벤트"},
  {url :"/PCGuideInfo", value:"알아보기",menulabel:"구해줘 홍여사 알아보기"},
  {url :"/PCSumpolicy", value:"이용약관",menulabel:"구해줘 홍여사 이용약관"},
]

const BoardItems1 =[
  LIFEMENU.AI,
  LIFEMENU.BOARD,
  LIFEMENU.WORK,
  LIFEMENU.CLIMATE,
  LIFEMENU.RECIPE,

]

const BoardItems2 =[
  TOURISTMENU.TOURCOURSE,
  CONVENIENCEMENU.CONVENIENCECAMPING,
  TOURISTMENU.TOURFESTIVAL,
  TOURISTMENU.TOURREGION,
  LIFEMENU.PICTURE,
  PERFORMANCEMENU.PERFORMANCECINEMA,


]
const BoardItems3 =[
  LIFEMENU.GAME,
  CONFIGMOVE.RULLET,
  LIFEMENU.ATTENDANCE,


]

const PCGateheader = ({ height}) => {

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [subhoveredIndex, setSubhoveredIndex] = useState(null);
  const navigation = useNavigate();
  const {user, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // 드롭다운 상태 관리


  const _handleLifeMenu = (data) =>{
    navigation("/PClife", {state:{TYPE:data}});

  }

  return (
    <>
    <PCHeader height={height}>

      <TwoContainer id="twoheader">
        <BetweenRow style={{height:70, background:"#fff", width:'70%', margin:"0 auto"}} id="twosubheader">
          <LogoLayer onClick={()=>{}}>
            <Row>      
              <img src={imageDB.logo} width={30} height={30} />
              <Link to="/PCGate">
                <div style={{color:"#ff7e19",fontFamily:"Pretendard-SemiBold",display:"flex", justifyContent:"center", alignItems:"center", paddingLeft:10}}>구해줘 홍여사</div>
              </Link>
            </Row>
          </LogoLayer>

          <ButtonLayer>
          {
            MeuItems.map((data, index)=>(
              <Row style={{height:300}}
              onMouseEnter={() => {setHoveredIndex(index);setIsOpen(true);}}
              onMouseLeave={() => {setHoveredIndex(null);setIsOpen(false);}}
              >
              {
                (index == 2 ) &&
                <div 
                key={index}
                style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                className={`menu-item ${hoveredIndex !== null && hoveredIndex !== index ? 'dimmed' : ''}`}
                ><div title={data.value} aria-label={data.menulabel}  style={{marginRight:"2px", maxWidth:80, width:80}}>{data.value}
                </div>
                <div style={{display:"flex"}}><MdKeyboardArrowDown size={20}/></div>   
                {
                  (hoveredIndex == 2 && index == 2 && isOpen == true) && <Popupmenu>
                    {
                      BoardItems1.map((data, subindex)=>(
                        <Submenu 
                        onClick={()=>{_handleLifeMenu(data)}}
                        onMouseEnter={() => {setSubhoveredIndex(subindex);}}
                        onMouseLeave={() => {setSubhoveredIndex(null);}}
                        className={`submenu-item ${subhoveredIndex !== null && subhoveredIndex !== subindex ? 'dimmed' : ''}`}>{data}</Submenu>
                        ))
                    }
                  
                  </Popupmenu>
                }
    
             
                </div>
              }
              {
                (index != 2  ) &&
                <Link 
                key={index}
                className={`menu-item ${hoveredIndex !== null && hoveredIndex !== index ? 'dimmed' : ''}`}
                to={data.url}><a title={data.value} aria-label={data.menulabel}  style={{marginRight:"2px",maxWidth:80, width:80}}>{data.value}
                </a>        
                </Link>
              }
              </Row>   
            ))
          }
          </ButtonLayer>

          <DownloadLayer>
            <Link to="/PCPhone"><DownloadButton>앱 다운로드</DownloadButton></Link>
          </DownloadLayer>



        </BetweenRow>


      
     
      </TwoContainer>

    </PCHeader>
    </>
  );
};

export default PCGateheader;
