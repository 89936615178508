
import { Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { getMainImageURL, useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { LoadingCommunityStyle, LoadingLifeStyle, LoadingListStyle, LoadingSearchAnimationStyle } from "../screen/css/common";
import "../screen/css/common.css"
import { TOURISTMENU } from "../utility/life";
import PCSlickSliderComponentEx from "../common/PCSwipeex";
import axios from "axios";
import { CreateFESTIVAL, ReadFESTITVAL } from "../service/FestivalService";

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  overflow : auto;
  width:100%;

`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '40px',
  border : "4px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  float: left;
  position: relative;
  width: 32%;
  height: 400px;
  margin-right: 1%;
  border-radius: 8px;
  isolation: isolate;
`

const Festival_img = styled.div`
  position: relative;
  height: 270px;
  border-radius: 8px;

` 
const Festival_name = styled.div`
    display: -webkit-box;
    width: 100%;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: break-spaces;
    color: #1A1A24;
    font-family:Pretendard-Bold;
    font-size:18px;
`
const Festival_region = styled.div`
    padding-bottom: 32px;
    color: #666670;
    font-size: 16px;
`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
  position: "absolute",
  top: "300px",
  left : "25%"
}

const ContentLayer = styled.div`
    padding: 10px 15px;
    line-height: 2;

`


const BannerItems =[

  {type:TOURISTMENU.TOURFESTIVAL,image : imageDB.festival, main1:"문화축제", main2:"전국 문화축제주관, 장소, 일시에 대한 정보를 한눈에 볼수 있도록 정리 " +
    "\n" +
    "지역문화에 바탕을 둔 다양한 축제 중 문화체육관광부가 지정한 관광 상품성이 높은 큰 축제정보를 제공 해드립니다", main3:"", color:"#eaf1d9"},
]


const PCLifeTourFestival =({containerStyle}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [search, setSearch] = useState('');
  const [show, setShow] = useState(true);
  const [searching, setSearching] = useState(true);
  const [displayitems, setDisplayitems] = useState([]);

  const [popupstatus, setPopupstatus] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitudie, setLongitude] = useState('');
  const [festivalname, setFestivalname] = useState('');

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{
    setSearching(searching);
    setDisplayitems(displayitems);
    setPopupstatus(popupstatus);
    setLatitude(latitude);
    setLongitude(longitudie);
    setFestivalname(festivalname);
  }, [refresh])

  useEffect(()=>{
      async function FetchData(){
 
        const items = await ReadFESTITVAL({});
        console.log("MATRIX LOG : FetchData : items:", items)
        
        data.tourfestivalitem = items;
        datadispatch(data);
        setDisplayitems(data.tourfestivalitem);
        setSearching(false);
        setRefresh((refresh) => refresh +1);
      }
    

      FetchData();
  }, [])

   /**
   * 페이지내에 스크롤을 막아 주는 코드입니다 
   */
  //  useEffect(() => {
  //   document.documentElement.style.scrollbarGutter = 'stable'; // 추가

  //   if (show) document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'unset';
  //   };
  // }, [show]);
 

  const isPastDate = (dateString) => {
    const targetDate = new Date(dateString);
    const currentDate = new Date();

    
    
    // `getTime()`을 사용하여 날짜를 밀리초로 변환 후 비교
    return currentDate.getTime() > targetDate.getTime();
  };

  const _handleSubmit = async(event) => {
    event.preventDefault();

    Searchfunc();

  };
  const popupcallback = async () => {
    setPopupstatus(!popupstatus);
    setRefresh((refresh) => refresh +1);
  };

  const _handleMapview= (lat, long, festivalname)=>{

    setPopupstatus(true);
    setLatitude(lat);
    setLongitude(long);
    setFestivalname(festivalname);
    setRefresh((refresh) => refresh +1);

  }

  const handleKeyDown = (event)=>{
    if (event.key === 'Enter') {
      Searchfunc();
    }

  }

  async function Searchfunc(){
    setSearching(true);

    await useSleep(2000);
    const keyworditems = searchkeyword(search, data.tourfestivalitem);

    const regionitems = searchRegion(search, data.tourfestivalitem);

    let resultitems = [];
    keyworditems.map((data)=>{resultitems.push(data)});
    regionitems.map((data)=>{resultitems.push(data)});

    setDisplayitems(resultitems);

    setSearching(false);
    setRefresh((refresh) => refresh +1);
  }
  function searchkeyword(data, items){

    const foundItems = items.filter(item => item.fstvlNm.toLowerCase().includes(data.toLowerCase()));

    return foundItems;

  }
  function searchRegion(data, items){

    const foundItems = items.filter(item => item.rdnmadr.toLowerCase().includes(data.toLowerCase()));

    return foundItems;

  }
  return (

    <Container style={containerStyle}>   


    
      <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#f7f1eb'}/>

      {
        popupstatus == true && <MobileMapPopup callback={popupcallback} latitude={latitude} longitude={longitudie}
        top={'30%'}  left={'10%'} height={'280px'} width={'280px'} name={festivalname} markerimg={imageDB.tour}
        />
      }


      {
        searching == true ? (<LottieAnimation containerStyle={LoadingListStyle}  animationData={imageDB.loadinglarge}
          width={"100px"} height={'100px'} />)
        :(
          <Column style={{marginTop:10,width:"100%",margin:"0 auto", paddingTop:"25px"}}>
            {/* <Column style ={{width:"60%", backgroundColor:"#fff"}}>
              <SearchLayer>
                <input  type="text" style={Inputstyle}
                onKeyDown={handleKeyDown} 
                class="communityinput"
                value={search} onChange={(e)=>{
                  setSearch(e.target.value);
                  setRefresh((refresh) => refresh +1);
                
                }}
                placeholder="지역축제 이름, 지역 입력" />

              </SearchLayer>

              <FlexstartRow style={{width:"90%", margin : '10 auto', background:"#fff", paddingBottom:10}}>
                    <img src={imageDB.infocircle} width={16} height={16} o/>
                    <span style={{fontSize:"12px", color :"#636363", marginLeft:5}}>알아보고자 하는 축제 이름이나 지역이름을 넣어주세요</span>                  
              </FlexstartRow>
            </Column> */}
        
      

            <FlexstartRow style={{width:"100%", marginTop:50, marginBottom:10}}>
              <ResultLabel label={'총'} result = {displayitems.length} unit={'건'}/>
            </FlexstartRow>


            <div style={{overflowY:"hidden",width:"100%",margin:"0 auto", display:"flex", flexWrap:"wrap"}}>
            {
              displayitems.map((data, index)=>(
                <>
                  <BoxItem>

                  <Festival_img>
                    <img src ={data.FESTIVALITEM.dispFstvlCntntsImgRout} style={{width:"100%",height:"100%",borderRadius:5}}/>
                  </Festival_img>
                  <ContentLayer>
                    <Festival_name>
                      {data.FESTIVALITEM.cntntsNm}
                    </Festival_name>
                    <div>
                      {data.FESTIVALITEM.fstvlBgngDe} ~ {data.FESTIVALITEM.fstvlEndDe}
                    </div>
                    <Festival_region>
                      {data.FESTIVALITEM.areaNm}
                    </Festival_region>
                  </ContentLayer>
           
  
                  </BoxItem>
                </> 
              ))
            }
            </div>

        
        
          </Column>)
      }  
    </Container>
  );

}

export default PCLifeTourFestival;

