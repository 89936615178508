import React, {memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../context/User";

import "../screen/css/common.css";
import { useAtom } from "jotai";
import { lifemenustore } from "../store/jotai";
import { LinearEncoding } from "@react-three/drei/helpers/deprecated";



const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;

`

const TagButton = styled.div`
    padding: 10px 0px;
    color:${({checked})=> checked == true ? '#131313':'#7f7c7c'};
    margin: 5px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left:5px;
    font-family :${({checked})=> checked == true ? 'Pretendard-SemiBold':'Pretendard-Regular'};

`

const PCLifeClick =memo(({containerStyle, data, callback}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */
 
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const { dispatch, user } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [number, setNumber] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [menu, setMenu] = useAtom(lifemenustore);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

 const handleCheckboxChange = () =>{

    setIsChecked(true);
    callback(true);
    setMenu(data);

    setRefresh((refresh) => refresh +1);
  }


  useEffect(()=>{
    setIsChecked(isChecked);

  }, [refresh])

  async function FetchData(){
       
   
  }


  useEffect(()=>{
  
      FetchData();
  }, [])
  

  const handleClick = () =>{

    setIsChecked(true);
    callback(true);
    setMenu(data);

    setRefresh((refresh) => refresh +1);
  }
 
  return (

    <Container style={containerStyle}>
      <TagButton checked={menu == data} onClick={handleClick}
      className="lifemenu-item"
      >{data}</TagButton>

    </Container>
  );

})

export default PCLifeClick;

