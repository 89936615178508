
import { Checkbox, Table } from "@mui/material";
import React, {useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FlexEndRow, FlexstartRow, Row } from "../common/Row";
import { UserContext } from "../context/User";

import "./mobile.css"
import { imageDB } from "../utility/imageData";
import LottieAnimation from "../common/LottieAnimation";
import { useSleep } from "../utility/common";
import CurrentMap from "./CurrentMap";
import PCMapPopup from "../modal/PcMapPopup/PcMapPopup";
import { Column, FlexstartColumn } from "../common/Column";
import { DataContext } from "../context/Data";
import MobileMapPopup from "../modal/MobileMapPopup/MobileMapPopup";
import { ReadTourFestival } from "../service/LifeService";
import ResultLabel from "../common/ResultLabel";
import { autoBatchEnhancer } from "@reduxjs/toolkit";
import { ReadCommunitySummary } from "../service/CommunityService";
import TimeAgo from 'react-timeago';
import { getFullTime } from "../utility/date";
import koreanStrings from "react-timeago/lib/language-strings/ko";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { LoadingBoardStyle, LoadingCommunityStyle } from "../screen/css/common";
import BoardBox from "./BoardBox";
import { CONFIGMOVE, PCCOMMNUNITYMENU } from "../utility/screen";
import PCBanner from "./PCBanner";
import { CONVENIENCEMENU, LIFEMENU, MEDICALMENU, PERFORMANCEMENU, TOURISTMENU } from "../utility/life";
import Button from "../common/Button";
import ButtonEx from "../common/ButtonEx";
import "./input.css"
import PCLifeCheck from "./PCLifeCheck";
import { lifemenustore } from "../store/jotai";
import { useAtom } from "jotai";

import PCSlickSliderComponentEx from "../common/PCSwipeex";

import PCLifeClick from "./PCLifeClick";

import MobileLifeCampingRegion from "./MobileLifeCampingRegion";

import PCLifeTourMapCourse from "./PCLifeTourMapCourse";
import PCLifeCampingRegion from "./PCLifeCampingRegion";

import PCLifeTourFestival from "./PCLifeTourFestival";
import PCLifePerformanceEvent from "./PCLifePerformanceEvent";
import PCLifeTourRegion from "./PCLifeTourRegion";
import PCLifeTourPicture from "./PCLifeTourPicture";
import PCLifePerformanceCinema from "./PCLifePerformanceCinema";
import PCLifeTourCourse from "./PCLifeTourCourse";

const formatter = buildFormatter(koreanStrings); 

const Container = styled.div`

  margin : 0 auto;
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
  scrollbar-width: none;
  width: 100%;
  background :#fff;
  min-height:600px;


`
const style = {
  display: "flex"
};






const Inputstyle ={

  background: '#FFF',
  borderRadius:'5px',
  fontSize: '16px',
  padding: '0px 16px 0px 16px',
  height : '20px',
  border : "1px solid #FF7125",


}


const  SearchLayer = styled.div`
  width: 90%;
  margin : 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  

`

const BoxItem = styled.div`
  padding: 0px 0px 30px;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  width: 30%;

`
const BoxLabel = styled.div`
  font-family: "Pretendard-SemiBold";
  font-size:16px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:100%;


`

const BoxContent = styled.div`
  font-family: "Pretendard-Regular";
  font-size:14px;

`
const BoxWrite = styled.div`
  display : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items: center;
  width : 100%;
  font-size :14px;
`

const BoxImage = styled.div`
  margin-top:5px;
`

const LoadingStyle={
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  top: "300px",
  position:"absolute"
}
const MapbtnStyle={
  background: "#ff",
  padding: "0px 20px",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '50px',
  color: '#333',
  border :" 1px solid #c3c3c3",
  height: '38px',
  fontSize:'16px',
  fontFamily:'Pretendard-SemiBold',
  width:'30%',
  margin :'20px auto 0px',
}

const Taglabel = styled.div`
  font-family: "Pretendard";
  font-size:12px;
  margin-right:10px;
  min-width:50px;
  display : flex;
  align-items: center;
  justify-content: center;
  background-color:#FFF5F5;
  color :#FF2121;
  border-radius: 5px;

`

const TagData = styled.div`
  font-family: "Pretendard-Light";
  font-size:14px;

  color :#131313;
`
const Item = styled.div`
  margin: 5px 0px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:center;
`
const TagButton = styled.div`
    padding: 10px 0px;
    color: #131313;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left:5px;
    width:600px;
`
const EventButton = styled.div`
    padding: 10px 0px;
    color: #131313;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding-left:5px;
    width:600px;
    background: #ededed;
    display: flex;
    justify-content: center;
`

const MainContent = styled.div`
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const MainContentTxt1 = styled.div`
  line-height: 1.5;
  font-size: 34px;
  letter-spacing: -0.32px;
  font-family :Pretendard-Bold;
`
const MainContentTxt2 = styled.div`

  line-height: 1.5;
  font-size: 22px;
  letter-spacing: -0.32px;
`
const ItemLabel = styled.div`
  font-family: 'Pretendard-Bold';
  font-size: 20px;
  margin: 10px 0px;

`
const Line = styled.div`
  height: 1px;
  margin: 20px 0px;
  background: #ededed;
  width: 80%;

`
const InputStyle ={
  display: "flex",
  justifycontent: "flex-start",
  height: "20px"
}

const BannerItems =[

  {type:TOURISTMENU.TOURCOURSE,image : imageDB.tourcourse, main1:"전국관광코스", main2:"구해줘 홍여사 AI가 추천 ", main3:"코스별 관광지 정보 및 이동경로 제공",color:"#ebf7fa"},
  {type:CONVENIENCEMENU.CONVENIENCECAMPING,image : imageDB.camping, main1:"캠핑장정보", main2:"캠핑장 사진  캠핑장 주변 날씨정보를 제공",color:"#ebf7fa"},
  {type:TOURISTMENU.TOURFESTIVAL,image : imageDB.festival, main1:"문화축제", main2:"전국 문화축제주관, 장소, 일시에 대한 정보를 한눈에 볼수 있도록 정리 ", main3:"", color:"#ebf7fa"},
  {type:PERFORMANCEMENU.PERFORMANCEEVENT,image : imageDB.performance, main1:"공연행사 할인티켓", main2:"공연 문화예술 할인 예매 정보보고 공연을 쉽게 즐겨보세요 ", main3:"",color:"#ebf7fa"},
  {type:TOURISTMENU.TOURREGION,image : imageDB.tour, main1:"관광정보", main2:"지도에 기반한 관광지  향토 문화시설및 편의시설정보 ", main3:"", color:"#ebf7fa"},

]

const PCLeisureBoard =({containerStyle, TYPE}) =>  {

/** 제목 정리
 ** 설명
 *! 중요한 내용
 * TODO 미진한 부분
 * ? 뤄리 API 설명
 * @param 파라미터 설명
 */


  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data } = useContext(DataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);



  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [menu, setMenu] = useAtom(lifemenustore);
  const [selectmenu] = useAtom(lifemenustore);

  // 체크박스 상태 변경 핸들러
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };




  const BoardItems1 =[
    LIFEMENU.AI,
    LIFEMENU.BOARD,
    LIFEMENU.WORK,
    LIFEMENU.CLIMATE,
    LIFEMENU.RECIPE,
    MEDICALMENU.MEDICALMEDICINE,
    MEDICALMENU.FOODINFOMATION,
  

  ]
  const BoardItems2 =[
    TOURISTMENU.TOURCOURSE,
    CONVENIENCEMENU.CONVENIENCECAMPING,
    TOURISTMENU.TOURFESTIVAL,
    PERFORMANCEMENU.PERFORMANCEEVENT,
    TOURISTMENU.TOURREGION,
    LIFEMENU.PICTURE,
    PERFORMANCEMENU.PERFORMANCECINEMA,


  ]
  const BoardItems3 =[
    LIFEMENU.GAME,
    CONFIGMOVE.RULLET,
    LIFEMENU.ATTENDANCE,


  ]

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
      window.scrollTo(0, 0);
      return () => {};
  }, []);

  useEffect(()=>{

    setMenu(TOURISTMENU.TOURCOURSE);

    setLoading(loading);


  },[refresh])



  useEffect(()=>{

  }, [])


  const _handleSelectMenu = (data)=>{



  }
  const _handleLifeCheck = (data)=>{

  }


  useEffect(()=>{

    console.log("MATRIX LOG : useEffect : lifemenustore:", selectmenu)
  },[selectmenu])


  return (
    <>

      {/* <Column style={{width:"100%", margin: "0px auto 0px"}}>
      <PCSlickSliderComponentEx width={"100%"} items={BannerItems} bgcolor={'#ec8260'}/>
      </Column> */}
      <Container style={containerStyle}>     
      <Row style={{marginTop:10,width:"100%",margin:"0 auto", display:"flex", alignItems:"flex-start", justifyContent:"flex-start"}}>
     
        <Column style={{width:"100%", marginTop:'70px'}}> 
          {TYPE == TOURISTMENU.TOURCOURSE && <PCLifeTourCourse/>}
          {TYPE == CONVENIENCEMENU.CONVENIENCECAMPING && <PCLifeCampingRegion/>}
          {TYPE == TOURISTMENU.TOURFESTIVAL && <PCLifeTourFestival/>}
          {TYPE == PERFORMANCEMENU.PERFORMANCEEVENT && <PCLifePerformanceEvent/>}
          {TYPE == TOURISTMENU.TOURREGION && <PCLifeTourRegion/>}
          {TYPE == LIFEMENU.PICTURE && <PCLifeTourPicture/>}
          {TYPE == PERFORMANCEMENU.PERFORMANCECINEMA  && <PCLifePerformanceCinema/>}
      
        </Column>
   
     

      </Row>
      </Container>
    </>
  );
}

export default PCLeisureBoard;

